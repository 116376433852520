import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit'; // redux bu
import { dispatch } from '../../store';
// utils
import axios from '../../../utils/axios';
import { ForeignTransitCompanyModel } from '../../../@types/GetForeignTransitCompany';
import { CONSTS } from '../const';
import { MapDeckglOverlay } from '../../../components/map';
// ----------------------------------------------------------------------
type GetForeignTransitCompanyState = {
  isLoading: boolean;
  error: boolean;
  detailModalIsOpen: boolean;
  GetForeignTransitCompanyList: ForeignTransitCompanyModel[]; // farklı olan diğerleri fix
  getForeignTransitCompany: ForeignTransitCompanyModel;
};
const getForeignTransitCompanyInit = {
  id: 0,
  currentCode: 0,
  companyName: '',
  phone: '',
  authorizedPersonnel: '',
  address: ''
};
const initialState: GetForeignTransitCompanyState = {
  isLoading: false,
  error: false,
  detailModalIsOpen: false,
  GetForeignTransitCompanyList: [], // diğerleri fix
  getForeignTransitCompany: getForeignTransitCompanyInit // buda öyle announcementInit yukarıda
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getForeignTransitCompanyAllSuccess(state, action) {
      state.isLoading = false;
      state.GetForeignTransitCompanyList = action.payload;
    },
    filterForeignTransitCompanyAllSuccess(state, action) {
      state.isLoading = false;
      state.GetForeignTransitCompanyList = action.payload;
    },
    saveForeignTransitCompanyAllSuccess(state, action) {
      state.isLoading = false;
      state.GetForeignTransitCompanyList = action.payload;
    },
    getForeignTransitCompanyAllByIdSuccess(state, action) {
      state.isLoading = false;
      state.GetForeignTransitCompanyList = action.payload;
    },
    onToggleDetailModal(state, action) {
      state.detailModalIsOpen = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleDetailModal } = slice.actions;

// ----------------------------------------------------------------------

export function addForeignTransitCompany(model: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${CONSTS.AnnouncementAddAnnouncement}`,
        {
          title: model.title,
          description: model.description,
          publicationDate: model.publicationDate,
          announcementTypeId: Number(model.announcementTypeId),
          takedownDate: model.takedownDate
        }
      );
      localStorage.setItem('id', response.data.data.id);

      dispatch(ForeignTransitCompanyList());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function ForeignTransitCompanyList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.GetForeignTransitCompany, {});
      dispatch(
        slice.actions.getForeignTransitCompanyAllSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteForeignTransitCompany(model: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${CONSTS.AnnouncementDeleteAnnouncement}`,
        model
      );
      dispatch(ForeignTransitCompanyList());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getForeignTransitCompanyById(announcementId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.AnnouncementGetAnnouncementById}${announcementId}`
      );
      dispatch(
        slice.actions.getForeignTransitCompanyAllSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateForeignTransitCompany(model: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${CONSTS.AnnouncementEditAnnounement}`,
        {
          id: Number(model.id),
          title: model.title,
          description: model.description,
          publicationDate: model.publicationDate,
          announcementTypeId: Number(model.announcementTypeId),
          takedownDate: model.takedownDate
        }
      );
      localStorage.setItem('id', response.data.data.id);

      dispatch(ForeignTransitCompanyList());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function filterForeignTransitCompany(
  _query: string,
  _registered: boolean
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        CONSTS.AnnouncementGetAnnouncementList,
        {
          query: _query,
          registered: _registered
        }
      );
      dispatch(
        slice.actions.getForeignTransitCompanyAllSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

import { createSlice } from '@reduxjs/toolkit'; // redux bu
import { dispatch } from '../../store';
// utils
import axios from '../../../utils/axios';
import {
  AddSelfOwnedVehicleModel,
  CreateSelfOwnedVehicleModel
} from '../../../@types/SelfOwnedVehicleModel';
import { CONSTS } from '../const';

// ----------------------------------------------------------------------

type OrderRequestState = {
  isLoading: boolean;
  error: boolean;
  PriceDefinitionFile: File | null;
  detailModalIsOpen: boolean;
  orderRequestDetailisOpen: boolean;
  addSelfOwnedVehicleList: AddSelfOwnedVehicleModel[]; // farklı olan diğerleri fix
  addSelfOwnedVehicle: AddSelfOwnedVehicleModel;
  base64FileConverter: any;
  base64FileName: any;
  base64FileSize: any;
};

// ------------------------------------------------------------------------------------
// addselfOwnedVehicleInit initial
const addSelfOwnedVehicleInit: AddSelfOwnedVehicleModel = {
  carmanId: 0,
  carmanName: '',
  licensePlate: '',
  vehicleTypeId: 0,
  //  cargoCapacity: 0,
  carModelYear: 0,
  carColor: '',
  carBrandId: 0,
  carModelId: 0,
  vehicleRuhsatDocuments: {
    documentTypeId: 0,
    documentTypeName: '',
    startDate: '',
    endDate: '',
    documentImageFileBase64String: '',
    documentImageName: ''
  },
  vehicleTypeConfirmation: {
    documentTypeId: 0,
    documentTypeName: '',
    startDate: '',
    endDate: '',
    documentImageFileBase64String: '',
    documentImageName: ''
  },
  inspectionInformation: {
    documentTypeId: 0,
    documentTypeName: '',
    beginDate: '',
    endDate: '',
    documentImageName: '',
    documentImageNameBase64String: ''
  },
  vehicleInsurences: {
    documentTypeId: 0,
    documentTypeName: '',
    isKasko: true,
    startDate: '',
    endDate: '',
    insuranceCompanyId: 0,
    insuranceCompanyName: '',
    insuranceAgentId: 0,
    insuranceAgentName: '',
    guaranteFileName: '',
    guaranteeBase64String: '',
    policyImageFileName: '',
    policyImageBase64String: '',
    guaranteDocumentTypeId: 0,
    guaranteDocumentImageName: ''
  },
  vehicleKasko: {
    documentTypeId: 0,
    documentTypeName: '',
    isKasko: true,
    startDate: '',
    endDate: '',
    insuranceCompanyId: 0,
    insuranceCompanyName: '',
    insuranceAgentId: 0,
    insuranceAgentName: '',
    guaranteFileName: '',
    guaranteeBase64String: '',
    policyImageFileName: '',
    policyImageBase64String: '',
    guaranteDocumentImageName: '',
    guaranteDocumentTypeId: 0
  },
  vehicleK1Document: {
    documentTypeId: 0,
    documentTypeName: '',
    startDate: '',
    endDate: '',
    documentImageFileBase64String: '',
    documentImageName: ''
  }
};

// ------------------------------------------------------------------------------------

const initialState: OrderRequestState = {
  // redux a bağlanıyor böyle
  isLoading: false,
  error: false,
  orderRequestDetailisOpen: false,
  detailModalIsOpen: false,
  addSelfOwnedVehicleList: [],
  PriceDefinitionFile: null,
  addSelfOwnedVehicle: addSelfOwnedVehicleInit,
  base64FileConverter: null,
  base64FileName: null,
  base64FileSize: null
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getAllOrderRequestSuccess(state, action) {
      // her  metod için 2 tane error ve succes metodları yazdık
      state.isLoading = false;
      state.addSelfOwnedVehicleList = action.payload;
    },

    // base64 metot
    // Burası

    setBase64FileConverter(state, action) {
      state.base64FileConverter = action.payload;
    },

    // base64 den gelen dosya ismi

    setBase64FileName(state, action) {
      state.base64FileName = action.payload;
    },

    // base64'den gelen dosya tipi
    setBase64FileSize(state, action) {
      state.base64FileSize = action.payload;
    },

    // GET PROFILE
    saveOrderRequestSuccess(state, action) {
      state.isLoading = false;
      state.addSelfOwnedVehicleList = action.payload;
    },

    // GET PROFILE
    getOrderRequestByIdSuccess(state, action) {
      state.isLoading = false;
      state.addSelfOwnedVehicleList = action.payload;
    },
    onToggleDetailModal(state, action) {
      state.detailModalIsOpen = action.payload;
    },
    onToggleOrderRequestDetailModal(state, action) {
      state.orderRequestDetailisOpen = action.payload;
    },
    fileDataState(state, action) {
      state.isLoading = false;
      state.PriceDefinitionFile = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onToggleDetailModal,
  fileDataState,
  onToggleOrderRequestDetailModal,
  setBase64FileConverter,
  setBase64FileName,
  setBase64FileSize
} = slice.actions;

// ----------------------------------------------------------------------

export function addSelfOwnedNewVehicle(model: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${CONSTS.AddSelfOwnedVehicle}`, {
        licensePlate: model.licensePlate,
        vehicleTypeId: Number(6),
        // cargoCapacity: Number(model.cargoCapacity),
        carmanId: model.carmanId,
        carmanName: model.carmanName,
        carModelYear: Number(model.carModelYear),
        carColor: model.carColor,
        carBrandId: Number(model.carBrandId),
        carModelId: Number(model.carModelId),
        vehicleRuhsatDocuments: {
          documentTypeId: Number(model.vehicleRuhsatDocuments.documentTypeId),
          documentTypeName: model.vehicleRuhsatDocuments.documentTypeName,
          startDate: new Date(model.vehicleRuhsatDocuments.startDate),
          endDate: new Date(model.vehicleRuhsatDocuments.endDate),
          documentImageFileBase64String:
            model.vehicleRuhsatDocuments.documentImageFileBase64String,
          documentImageName: model.vehicleRuhsatDocuments.documentImageName
        },
        vehicleTypeConfirmation: {
          documentTypeId: Number(model.vehicleTypeConfirmation.documentTypeId),
          documentTypeName: model.vehicleTypeConfirmation.documentTypeName,
          startDate: new Date(model.vehicleTypeConfirmation.startDate),
          endDate: new Date(model.vehicleTypeConfirmation.endDate),
          documentImageFileBase64String:
            model.vehicleTypeConfirmation.documentImageFileBase64String,
          documentImageName: model.vehicleTypeConfirmation.documentImageName
        },
        inspectionInformation: {
          beginDate: new Date(model.inspectionInformation.beginDate),
          endDate: new Date(model.inspectionInformation.endDate),
          documentImageName: model.inspectionInformation.documentImageName,
          documentImageNameBase64String:
            model.inspectionInformation.documentImageNameBase64String
        },
        vehicleInsurences: {
          documentTypeId: Number(model.vehicleInsurences.documentTypeId),
          documentTypeName: model.vehicleInsurences.documentTypeName,
          isKasko: true,
          startDate: new Date(model.vehicleInsurences.startDate),
          endDate: new Date(model.vehicleInsurences.startDate),
          insuranceCompanyId: Number(
            model.vehicleInsurences.insuranceCompanyId
          ),
          insuranceCompanyName: model.vehicleInsurences.insuranceCompanyName,
          insuranceAgentId: Number(model.vehicleInsurences.insuranceAgentId),
          insuranceAgentName: model.vehicleInsurences.insuranceAgentName,
          guaranteFileName: model.vehicleInsurences.guaranteFileName,
          guaranteeBase64String: model.vehicleInsurences.guaranteeBase64String,
          policyImageFileName: model.vehicleInsurences.policyImageFileName,
          policyImageBase64String:
            model.vehicleInsurences.policyImageBase64String,
          guaranteDocumentTypeId:
            model.vehicleInsurences.guaranteDocumentTypeId,
          guaranteDocumentImageName:
            model.vehicleInsurences.guaranteDocumentTypeId
        },
        vehicleKasko: {
          documentTypeId: Number(model.vehicleKasko.documentTypeId),
          documentTypeName: model.vehicleKasko.documentTypeName,
          isKasko: true,
          startDate: new Date(model.vehicleKasko.startDate),
          endDate: new Date(model.vehicleKasko.endDate),
          insuranceCompanyId: Number(model.vehicleKasko.insuranceCompanyId),
          insuranceCompanyName: model.vehicleKasko.insuranceCompanyName,
          insuranceAgentId: Number(model.vehicleKasko.insuranceAgentId),
          insuranceAgentName: model.vehicleKasko.insuranceAgentName,
          guaranteFileName: model.vehicleKasko.guaranteFileName,
          guaranteeBase64String: model.vehicleKasko.guaranteeBase64String,
          policyImageFileName: model.vehicleKasko.policyImageFileName,
          policyImageBase64String: model.vehicleKasko.policyImageBase64String,
          guaranteDocumentTypeId: model.vehicleKasko.guaranteDocumentTypeId,
          guaranteDocumentImageName: model.vehicleKasko.guaranteDocumentTypeId
        },
        vehicleK1Document: {
          documentTypeId: Number(model.vehicleK1Document.documentTypeId),
          documentTypeName: model.vehicleK1Document.documentTypeName,
          startDate: new Date(model.vehicleK1Document.startDate),
          endDate: new Date(model.vehicleK1Document.endDate),
          documentImageFileBase64String:
            model.vehicleK1Document.documentImageFileBase64String,
          documentImageName: model.vehicleK1Document.documentImageName
        }
      });
      localStorage.setItem('id', response.data.data.id);

      dispatch(getCustomerAllOrderRequest());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setVehicleDocument(model: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${CONSTS.AddSelfOwnedVehicle}`, {
        documentTypeId: model.documentTypeId,
        documentTypeName: model.documentTypeName,
        documentTypeSelectList: model.documentTypeSelectList,
        startDate: model.startDate,
        endDate: model.endDate,
        documentImageFileBase64String: model.documentImageFileBase64String,
        documentImageName: model.documentImageName
      });
      localStorage.setItem('id', response.data.data.id);

      dispatch(getCustomerAllOrderRequest());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomerAllOrderRequest() {
  const customerId = localStorage.getItem('Customer');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.GetCustomerOrderRequests}?customerId=${customerId}`
      );
      dispatch(slice.actions.getAllOrderRequestSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

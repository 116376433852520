import { createSlice } from '@reduxjs/toolkit'; // redux bu
import {
  TotalCargoModel,
  TotalKMModel,
  TotalOrderRequestModel,
  TotalShipmentModel
} from '../../../@types/DashboardModel';
import { dispatch } from '../../store';
import axios from '../../../utils/axios';
import { CONSTS } from '../const';

// ----------------------------------------------------------------------

type DashboardState = {
  isLoading: boolean;
  isDataLoading: boolean;

  error: boolean;
  TotalOrderReques: TotalOrderRequestModel;
  TotalShipment: TotalShipmentModel;
  TotalCargo: TotalCargoModel;
  TotalKM: TotalKMModel;
};

const TotalOrderRequestInit = {
  totalOrderCount: 0,
  lastMonthOrderCount: 0,
  chartData: []
};

const TotalShipmentInit = {
  totalOrderCount: 0,
  lastMonthOrderCount: 0,
  chartData: []
};

const TotalCargoInit = {
  totalOrderCount: 0,
  lastMonthOrderCount: 0,
  chartData: []
};

const TotalKMInıt = {
  totalOrderCount: 0,
  lastMonthOrderCount: 0,
  chartData: []
};

const initialState: DashboardState = {
  isLoading: false,
  error: false,
  TotalOrderReques: TotalOrderRequestInit,
  isDataLoading: false,
  TotalShipment: TotalShipmentInit,
  TotalCargo: TotalCargoInit,
  TotalKM: TotalKMInıt
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setTotalCountORder(state, action) {
      state.TotalOrderReques = action.payload;
    },

    setTotalCountShipment(state, action) {
      state.TotalShipment = action.payload;
    },

    setTotalCountCargo(state, action) {
      state.TotalCargo = action.payload;
    },

    setTotalKM(state, action) {
      state.TotalKM = action.payload;
    },

    // START LOADING
    startDataLoading(state) {
      state.isDataLoading = true;
    },
    // START LOADING
    finishDataLoading(state) {
      state.isDataLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setTotalCountORder,
  setTotalCountShipment,
  setTotalCountCargo,
  setTotalKM
} = slice.actions;

export async function getTotalCountORder() {
  dispatch(slice.actions.startLoading());
  dispatch(slice.actions.startDataLoading());

  try {
    const response = await axios.get(CONSTS.TotalORderRequest, {});

    dispatch(slice.actions.finishDataLoading());
    return response.data.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getTotalCountCargo() {
  dispatch(slice.actions.startLoading());
  dispatch(slice.actions.startDataLoading());

  try {
    const response = await axios.get(CONSTS.totalCargo, {});

    dispatch(slice.actions.finishDataLoading());
    return response.data.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getTotalCountShipment() {
  dispatch(slice.actions.startLoading());
  dispatch(slice.actions.startDataLoading());

  try {
    const response = await axios.get(CONSTS.totalShipment, {});

    dispatch(slice.actions.finishDataLoading());
    return response.data.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getTotalKM() {
  dispatch(slice.actions.startLoading());
  dispatch(slice.actions.startDataLoading());

  try {
    const response = await axios.get(CONSTS.totalkm, {});

    dispatch(slice.actions.finishDataLoading());
    return response.data.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

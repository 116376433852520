import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import authJwtReducer from './slices/authJwt';
import settingsReducer from './slices/settings';
import calendarReducer from './slices/calendar';
import announcementReducer from './slices/announcement';
import quietReducer from './slices/quietLibrary';
import studiesReducer from './slices/studiesRoom';
import tableReducer from './slices/table';
import turnstileReducer from './slices/turnstile';
import definedLocationsReducer from './slices/customer/GetLocations';
import orderRequestReducer from './slices/customer/orderRequest';
import AddSelfOwnedVehicleReducer from './slices/Staff/AddSelfOwnedVehicle';
import getPriceDefinitionReducer from './slices/customer/GetPriceDefinition';
import priceDefinationReducer from './slices/priceDefination';
import analyticsReducer from './slices/analytics';
import presidentAnnouncementReducer from './slices/presidentAnnouncement';
import classesReducer from './slices/classes';
import getForeignVehicleReducer from './slices/ManagementPanel/GetForeignVehicle';
import getVehicleTypesReducer from './slices/ManagementPanel/GetVehicleTypes';
import SelfOwnedVehicleReducer from './slices/SelfOwnedVehicle';
import GetCreateorderRequestForCustomerReducer from './slices/customer/GetCreateOrderRequest';
import CustomerAllReducer from './slices/ManagementPanel/GetCustomerAll';
import CitySelectLİstGetReducer from './slices/customer/CitySelectListGet';
// import DistrictForCitySelectListReducer from './slices/customer/DistrictForCitySelectList';
import StaffListReducer from './slices/ManagementPanel/GetStaffList';
import AddStaffReducer from './slices/Staff/AddStaff';
import ForeignTransitCompanyReducer from './slices/ManagementPanel/GetForeignTransitCompany';
import kanbanReducer from './slices/kanban';
import AddPriceFormulFixedReducer from './slices/AddPriceFormulFixed';
import CustomerContractReducer from './slices/Staff/CustomerContract';
import CustomerReducer from './slices/customer/Customer';
import materialReducer from './slices/Staff/material';
import staffReducer from './slices/staff';

import VehicleStateReducer from './slices/Staff/VehicleState';
import TransportDashboard from './slices/TransportDashBoard/TransportDashboard';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const authPersistConfig = {
  key: 'authJwt',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated']
};

const rootReducer = combineReducers({
  material: materialReducer,
  GetCreateORderRequest: GetCreateorderRequestForCustomerReducer,
  // DistrictForCitySelectList: DistrictForCitySelectListReducer,
  CitySelectListGet: CitySelectLİstGetReducer,
  VehicleState: VehicleStateReducer,
  AddPriceFormulFixed: AddPriceFormulFixedReducer,
  SelfOwnedVehicle: SelfOwnedVehicleReducer,
  CustomerContract: CustomerContractReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  table: tableReducer,
  announcement: announcementReducer,
  quiet: quietReducer,
  kanban: kanbanReducer,
  studies: studiesReducer,
  addSelfOwnedVehicle: AddSelfOwnedVehicleReducer,
  presidentAnnouncement: presidentAnnouncementReducer,
  foreignVehicle: getForeignVehicleReducer,
  getVehicleTypes: getVehicleTypesReducer,
  definedLocations: definedLocationsReducer,
  getPriceDefinition: getPriceDefinitionReducer,
  priceDefination: priceDefinationReducer,
  orderRequest: orderRequestReducer,
  classes: classesReducer,
  turnstile: turnstileReducer,
  analytics: analyticsReducer,
  settings: settingsReducer,
  getForeignTransitCompany: ForeignTransitCompanyReducer,
  addStaff: AddStaffReducer,
  getStaffs: StaffListReducer,
  staff: staffReducer,
  calendar: calendarReducer,
  TransportDashboardd: TransportDashboard,
  customer: CustomerReducer,
  getCustomerAll: CustomerAllReducer,
  product: persistReducer(productPersistConfig, productReducer),
  authJwt: persistReducer(authPersistConfig, authJwtReducer)
});

export { rootPersistConfig, rootReducer };

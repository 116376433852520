import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit'; // redux bu
import { dispatch } from '../../store';
// utils
import axios from '../../../utils/axios';
import {
  DriverDocumentTypesSelectLİstModel,
  ForeignDriverListModel,
  LoginStaffDriverModel,
  PassiveStaffStatus,
  SelfOwnedDriverListModel,
  StaffInfoModel,
  StaffListModel,
  StaffTitleSelectListModel,
  VehicleDriversDocumentListModel,
  VehicleDriversDocumentListModelList
} from '../../../@types/GetStaffModel';
import { CONSTS } from '../const';
import { MapDeckglOverlay } from '../../../components/map';
import { getDistrictForCitySelectList } from '../customer/CitySelectListGet';
// ----------------------------------------------------------------------
type GetStaffAllState = {
  isLoading: boolean;
  isDataLoading: boolean;
  error: boolean;
  detailModalIsOpen: boolean;
  getSelfOwnedDriverList: SelfOwnedDriverListModel[];
  getForeignDriverLİst: ForeignDriverListModel[];
  selfOwnedVehicleDriverEditModalOpen: false;
  VehicleDriverDocumentEditModalOpen: false;
  StaffOtherList: StaffListModel[];
  VehicleDriversDocumentList: VehicleDriversDocumentListModelList[];
  staffIdstaff: number;
  vehicleDriverDocumentEdit: VehicleDriversDocumentListModel;
  DriverDocumentTypesSelectLİst: DriverDocumentTypesSelectLİstModel[];
  AddVehicleDriverDocument: VehicleDriversDocumentListModel;
  VehicleDriverInfo: StaffInfoModel;
  StaffTitleSelectList: StaffTitleSelectListModel[];
  staffTabIndex: number;
  staffName: string;
  staffLoginDriver: LoginStaffDriverModel;
  PassiveStaffStatus: PassiveStaffStatus[];
};
const getStaffInit = {
  // gelen modelin propertylerin tipleri
  id: 0,
  tckNo: '',
  email: '',
  name: '',
  surname: '',
  nameAndSurname: '',
  titleId: 0,
  phone: '',
  titleName: '',
  currentCode: 0
};

const vehicleDriverDocumentEditInıt = {
  id: 0,
  documentExtension: '',
  documentTypeId: 0,
  mimeType: '',
  documentTypeName: '',
  startDate: new Date(),
  endDate: new Date(),
  documentImageFileBase64String: '',
  documentImageName: '',
  documnetStatüs: '',
  vehicleId: 0,
  staffId: 0,
  driverState: ''
};

const VehicleDriverInfoModelInıt = {
  tckNo: '',
  name: '',
  surname: '',
  titleId: 0,
  email: '',
  phone: '',
  titleName: '',
  currentCode: 0,
  cityName: '',
  districtName: '',
  cityId: 0,
  districtId: 0,
  isActive: true,
  staffStatusId: 0
};

const staffLoginDriverInıt = {
  email: '',
  password: '',
  customerId: 0,
  staffId: 0,
  cityId: 0,
  districtId: 0,
  phoneNumber: '',
  photoBase64: '',
  photoImageName: ''
};

const initialState: GetStaffAllState = {
  // redux a bağlanıyor böyle
  isLoading: false,
  error: false,
  detailModalIsOpen: false,
  getSelfOwnedDriverList: [],
  StaffOtherList: [],
  getForeignDriverLİst: [],
  selfOwnedVehicleDriverEditModalOpen: false,
  VehicleDriversDocumentList: [],
  staffIdstaff: 0,
  VehicleDriverDocumentEditModalOpen: false,
  vehicleDriverDocumentEdit: vehicleDriverDocumentEditInıt,
  DriverDocumentTypesSelectLİst: [],
  AddVehicleDriverDocument: vehicleDriverDocumentEditInıt,
  VehicleDriverInfo: VehicleDriverInfoModelInıt,
  StaffTitleSelectList: [],
  staffTabIndex: 0,
  isDataLoading: false,
  staffName: '',
  staffLoginDriver: staffLoginDriverInıt,
  PassiveStaffStatus: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // START LOADING
    startDataLoading(state) {
      state.isDataLoading = true;
    },
    // finish LOADING
    finishDataLoading(state) {
      state.isDataLoading = false;
    },

    getStaffListtSuccess(state, action) {
      state.isLoading = false;
      state.StaffOtherList = action.payload;
    },

    onToggleDetailModal(state, action) {
      state.detailModalIsOpen = action.payload;
    },
    getSelfOwnedDriverListSuccess(state, action) {
      state.isLoading = false;
      state.getSelfOwnedDriverList = action.payload;
    },
    getGetForeignDriverListSuccess(state, action) {
      state.isLoading = false;
      state.getForeignDriverLİst = action.payload;
    },
    setselfOwnedVehicleDriverEditModalOpen(state, action) {
      state.selfOwnedVehicleDriverEditModalOpen = action.payload;
    },
    getVehicleDriversDocumentListSuccess(state, action) {
      state.VehicleDriversDocumentList = action.payload;
    },
    setStaffId(state, action) {
      state.staffIdstaff = action.payload;
    },
    setStaffName(state, action) {
      state.staffName = action.payload;
    },
    setVehicleDriverDocumentEditModalOpen(state, action) {
      state.VehicleDriverDocumentEditModalOpen = action.payload;
    },
    getEditVehicleDriversDocumentSuccess(state, action) {
      state.vehicleDriverDocumentEdit = action.payload;
    },
    getDriverDocumentTypesSelectLİstSuccess(state, action) {
      state.DriverDocumentTypesSelectLİst = action.payload;
    },
    getVehicleDriverInfoSuccess(state, action) {
      state.VehicleDriverInfo = action.payload;
    },
    getStaffTitleSelectLİstSuccess(state, action) {
      state.StaffTitleSelectList = action.payload;
    },
    setStaffTabIndex(state, action) {
      state.staffTabIndex = action.payload;
    },
    PassiveStaffStatusSuccess(state, action) {
      state.PassiveStaffStatus = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onToggleDetailModal,
  setselfOwnedVehicleDriverEditModalOpen,
  setStaffId,
  setVehicleDriverDocumentEditModalOpen,
  setStaffTabIndex,
  setStaffName
} = slice.actions;

// ----------------------------------------------------------------------

export function getStaffListt() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.GetStaffAll, {});
      dispatch(slice.actions.getStaffListtSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPassiveStaffStatusFunc() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.staffStatusPassive, {});
      dispatch(slice.actions.PassiveStaffStatusSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGetSelfOwnedDriverList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.selfOwnedDriverList, {});
      dispatch(slice.actions.getSelfOwnedDriverListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGetForeignDriverList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.foreignDriverList, {});
      dispatch(
        slice.actions.getGetForeignDriverListSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVehicleDriversDocumentList(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.getVehicleDriverDocumentList}?driverId=${id}&IsForeign=false`
      );
      dispatch(
        slice.actions.getVehicleDriversDocumentListSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEditVehicleDriversDocument(documentId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.getEditVehicleDriverDocument}?documentId=${documentId}`
      );
      dispatch(
        slice.actions.getEditVehicleDriversDocumentSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateVehicleDriversDocument(
  updatemodel: any,
  staffId: any
) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.updateVehicleDriverDocument, {
      oldDocumentId: Number(updatemodel.id),
      documentTypeId: Number(updatemodel.documentTypeId),
      beginDate: updatemodel.startDate,
      endDate: updatemodel.endDate,
      documentImageFileBase64String: updatemodel.documentImageFileBase64String,
      documentImageName: updatemodel.documentImageName,
      vehicleId: 0,
      staffId: updatemodel.staffId
    });
    if (response.data.success) {
      dispatch(getEditVehicleDriversDocument(staffId));
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getDriverDocumentTypesSelectLİst() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        CONSTS.DriverDocumentTypesSelectLİst,
        {}
      );
      dispatch(
        slice.actions.getDriverDocumentTypesSelectLİstSuccess(
          response.data.data
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function addNewVehicleDriverDocument(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      CONSTS.addSelfOwnedVehicleDocument,
      model
    );
    if (response.data.success) {
      dispatch(getVehicleDriversDocumentList(model.vehicleId));
      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function StaffaddDocument(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.addstaffDocument, model);
    if (response.data.success) {
      //    dispatch(getVehicleDriversDocumentList(model.vehicleId));
      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function removeVehicleDriverDocument(id: any, staffId: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.deleteVehicleDriverDocument}`,
      id
    );
    if (response.data.success) {
      dispatch(getVehicleDriversDocumentList(staffId));
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getVehicleDriverInfo(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startDataLoading());

    try {
      const response = await axios.get(
        `${CONSTS.vehicleDriverInfo}?staffId=${id}`
      );
      dispatch(slice.actions.getVehicleDriverInfoSuccess(response.data.data));
      dispatch(slice.actions.finishDataLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateVehicleDriverInfo(updatemodel: any, staffId: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      CONSTS.updateVehicleDriverInfo,
      updatemodel
    );
    if (response.data.success) {
      dispatch(getVehicleDriverInfo(staffId));
      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getStaffTitleSelectLİst() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.getStaffTitle, {});
      dispatch(
        slice.actions.getStaffTitleSelectLİstSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function removeStaff(id: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${CONSTS.deleteStaff}`, id);
    if (response) {
      dispatch(getGetSelfOwnedDriverList());
      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function addCreateDriverStaff(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.AddCreateStaff, {
      email: `${model.phoneNumber}@test.com`,
      password: 'Test@123',
      customerId: model.customerId,
      staffId: model.staffId,
      cityId: model.cityId,
      districtId: model.districtId,
      phoneNumber: model.phoneNumber,
      photoBase64: model.photoBase64,
      photoImageName: model.photoImageName
    });

    if (response.data.success) {
      // dispatch(getCustomerInContractList());
    }
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return null;
  }
}

// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DOCS = '/docs';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  page404: '/404',
  page500: '/500'
};

export const PATH_HOME = {
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-dashboard/',
  components: '/components',
  dashboard: ROOTS_DASHBOARD
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    turnstile: path(ROOTS_DASHBOARD, '/turnstile'),
    turnstileFirst: path(ROOTS_DASHBOARD, '/turnstile/first/floor'),
    qrCode: path(ROOTS_DASHBOARD, '/turnstile/qrcode'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    silentLibrary: path(ROOTS_DASHBOARD, '/silentLibrary'),
    apointment: path(ROOTS_DASHBOARD, '/apointment'),
    workingSchedule: path(ROOTS_DASHBOARD, '/workingSchedule'),
    table: path(ROOTS_DASHBOARD, '/table'),
    showApointment: path(ROOTS_DASHBOARD, '/showApointment')
  },

  transportation: {
    GetVehicleListWithCity: path(ROOTS_DASHBOARD, '/GetVehicleListWithCity'),
    ShipmentConfimModal: path(ROOTS_DASHBOARD, '/staff/ShipmentConfirmModal'),

    waitingOKShipmentList: path(ROOTS_DASHBOARD, '/waitingOKShipmentList'),
    selfOwnedVehicleDriverList: path(
      ROOTS_DASHBOARD,
      '/Staff/selfOwnedVehicleDriverList'
    ),
    EscalationScreen: path(ROOTS_DASHBOARD, '/Staff/EscalationScreen'),
    SelfOWnedCompanies: path(ROOTS_DASHBOARD, '/Staff/SelfOwnedCompanies'),

    foreignTransitCompany: path(
      ROOTS_DASHBOARD,
      '/Staff/foreignTransitCompany'
    ),
    AddDriver: path(ROOTS_DASHBOARD, '/Staff/AddDriver'),

    TrailerList: path(ROOTS_DASHBOARD, '/Staff/TrailerList'),

    StaffList: path(ROOTS_DASHBOARD, '/Staff/StaffList'),

    AddStaff: path(ROOTS_DASHBOARD, '/Staff/AddStaff'),

    AddCustomerInfo: path(ROOTS_DASHBOARD, '/Staff/AddCustomerInfo'),

    StaffDashboard: path(ROOTS_DASHBOARD, '/Staff/StaffDashboard'),

    OrderRequestFull: path(ROOTS_DASHBOARD, '/Staff/OrderRequestFull'),

    EditContract: path(ROOTS_DASHBOARD, '/Staff/EditContract'),

    ContractList: path(ROOTS_DASHBOARD, '/Staff/ContractList'),
    DebitMaterial: path(ROOTS_DASHBOARD, '/Staff/DebitMaterial'),

    Customers: path(ROOTS_DASHBOARD, '/Staff/Customers'),

    PriceDefinationScreen: path(
      ROOTS_DASHBOARD,
      '/Staff/PriceDefinationScreen'
    ),
    DivideLoad: path(ROOTS_DASHBOARD, '/Staff/DivideLoad/:orderRequestId'),
    CustomerAccount: path(ROOTS_DASHBOARD, '/Customer/Account'),
    customerScreen: path(ROOTS_DASHBOARD, '/Customer/CreateOrderRequest'),
    ShipmentOrganization: path(ROOTS_DASHBOARD, '/Staff/ShipmentOrganization'),
    SelfOwnedVehicleDetail: path(
      ROOTS_DASHBOARD,
      '/Staff/SelfOwnedVehicleDetail'
    ),
    kanban: path(ROOTS_DASHBOARD, '/SelfOwnedVehicleConnection'),
    orderRequestOrganization: path(
      ROOTS_DASHBOARD,
      '/OrderrequestOrganization'
    ),
    customerInformation: path(ROOTS_DASHBOARD, '/Customer/Information'),
    personelcustomerInformation: path(
      ROOTS_DASHBOARD,
      '/Staff/CustomerInformation/:customerId'
    ),
    customerList: path(ROOTS_DASHBOARD, '/Staff/Customers'),
    staffOrder: path(ROOTS_DASHBOARD, '/Staff/order'),
    Vehicle: path(ROOTS_DASHBOARD, '/Staff/Vehicles'),
    prices: path(ROOTS_DASHBOARD, '/Customer/Prices'),
    orderRequest: path(ROOTS_DASHBOARD, '/Customer/MyOrderRequest'),
    foreignVehicleRegistration: path(
      ROOTS_DASHBOARD,
      '/ForeignVehicleRegistration'
    ),
    upload: path(ROOTS_DASHBOARD, '/upload'),
    createOrderEdit: path(ROOTS_DASHBOARD, '/Customer/CreateOrderEdit'),
    shipmentFinalConfirmation: path(
      ROOTS_DASHBOARD,
      '/Staff/ShipmentFinalConfirmation'
    ),
    // staffs: path(ROOTS_DASHBOARD, '/ManagementPaneleskii/staffs'),
    ManagementStaffs: path(ROOTS_DASHBOARD, '/ManagementPanel/staffs'),
    vehicles: path(ROOTS_DASHBOARD, '/ManagementPanel/vehicles'),
    AddForeignvehicle: path(
      ROOTS_DASHBOARD,
      '/MagementPanel/Addforeignvehicle'
    ),

    AddTrailer: path(ROOTS_DASHBOARD, '/ManagementPanel/AddTrailer'),
    ForeignVehicles: path(ROOTS_DASHBOARD, '/ManagementPanel/ForeignVehicles'),
    SelfOwnedVehiclesManagementPanel: path(
      ROOTS_DASHBOARD,
      '/ManagementPanel/SelfOwnedVehicle'
    ),
    VehicleTypes: path(ROOTS_DASHBOARD, '/ManagementPanel/VehicleTypes'),
    AddSelfOwnedVehicle: path(
      ROOTS_DASHBOARD,
      '/ManagementPanel/AddSelfOwnedVehicle'
    ),
    CustomerAll: path(ROOTS_DASHBOARD, '/ManagementPanel/Customers'),
    CarDocumentFinishedVehicles: path(
      ROOTS_DASHBOARD,
      '/ManagementPanel/Cardocumentvehicles'
    ),
    WorkedTransitCompanies: path(
      ROOTS_DASHBOARD,
      '/ManagementPanel/WorkedTransitCompanies'
    ),
    StaffsContractFinished: path(
      ROOTS_DASHBOARD,
      '/ManagementPanel/StaffsContractFinished'
    ),
    SelfOwnedVehicleStaff: path(ROOTS_DASHBOARD, '/Staff/SelfOwnedVehicle'),
    ForeignVehicleStaff: path(ROOTS_DASHBOARD, '/Staff/ForeignVehicle'),
    CustomerContractFinish: path(
      ROOTS_DASHBOARD,
      '/ManagementPanel/CustomerContractFinish'
    ),
    CustomerEscalationCondition: path(
      ROOTS_DASHBOARD,
      '/ManagementPanel/CustomerEscalationCondinition'
    ),

    VehicleDamageRecords: path(ROOTS_DASHBOARD, '/Staff/VehicleDamageRecords')
  },
  order: {
    root: path(ROOTS_DASHBOARD, '/Customer/order'),
    orderShippingStatuses: path(
      ROOTS_DASHBOARD,
      '/Customer/order/Shipping/Statuses'
    ),
    orderRequest: path(ROOTS_DASHBOARD, '/Customer/order/MyOrderRequest')
  },

  announcement: {
    root: path(ROOTS_DASHBOARD, '/announcement'),
    presidentAnnouncement: path(ROOTS_DASHBOARD, '/announcement/president'),
    normalAnnouncement: path(ROOTS_DASHBOARD, '/announcement/normal')
  },

  workingArea: {
    root: path(ROOTS_DASHBOARD, '/workingArea'),
    classes: path(ROOTS_DASHBOARD, '/workingArea/classes'),
    quietLibrary: path(ROOTS_DASHBOARD, '/workingArea/quiet/library'),
    workingRooms: path(ROOTS_DASHBOARD, '/workingArea/working/rooms')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
    labels: [
      path(ROOTS_DASHBOARD, '/mail/label/:customLabel/:mailId?'),
      path(ROOTS_DASHBOARD, '/mail/:systemLabel/:mailId?')
    ]
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: [
      path(ROOTS_DASHBOARD, '/chat/new'),
      path(ROOTS_DASHBOARD, '/chat/:conversationKey')
    ]
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/card'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },

  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(
      ROOTS_DASHBOARD,
      '/e-commerce/product/nike-air-force-1-ndestrukt'
    ),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(
      ROOTS_DASHBOARD,
      '/blog/post/portfolio-review-is-this-portfolio-too-creative'
    ),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  },
  foundations: {
    root: path(ROOTS_DASHBOARD, '/foundations'),
    colors: path(ROOTS_DASHBOARD, '/foundations/colors'),
    typography: path(ROOTS_DASHBOARD, '/foundations/typography'),
    shadows: path(ROOTS_DASHBOARD, '/foundations/shadows'),
    grid: path(ROOTS_DASHBOARD, '/foundations/grid'),
    icons: path(ROOTS_DASHBOARD, '/foundations/icons')
  },
  components: {
    root: path(ROOTS_DASHBOARD, '/components'),
    accordion: path(ROOTS_DASHBOARD, '/components/accordion'),
    alert: path(ROOTS_DASHBOARD, '/components/alert'),
    autocomplete: path(ROOTS_DASHBOARD, '/components/autocomplete'),
    avatar: path(ROOTS_DASHBOARD, '/components/avatars'),
    badge: path(ROOTS_DASHBOARD, '/components/badges'),
    breadcrumbs: path(ROOTS_DASHBOARD, '/components/breadcrumbs'),
    buttons: path(ROOTS_DASHBOARD, '/components/buttons'),
    chip: path(ROOTS_DASHBOARD, '/components/chips'),
    dialog: path(ROOTS_DASHBOARD, '/components/dialogs'),
    textfield: path(ROOTS_DASHBOARD, '/components/text-fields'),
    label: path(ROOTS_DASHBOARD, '/components/labels'),
    lists: path(ROOTS_DASHBOARD, '/components/lists'),
    menu: path(ROOTS_DASHBOARD, '/components/menu'),
    pagination: path(ROOTS_DASHBOARD, '/components/pagination'),
    pickers: path(ROOTS_DASHBOARD, '/components/pickers'),
    popover: path(ROOTS_DASHBOARD, '/components/popover'),
    progress: path(ROOTS_DASHBOARD, '/components/progress'),
    rating: path(ROOTS_DASHBOARD, '/components/rating'),
    selectionControls: path(ROOTS_DASHBOARD, '/components/selection-controls'),
    snackbar: path(ROOTS_DASHBOARD, '/components/snackbars'),
    slider: path(ROOTS_DASHBOARD, '/components/slider'),
    stepper: path(ROOTS_DASHBOARD, '/components/steppers'),
    tabs: path(ROOTS_DASHBOARD, '/components/tabs'),
    table: path(ROOTS_DASHBOARD, '/components/table'),
    timeline: path(ROOTS_DASHBOARD, '/components/timeline'),
    tooltip: path(ROOTS_DASHBOARD, '/components/tooltips'),
    transferList: path(ROOTS_DASHBOARD, '/components/transfer-list'),
    treeView: path(ROOTS_DASHBOARD, '/components/tree-view'),

    // Extra
    extraComponents: path(ROOTS_DASHBOARD, '/extra-components'),
    chart: path(ROOTS_DASHBOARD, '/extra-components/chart'),
    map: path(ROOTS_DASHBOARD, '/extra-components/map'),
    editor: path(ROOTS_DASHBOARD, '/extra-components/editor'),
    copyToClipboard: path(
      ROOTS_DASHBOARD,
      '/extra-components/copy-to-clipboard'
    ),
    upload: path(ROOTS_DASHBOARD, '/extra-components/upload'),
    carousel: path(ROOTS_DASHBOARD, '/extra-components/carousel'),
    multiLanguage: path(ROOTS_DASHBOARD, '/extra-components/multi-language'),
    animate: path(ROOTS_DASHBOARD, '/extra-components/animate')
  }
};

export const PATH_DOCS = {
  root: ROOTS_DOCS,
  introduction: path(ROOTS_DOCS, '/introduction'),
  started: path(ROOTS_DOCS, '/getting-started'),
  package: path(ROOTS_DOCS, '/package'),

  // Theme UI
  color: path(ROOTS_DOCS, '/color'),
  typography: path(ROOTS_DOCS, '/typography'),
  icon: path(ROOTS_DOCS, '/icon'),
  shadows: path(ROOTS_DOCS, '/shadows'),
  components: path(ROOTS_DOCS, '/components'),
  tips: path(ROOTS_DOCS, '/tips'),

  // Development
  routing: path(ROOTS_DOCS, '/routing'),
  environmentVariables: path(ROOTS_DOCS, '/environment-variables'),
  stateManagement: path(ROOTS_DOCS, '/state-management'),
  apiCalls: path(ROOTS_DOCS, '/api-calls'),
  analytics: path(ROOTS_DOCS, '/analytics'),
  authentication: path(ROOTS_DOCS, '/authentication'),
  multiLanguage: path(ROOTS_DOCS, '/multi-language'),
  lazyload: path(ROOTS_DOCS, '/lazyload-image'),
  formHelper: path(ROOTS_DOCS, '/form-helper'),

  // Changelog
  support: path(ROOTS_DOCS, '/support'),
  changelog: path(ROOTS_DOCS, '/changelog')
};

import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit'; // redux bu
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import {
  AnnouncementModel,
  AddAnnouncementModel
} from '../../@types/announcementModel';
import { StaffInformationModel } from '../../@types/GetStaffModel';
import { CONSTS } from './const';
import { MapDeckglOverlay } from '../../components/map';
import { getAllCitySelectList } from './customer/CitySelectListGet';
// ----------------------------------------------------------------------

type AnnouncementState = {
  isLoading: boolean;
  error: boolean;
  detailModalIsOpen: boolean;
  announcementList: AnnouncementModel[]; // farklı olan diğerleri fix
  announcement: AnnouncementModel;
  StaffInformation: StaffInformationModel;
};
const announcementInit = {
  // gelen modelin propertylerin tipleri
  id: 0,
  title: '',
  description: '',
  publicationDate: new Date(),
  announcementTypeId: 0,
  announcementTypeName: '',
  takedownDate: new Date()
};

const staffInformationInit = {
  userName: '',
  surName: '',
  name: '',
  photoBase64: '',
  email: '',
  phoneNumber: '',
  cityId: 0,
  districtId: 0,
  cityName: '',
  districtName: '',
  tckNo: '',
  currentCode: 0
};

const initialState: AnnouncementState = {
  isLoading: false,
  error: false,
  detailModalIsOpen: false,
  announcementList: [],
  announcement: announcementInit,
  StaffInformation: staffInformationInit
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getStaffAccountInformation(state, action) {
      state.isLoading = false;
      state.StaffInformation = action.payload;
    },
    getAllAnnouncementSuccess(state, action) {
      state.isLoading = false;
      state.announcementList = action.payload;
    },
    filterAnnouncementSuccess(state, action) {
      state.isLoading = false;
      state.announcementList = action.payload;
    },
    saveAnnouncementSuccess(state, action) {
      state.isLoading = false;
      state.announcementList = action.payload;
    },
    getAnnouncementByIdSuccess(state, action) {
      state.isLoading = false;
      state.announcement = action.payload;
    },
    onToggleDetailModal(state, action) {
      state.detailModalIsOpen = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleDetailModal } = slice.actions;

// ----------------------------------------------------------------------

// export function addAnnouncement(model: any) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(
//         `${CONSTS.AnnouncementAddAnnouncement}`,
//         {
//           title: model.title,
//           description: model.description,
//           publicationDate: model.publicationDate,
//           announcementTypeId: Number(model.announcementTypeId),
//           takedownDate: model.takedownDate
//         }
//       );
//       localStorage.setItem('id', response.data.data.id);

//       dispatch(getAllAnnouncement());
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function getAllAnnouncement() {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(
//         CONSTS.AnnouncementGetAnnouncementList,
//         {}
//       );
//       dispatch(slice.actions.getAllAnnouncementSuccess(response.data.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function deleteAnnouncement(model: any) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(
//         `${CONSTS.AnnouncementDeleteAnnouncement}`,
//         model
//       );
//       dispatch(getAllAnnouncement());
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function getAnnouncementById(announcementId: number) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(
//         `${CONSTS.AnnouncementGetAnnouncementById}${announcementId}`
//       );
//       dispatch(slice.actions.getAnnouncementByIdSuccess(response.data.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function updateAnnouncement(model: any) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(
//         `${CONSTS.AnnouncementEditAnnounement}`,
//         {
//           id: Number(model.id),
//           title: model.title,
//           description: model.description,
//           publicationDate: model.publicationDate,
//           announcementTypeId: Number(model.announcementTypeId),
//           takedownDate: model.takedownDate
//         }
//       );
//       localStorage.setItem('id', response.data.data.id);

//       dispatch(getAllAnnouncement());
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
// export function filterAnnouncement(_query: string, _registered: boolean) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(
//         CONSTS.AnnouncementGetAnnouncementList,
//         {
//           query: _query,
//           registered: _registered
//         }
//       );
//       dispatch(slice.actions.filterAnnouncementSuccess(response.data.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function GetStaffInformation(staffEmail: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.StaffAccountInformation}?email=${staffEmail}`
      );
      dispatch(slice.actions.getStaffAccountInformation(response.data.data));
      dispatch(getAllCitySelectList());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updatStaffProfileInfo(updatemodel: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.editprofilestaff, updatemodel);
    if (response.data.success) {
      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { PATH_DASHBOARD } from './paths';
// guards
import AuthGuard from '../guards/AuthGuard';
// layouts
import DashboardLayout from '../layouts/dashboard';

// ----------------------------------------------------------------------

const DashboardRoutes = {
  path: PATH_DASHBOARD.root,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.foreignTransitCompany,
      component: lazy(
        () => import('../views/transportation/staff/ForeignTransitCompany')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.TrailerList,
      component: lazy(() => import('../views/transportation/staff/TrailerList'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.GetVehicleListWithCity,
      component: lazy(
        () => import('../views/transportation/GetVehicleListWithCity')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.DebitMaterial,
      component: lazy(
        () => import('../views/transportation/staff/DebitMaterial')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.SelfOWnedCompanies,
      component: lazy(
        () => import('../views/transportation/staff/SelfOwnedCompanies')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.OrderRequestFull,
      component: lazy(
        () => import('../views/transportation/staff/OrderRequestFull')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.DivideLoad,
      component: lazy(() => import('../views/transportation/staff/DivideLoad'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.PriceDefinationScreen,
      component: lazy(
        () => import('../views/transportation/staff/PriceDefinationScreen')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.customerScreen,
      component: lazy(
        () => import('../views/transportation/CreateOrderRequest')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.kanban,
      component: lazy(() => import('../views/transportation/Kanbannn3'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.waitingOKShipmentList,
      component: lazy(
        () => import('../views/transportation/WaitingOKShipmentList')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.ShipmentConfimModal,
      component: lazy(
        () => import('../views/transportation/staff/ShipmentConfirmModal')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.orderRequestOrganization,
      component: lazy(
        () => import('../views/transportation/OrderRequestOrganization')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.foreignVehicleRegistration,
      component: lazy(
        () =>
          import(
            '../views/transportation/staff/ForeignVehicleRegistration/ForeignVehicleRegistration'
          )
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.VehicleTypes,
      component: lazy(
        () =>
          import('../views/transportation/ManagementPanel/AracTurleriVehicles')
      )
    },

    {
      exact: true,
      path: PATH_DASHBOARD.transportation.AddTrailer,
      component: lazy(() => import('../views/transportation/staff/AddTrailer'))
    },

    {
      exact: true,
      path: PATH_DASHBOARD.transportation.EscalationScreen,
      component: lazy(
        () => import('../views/transportation/staff/EscalationScreen')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.AddDriver,
      component: lazy(
        () =>
          import(
            '../views/transportation/staff/AddDriverAndStaff/AddDriverAndStaff'
          )
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.CarDocumentFinishedVehicles,
      component: lazy(
        () =>
          import(
            '../views/transportation/ManagementPanel/BelgeBitisSuresiYaklasanVehicles'
          )
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.ForeignVehicles,
      component: lazy(
        () => import('../views/transportation/ManagementPanel/YabanciVehicles')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.upload,
      component: lazy(
        () =>
          import(
            '../components/transportComponents/ForeignVehicleRegistration/UploadMultiFile'
          )
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.staffOrder,
      component: lazy(() => import('../views/transportation/staff/StaffView'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.ManagementStaffs,
      component: lazy(
        () => import('../views/transportation/ManagementPanel/AllStaff')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.CustomerAll,
      component: lazy(
        () => import('../views/transportation/ManagementPanel/TumMusteriler')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.customerList,
      component: lazy(() => import('../views/transportation/CustomerList'))
    },
    // ilk açılan sayfa burası
    {
      exact: true,
      path: PATH_DASHBOARD.root,
      component: lazy(() => import('../views/transportation/StaffDashboard'))
    },

    {
      exact: true,
      path: PATH_DASHBOARD.transportation.StaffDashboard,
      component: lazy(() => import('../views/transportation/StaffDashboard'))
    },

    {
      exact: true,
      path: PATH_DASHBOARD.transportation.prices,
      component: lazy(() => import('../views/transportation/Customer/prices'))
    },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.transportation.ShipmentOrganization,
    //   component: lazy(
    //     () => import('../views/transportation/staff/ShipmentOrganization')
    //   )
    // },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.customerInformation,
      component: lazy(
        () => import('../views/transportation/Customer/DefinedLocations')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.order.orderRequest,
      component: lazy(() => import('../views/transportation/OrderRequests'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.personelcustomerInformation,
      component: lazy(
        () =>
          import('../views/transportation/staff/PersonelCustomerInformation')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.SelfOwnedVehicleDetail,
      component: lazy(
        () => import('../views/transportation/staff/SelfOwnedVehicleDetail')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.ContractList,
      component: lazy(
        () => import('../views/transportation/staff/ContractList')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.EditContract,
      component: lazy(
        () => import('../views/transportation/staff/EditContract')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.CustomerContractFinish,
      component: lazy(
        () =>
          import(
            '../views/transportation/ManagementPanel/SozlesmeBitisYaklasanMusteriler'
          )
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.CustomerEscalationCondition,
      component: lazy(
        () =>
          import(
            '../views/transportation/ManagementPanel/EskalasyonSartiSaglayanMusteriler'
          )
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.order.orderShippingStatuses,
      component: lazy(
        () => import('../components/transportComponents/ShipmentConditions')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.ForeignVehicleStaff,
      component: lazy(
        () => import('../views/transportation/staff/StaffForeignVehicle')
      )
    },

    {
      exact: true,
      path: PATH_DASHBOARD.transportation.StaffList,
      component: lazy(() => import('../views/transportation/staff/StaffList'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.SelfOwnedVehicleStaff,
      component: lazy(
        () => import('../views/transportation/staff/StaffSelfOwnedVehicle')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.AddCustomerInfo,
      component: lazy(
        () => import('../views/transportation/staff/AddCustomerInformation')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.createOrderEdit,
      component: lazy(() => import('../views/transportation/createOrderEdit'))
    },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.transportation.shipmentFinalConfirmation,
    //   component: lazy(
    //     () => import('../views/transportation/staff/shipmentFinalConfirmation')
    //   )
    // },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.AddForeignvehicle,
      component: lazy(
        () =>
          import(
            '../views/transportation/staff/ForeignVehicleRegistration/ForeignVehicleRegistration'
          )
      )
    }, // burası
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.WorkedTransitCompanies,
      component: lazy(
        () =>
          import(
            '../views/transportation/ManagementPanel/TransitFirmalarStaffs'
          )
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.StaffsContractFinished,
      component: lazy(
        () =>
          import(
            '../views/transportation/ManagementPanel/BelgeBitisSuresiYaklasanStaffs'
          )
      )
    },
    // ozmal Vehicle -----
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.AddSelfOwnedVehicle,
      component: lazy(
        () =>
          import(
            '../views/transportation/staff/SelfOwnedVehicleAdd/SelfOwnedVehicleAdd'
          )
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.SelfOwnedVehiclesManagementPanel,
      component: lazy(
        () =>
          import('../views/transportation/ManagementPanel/SelfOwnedVehicles')
      )
    },
    // --------------------------------------------- nakliyat--------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.workingArea.classes,
      component: lazy(
        () =>
          import(
            '../components/libraryComponents/WorkingAreaComponents/Classes'
          )
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.transportation.CustomerAccount,
      component: lazy(
        () => import('../views/transportation/Customer/CustomerAccount')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.workingArea.quietLibrary,
      component: lazy(
        () =>
          import(
            '../components/libraryComponents/WorkingAreaComponents/QuietLibrary'
          )
      )
    },

    {
      exact: true,
      path: PATH_DASHBOARD.workingArea.workingRooms,
      component: lazy(
        () =>
          import(
            '../components/libraryComponents/WorkingAreaComponents/StudiesRooms'
          )
      )
    },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.general.qrCode,
    //   component: lazy(() => import('../views/libraryViews/turnstile/QrCode'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.general.analytics,
    //   component: lazy(() => import('../views/libraryViews/Analytics'))
    // },

    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.workingArea.root,
    //   component: lazy(() => import('../views/libraryViews/WorkingArea'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.general.silentLibrary,
    //   component: lazy(() => import('../views/libraryViews/SilentLibrary'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.general.showApointment,
    //   component: lazy(() => import('../views/libraryViews/ShowApointment'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.general.apointment,
    //   component: lazy(() => import('../views/libraryViews/Apointment'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.general.workingSchedule,
    //   component: lazy(() => import('../views/libraryViews/WorkingSchedule'))
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.general.table,
    //   component: lazy(() => import('../views/libraryViews/Table'))
    // },

    // Announcement ----------------------------------------------------------------------------

    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.announcement.root,
    //   component: lazy(() => import('../views/libraryViews/Announcement'))
    // },
    {
      exact: true,
      path: PATH_DASHBOARD.announcement.normalAnnouncement,
      component: lazy(
        () =>
          import('../components/libraryComponents/Announcement/Announcement')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.announcement.presidentAnnouncement,
      component: lazy(
        () =>
          import(
            '../components/libraryComponents/Announcement/PresidentAnnouncement'
          )
      )
    },

    // Turnstile -----------------------------------------------------------------------------------------

    {
      exact: true,
      path: PATH_DASHBOARD.general.analytics,
      component: () => <Redirect to={PATH_DASHBOARD.general.analytics} />
    },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.general.turnstile,
    //   component: lazy(
    //     () => import('../views/libraryViews/turnstile/TurnstileGround')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_DASHBOARD.general.turnstileFirst,
    //   component: lazy(
    //     () => import('../views/libraryViews/turnstile/TurnstileFirst')
    //   )
    // },

    // MANAGEMENT : E-COMMERCE
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.eCommerce.shop,
      component: lazy(() => import('../views/EcommerceShop'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.eCommerce.product,
      component: lazy(() => import('../views/EcommerceProductDetails'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.eCommerce.list,
      component: lazy(() => import('../views/EcommerceProductList'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.eCommerce.checkout,
      component: lazy(() => import('../views/EcommerceCheckout'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.eCommerce.invoice,
      component: lazy(() => import('../views/EcommerceInvoice'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.eCommerce.root,
      component: () => <Redirect to={PATH_DASHBOARD.eCommerce.shop} />
    },

    // MANAGEMENT : BLOG
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.blog.root,
      component: lazy(() => import('../views/BlogPosts'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.blog.post,
      component: lazy(() => import('../views/BlogPost'))
    },

    // MANAGEMENT : USER
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.user.profile,
      component: lazy(() => import('../views/UserProfile'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.user.cards,
      component: lazy(() => import('../views/UserCards'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.user.list,
      component: lazy(() => import('../views/UserList'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.user.account,
      component: lazy(() => import('../views/UserAccount'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.user.root,
      component: () => <Redirect to={PATH_DASHBOARD.user.profile} />
    },

    // APP : CHAT
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.chat.conversation,
      component: lazy(() => import('../views/Chat'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.chat.root,
      component: () => <Redirect to={PATH_DASHBOARD.chat.new} />
    },

    // APP : MAIL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.mail.labels,
      component: lazy(() => import('../views/Mail'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.mail.root,
      component: () => <Redirect to={PATH_DASHBOARD.mail.all} />
    },

    // APP : CALENDAR
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.calendar,
      component: lazy(() => import('../views/Calendar'))
    },

    // FOUNDATIONS
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.foundations.colors,
      component: lazy(
        () => import('../views/uikit-foundations/FoundationColors')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.foundations.typography,
      component: lazy(
        () => import('../views/uikit-foundations/FoundationTypography')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.foundations.shadows,
      component: lazy(
        () => import('../views/uikit-foundations/FoundationShadows')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.foundations.grid,
      component: lazy(() => import('../views/uikit-foundations/FoundationGrid'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.foundations.icons,
      component: lazy(() => import('../views/uikit-foundations/icon'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.foundations.root,
      component: () => <Redirect to={PATH_DASHBOARD.foundations.colors} />
    },

    // COMPONENTS
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.components.accordion,
      component: lazy(() => import('../views/uikit-components/accordion'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.alert,
      component: lazy(() => import('../views/uikit-components/Alert'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.autocomplete,
      component: lazy(() => import('../views/uikit-components/autocomplete'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.avatar,
      component: lazy(() => import('../views/uikit-components/Avatar'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.badge,
      component: lazy(() => import('../views/uikit-components/Badge'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.breadcrumbs,
      component: lazy(() => import('../views/uikit-components/Breadcrumb'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.buttons,
      component: lazy(() => import('../views/uikit-components/buttons'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.chip,
      component: lazy(() => import('../views/uikit-components/chips'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.dialog,
      component: lazy(() => import('../views/uikit-components/dialog'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.textfield,
      component: lazy(() => import('../views/uikit-components/textfield'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.label,
      component: lazy(() => import('../views/uikit-components/Label'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.lists,
      component: lazy(() => import('../views/uikit-components/Lists'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.menu,
      component: lazy(() => import('../views/uikit-components/Menus'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.pagination,
      component: lazy(() => import('../views/uikit-components/Pagination'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.pickers,
      component: lazy(() => import('../views/uikit-components/pickers'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.popover,
      component: lazy(() => import('../views/uikit-components/Popover'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.progress,
      component: lazy(() => import('../views/uikit-components/progress'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.rating,
      component: lazy(() => import('../views/uikit-components/Rating'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.selectionControls,
      component: lazy(
        () => import('../views/uikit-components/selection-controls')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.snackbar,
      component: lazy(() => import('../views/uikit-components/Snackbar'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.slider,
      component: lazy(() => import('../views/uikit-components/Slider'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.stepper,
      component: lazy(() => import('../views/uikit-components/stepper'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.tabs,
      component: lazy(() => import('../views/uikit-components/Tabs'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.table,
      component: lazy(() => import('../views/uikit-components/table'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.timeline,
      component: lazy(() => import('../views/uikit-components/Timeline'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.tooltip,
      component: lazy(() => import('../views/uikit-components/Tooltip'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.transferList,
      component: lazy(() => import('../views/uikit-components/transfer-list'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.treeView,
      component: lazy(() => import('../views/uikit-components/TreeView'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.root,
      component: () => <Redirect to={PATH_DASHBOARD.components.accordion} />
    },

    // EXTRA COMPONENTS
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.components.chart,
      component: lazy(() => import('../views/uikit-extra-components/Charts'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.map,
      component: lazy(() => import('../views/uikit-extra-components/Map'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.editor,
      component: lazy(() => import('../views/uikit-extra-components/Editor'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.copyToClipboard,
      component: lazy(
        () => import('../views/uikit-extra-components/CopyToClipboard')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.upload,
      component: lazy(() => import('../views/uikit-extra-components/Upload'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.carousel,
      component: lazy(() => import('../views/uikit-extra-components/Carousel'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.multiLanguage,
      component: lazy(
        () => import('../views/uikit-extra-components/MultiLanguage')
      )
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.animate,
      component: lazy(() => import('../views/uikit-extra-components/animate'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.components.extraComponents,
      component: () => <Redirect to={PATH_DASHBOARD.components.chart} />
    },

    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default DashboardRoutes;

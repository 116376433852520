import { Box, BoxProps } from '@material-ui/core';
import { useSelector } from 'react-redux';
// import useAuth from '../hooks/useAuth';
import { RootState } from '../redux/store';
// ----------------------------------------------------------------------

export default function Logo(props: BoxProps) {
  // const { user } = useAuth();
  const { user } = useSelector((state: RootState) => state.authJwt);
  const data = user.photoURL;
  // const defaultdata = '/static/brand/logo_single.png';
  const defaultdata = '/static/brand/seker_logistics2.png';

  return (
    // <img
    //   src={`data:image/jpg;base64,${user.photoURL}`}
    //   style={{ width: '20', height: '40' }}
    // />
    <Box
      component="img"
      alt="logo"
      src={
        user.role === 'Customer'
          ? `data:image/jpeg;base64,${data}`
          : defaultdata
      }
      // src={defaultdata}
      height={40}
      {...props}
    />
  );
}

// src = '/static/brand/logo_single.png';

import { createSlice } from '@reduxjs/toolkit'; // redux bu
import { dispatch } from '../../store';
// utils
import axios from '../../../utils/axios';
import {
  GetOrderRequestModel,
  UpdateOrderRequestModel,
  LoadSplitOrderRequestModel,
  waitingRequestModel,
  cancelShipmentValues,
  paymentMethotSelectListModel,
  CompletedOrderModel,
  GetProcessedListModel,
  WayOrderRequestList,
  finalAprovalModel,
  UpdateJoinRateValues,
  finalConfirmOrderRequestModel,
  CashierSelectListModel,
  ShippedShipmentListModel,
  CancelOrderRequestListModel,
  AddOrderRequestWaybillOrDeliveryDocumentModel,
  LoadingTypeSelectListModel,
  AllDocumentForCompletedOrderModel,
  GetOrderDocumentBase64Model,
  AddOrderRequestWaybillNoOrDeliveryDocumentNoModel,
  StationModel,
  StationsSelectList,
  ResponseUETDSModel,
  CompletedOrderDetailModel,
  OrderRaportModel
} from '../../../@types/OrderRequestModel';
import { CONSTS } from '../const';
import { MapDeckglOverlay } from '../../../components/map';
import { AnalyticsNewsUpdate } from '../../../components/general/analytics';
import {
  getAllCitySelectList,
  getDistrictForCitySelectList
} from './CitySelectListGet';

// ----------------------------------------------------------------------

type OrderRequestState = {
  isLoading: boolean;
  error: boolean;
  detailModalIsOpen: boolean;
  orderRequestDetailisOpen: boolean;
  orderRequestList: GetOrderRequestModel[]; // farklı olan diğerleri fix
  orderRequest: GetOrderRequestModel;
  orderRequestRowId: string;
  orderRequestByIdList: UpdateOrderRequestModel[];
  orderRequestById: UpdateOrderRequestModel;
  orderRequestByIdLoadSplit: LoadSplitOrderRequestModel[];
  orderRequestLoadSplit: LoadSplitOrderRequestModel;
  orderRequestId: string;
  customerCompanyName: string;
  OrderListFullTabPanelIndex: number;
  AllDocumentForCompletedOrderModal: boolean;
  orderRequestSplitFullScreenIsOpen: boolean;
  waitingShipmentList: waitingRequestModel[];
  shipmentId: number;
  orderId: number[];
  orderIdForUpdateJoinRate: number;
  vehicleIdShipment: number;
  cancelShipmentModal: boolean;
  FinalShipmentModal: boolean;
  cancelShipmentValues: cancelShipmentValues;
  UpdateJoinRateValues: UpdateJoinRateValues;
  paymentMethotSelectList: paymentMethotSelectListModel[];
  stationSelectList: StationsSelectList[];
  CashierSelectList: CashierSelectListModel[];
  GetCompletedOrder: CompletedOrderModel[];
  ProcessedList: GetProcessedListModel[];
  CancelOrderRequestList: CancelOrderRequestListModel[];
  ShippedShipmentList: ShippedShipmentListModel[];
  WayOrderRequest: WayOrderRequestList[];
  FinalApproval: finalAprovalModel;
  UpdatePriceForFinalShipmentModal: boolean;
  finalConfirmOrderRequest: finalConfirmOrderRequestModel;
  waybillInOrderModal: boolean;
  stationModal: boolean;
  stationAddOrEdit: StationModel;
  AddOrderRequestWaybillOrDeliveryDocument: AddOrderRequestWaybillOrDeliveryDocumentModel;
  handleCargoAmountDivideLoadModal: boolean;
  LoadingTypeSelectList: LoadingTypeSelectListModel[];
  finalConfirmationOrderRequestsId: number;
  AllDocumentForCompletedOrderList: AllDocumentForCompletedOrderModel[];
  GetOrderDocumentBase64: GetOrderDocumentBase64Model;
  addWaybillNoOrDeliveryDocumentNo: AddOrderRequestWaybillNoOrDeliveryDocumentNoModel;
  isDataLoading: boolean;
  DynamicListId: number;
  UETDSResponseFullScreeenModal: boolean;
  ResponseUETDS: ResponseUETDSModel[];
  CompletedOrderDetailModal: boolean;
  CompletedOrderDetail: CompletedOrderDetailModel;
  CompletedOrderDetailUpdateisForeign: boolean;
  OrderRaportModal: boolean;
  OrderRaportModel: OrderRaportModel;
  shipmentConfirmFinalModal: boolean;
};

const OrderRaportModelInit = {
  beginDate: new Date(),
  endDate: new Date()
};

const stationAddOrEditInıt = {
  companyName: '',
  currentCode: '',
  cityId: 0,
  districtId: 0,
  id: 0
};

const addWaybillNoOrDeliveryDocumentNoInit = {
  finalConfirmationOrderRequestId: 0,
  wayBillNo: 0,
  deliveryDocumentNo: 0
};

const GetOrderDocumentBase64Init = {
  id: 0,
  documentExtension: '',
  documentTypeId: 0,
  mimeType: '',
  documentTypeName: '',
  startDate: '',
  endDate: '',
  documentImageFileBase64String: '',
  documentImageName: '',
  documnetStatüs: '',
  vehicleId: 0,
  staffId: 0,
  finalConfirmationOrderRequestId: 0
};

const loadSplitOrderRequestInit = {
  id: 0,
  customerId: 0,
  cargoAmount: 0,
  amountDivided: 0,
  cargoUnitName: '',
  cargoUnitId: 0
};
const orderRequestInit = {
  // gelen modelin propertylerin tipleri
  id: 0,
  customerId: 0,
  customerCompanyName: '',
  sourceLocationId: 0,
  sourceLocationName: '',
  createDate: new Date(),
  sourceLocationSelectList: null,
  destinationLocationId: 0,
  destinationLocationName: '',
  destinationLocationSelectList: null,
  deliveryDate: '',
  cargoAmount: 0,
  sourceLocationCityName: '',
  sourceLocationDistrictName: '',
  destinationCityName: '',
  destinationDistrictName: '',
  cargoUnitId: 0,
  cargoUnitName: null,
  cargoUnitSelectList: null,
  cargoDimensions: '',
  vehicleTypeId: 0,
  vehicleTypeName: '',
  vehicleTypeSelectList: null,
  note: null,
  orderStatusId: 0,
  orderStatusName: '',
  orderStatusSelectList: null,
  jointRate: 0,
  cargoTypeId: 0,
  cargoTypeName: null,
  cargoTypeSelectList: null,
  width: 0,
  height: 0,
  depth: 0,
  transportTypeId: null,
  transportTypeName: null,
  transportTypeSelectList: null,
  cargoTypeOtherDesc: null,
  ladingName: '',
  loadingTypeId: null,
  loadingTypeName: null,
  loadingTypeSelectList: null,
  rootId: null,
  destinationVerbalAddress: '',
  trailerTypeId: 0,
  paymentMethodId: 0
};

const UpdateOrderRequestInit = {
  customerId: 0,
  id: 0,
  sourceLocationId: 0,
  destinationLocationId: 0,
  ladingName: '',
  deliveryDate: new Date(),
  transportTypeId: 0,
  cargoTypeId: 0,
  cargoAmount: 0,
  cargoUnitId: 0,
  cargoTypeOtherDesc: '',
  note: '',
  createDate: new Date(),
  orderStatusId: 0,
  jointRate: 0,
  cargoDimensions: '',
  trailerTypeId: 0,
  paymentMethodId: 0,
  sourceVerbalAddress: '',
  destinationVerbalAddress: '',
  customerCompanyName: '',
  vehicleTypeId: 0,
  loadingTypeId: 0,
  senderCustomerId: 0,
  buyerCustomerId: 0,
  dangerousMaterialsTransportTypeId: 0,
  unNumber: 0,
  orderUnitPrice: 0,
  paymentTypeId: 0,
  currencyType: '',
  foreignCurrencyAmount: 0
};
const cancelShipmentValuesInıt = {
  ShipmentId: 0,
  Cancel: ''
};

const UpdateJoinRateValuesInıt = {
  processOrderRequestId: 0,
  joinRate: 0,
  isJoinRate: false
};

const finalAprovalModelInıt = {
  amountOfLoadLoaded: 0,
  isForeign: true,
  cargoCapacity: 0,
  vehicleLicensePlate: '',
  orderRequests: [],
  totalPrice: 0,
  documnetStatüs: []
};

const finalConfirmOrderRequestInıt = {
  driverNote: '',
  isForeign: false,
  transportPrice: 0,
  commissionAmount: 0,
  shipmentsId: [],
  cashiersId: 0,
  vehicleId: 0,
  paymentMethodId: 0,
  totalJoinRate: 0,
  PlannedPaymentDate: '',
  PayAsDieselChip: false,
  UnitPrice: 0,
  // UnitCommission: 0,

  payDieselChipNo: 0,
  payDieselPrice: 0,

  payAsCashChip: false,
  payCashPrice: 0,
  payCashChipNo: 0,
  stationId: 0
};

const AddOrderRequestWaybillOrDeliveryDocumentInıt = {
  documentTypeId: 0,
  documentImageBaseString: '',
  documentImageName: '',
  finalConfirmationOrderRequestId: 0,
  note: ''
};

const CompletedOrderDetailModelInit = {
  finalConfirmOrderRequestId: 0,
  orderNumber: 0,
  orderName: '',
  customerId: 0,
  buyerCustomer: '',
  senderCustomer: '',
  startLocation: '',
  endLocation: '',
  transportType: '',
  cargoAmount: 0,
  totalPriceOrder: 0,
  unitPriceOrder: 0,
  totalPriceForeign: 0,
  unitPriceForeign: 0,
  paymentTypeId: 0,
  dangerousMaterialsTransportType: '',
  unNumber: '',
  vehicleLicencePlate: '',
  trailerVehicleLicencePlate: '',
  driverNameAndSurname: '',
  statusUETDS: false,
  uetdsMessage: '',
  statusAccounting: false,
  accountingMessage: '',
  cargoUnitName: '',
  cargoType: '',
  isForeign: false,
  payAsCashChip: false, // nnkit ödeme yapıldı mı
  payCashChipNo: 0, // nakit ödeme fiş no
  payCashPrice: 0, // nakit ödeme tutarı
  payAsDieselChip: false, // motorin fişi ile ödeme yapıldı mı
  payDieselChipNo: 0, // motorin fiş no
  payDieselPrice: 0, // motorin tutarı
  stationsId: 0,
  commissionAmount: 0,
  cashierId: 0,
  accountingTypeCompletedTransferInformation: []
};

const initialState: OrderRequestState = {
  isLoading: false,
  error: false,
  orderRequestDetailisOpen: false,
  detailModalIsOpen: false,
  orderRequestList: [],
  orderRequest: orderRequestInit,
  orderRequestRowId: '',
  orderRequestByIdList: [],
  orderRequestById: UpdateOrderRequestInit,
  orderRequestByIdLoadSplit: [],
  orderRequestLoadSplit: loadSplitOrderRequestInit,
  orderRequestId: '',
  customerCompanyName: '',
  OrderListFullTabPanelIndex: 0,
  AllDocumentForCompletedOrderModal: false,
  orderRequestSplitFullScreenIsOpen: false,
  waitingShipmentList: [],
  shipmentId: 0,
  orderId: [],
  vehicleIdShipment: 0,
  cancelShipmentModal: false,
  FinalShipmentModal: false,
  cancelShipmentValues: cancelShipmentValuesInıt,
  UpdateJoinRateValues: UpdateJoinRateValuesInıt,
  paymentMethotSelectList: [],
  GetCompletedOrder: [],
  ProcessedList: [],
  ShippedShipmentList: [],
  WayOrderRequest: [],
  CancelOrderRequestList: [],
  FinalApproval: finalAprovalModelInıt,
  UpdatePriceForFinalShipmentModal: false,
  finalConfirmOrderRequest: finalConfirmOrderRequestInıt,
  CashierSelectList: [],
  waybillInOrderModal: false,
  stationModal: false,
  stationAddOrEdit: stationAddOrEditInıt,
  AddOrderRequestWaybillOrDeliveryDocument: AddOrderRequestWaybillOrDeliveryDocumentInıt,
  handleCargoAmountDivideLoadModal: false,
  LoadingTypeSelectList: [],
  finalConfirmationOrderRequestsId: 0,
  orderIdForUpdateJoinRate: 0,
  AllDocumentForCompletedOrderList: [],
  GetOrderDocumentBase64: GetOrderDocumentBase64Init,
  addWaybillNoOrDeliveryDocumentNo: addWaybillNoOrDeliveryDocumentNoInit,
  isDataLoading: false,
  stationSelectList: [],
  DynamicListId: 0,
  UETDSResponseFullScreeenModal: false,
  ResponseUETDS: [],
  CompletedOrderDetailModal: false,
  CompletedOrderDetail: CompletedOrderDetailModelInit,
  CompletedOrderDetailUpdateisForeign: false,
  OrderRaportModal: false,
  OrderRaportModel: OrderRaportModelInit,
  shipmentConfirmFinalModal: false
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getAllOrderRequestSuccess(state, action) {
      // her  metod için 2 tane error ve succes metodları yazdık
      state.isLoading = false;
      state.orderRequestList = action.payload;
    },

    getAllOrderRequestFullSuccess(state, action) {
      // her  metod için 2 tane error ve succes metodları yazdık
      state.isLoading = false;
      state.orderRequestList = action.payload;
    },

    saveOrderRequestSuccess(state, action) {
      state.isLoading = false;
      state.orderRequestList = action.payload;
    },
    setOrderRequestId(state, action) {
      state.orderRequestId = action.payload;
    },
    getOrderRequestByIdSuccess(state, action) {
      state.isLoading = false;
      state.orderRequestById = action.payload;
    },
    getOrderRequestByIdSplit(state, action) {
      state.isLoading = false;
      state.orderRequestLoadSplit = action.payload;
      state.orderRequestByIdLoadSplit = [];
    },
    getOrderRequestSplit(state, action) {
      state.isLoading = false;
      state.orderRequestByIdLoadSplit = action.payload;
    },

    getCustomerOrderRequestForStaffById(state, action) {
      state.isLoading = false;
      //  state.orderRequest = action.payload;
      state.orderRequestList = action.payload;
    },

    onToggleDetailModal(state, action) {
      state.detailModalIsOpen = action.payload;
    },
    orderRequestSplitFullScreenModal(state, action) {
      state.orderRequestSplitFullScreenIsOpen = action.payload;
    },
    onToggleOrderRequestDetailModal(state, action) {
      state.orderRequestDetailisOpen = action.payload;
    },
    setOrderRequestRowId(state, action) {
      state.orderRequestRowId = action.payload;
    },
    setCustomerCompanyName(state, action) {
      state.customerCompanyName = action.payload;
    },
    setOrderListFullTabPanelIndex(state, action) {
      state.OrderListFullTabPanelIndex = action.payload;
    },
    setAllDocumentForCompletedOrderModal(state, action) {
      state.AllDocumentForCompletedOrderModal = action.payload;
    },
    getwaitingShipmentListSuccess(state, action) {
      state.waitingShipmentList = action.payload;
    },
    setShipmentId(state, action) {
      state.shipmentId = action.payload;
    },

    setcancelShipmentModal(state, action) {
      state.cancelShipmentModal = action.payload;
    },

    setFinalShipmentModal(state, action) {
      state.FinalShipmentModal = action.payload;
    },

    getPaymentMethotSelectListSuccess(state, action) {
      state.paymentMethotSelectList = action.payload;
    },

    getstationSelectListSuccess(state, action) {
      state.stationSelectList = action.payload;
    },

    GetCashierSelectListSuccess(state, action) {
      state.CashierSelectList = action.payload;
    },

    getCompleteOrderSuccess(state, action) {
      state.GetCompletedOrder = action.payload;
    },
    getProcessedListSuccess(state, action) {
      state.ProcessedList = action.payload;
    },
    ShippedShipmentListSuccess(state, action) {
      state.ShippedShipmentList = action.payload;
    },
    getWayOrderRequestSuccess(state, action) {
      state.WayOrderRequest = action.payload;
    },
    GetFinalApprovalSuccess(state, action) {
      state.FinalApproval = action.payload;
    },
    SetUpdatePriceForFinalShipmentModal(state, action) {
      state.UpdatePriceForFinalShipmentModal = action.payload;
    },
    SetvehicleIdShipment(state, action) {
      state.vehicleIdShipment = action.payload;
    },
    SetOrderId(state, action) {
      state.orderId = action.payload;
    },
    SetwaybillInOrderModal(state, action) {
      state.waybillInOrderModal = action.payload;
    },
    SetStationModal(state, action) {
      state.stationModal = action.payload;
    },
    SetstationAddOrEditSuccess(state, action) {
      state.stationAddOrEdit = action.payload;
    },

    getCancelOrderrequestListSuccess(state, action) {
      state.CancelOrderRequestList = action.payload;
    },
    setHandleCargoAmountDivideLoadModal(state, action) {
      state.handleCargoAmountDivideLoadModal = action.payload;
    },
    getLoadingTypeSelectListSuccess(state, action) {
      state.LoadingTypeSelectList = action.payload;
    },
    setfinalConfirmationOrderRequestsId(state, action) {
      state.finalConfirmationOrderRequestsId = action.payload;
    },
    setorderIdForUpdateJoinRate(state, action) {
      state.orderIdForUpdateJoinRate = action.payload;
    },
    AllDocumentForCompletedOrderSuccess(state, action) {
      state.AllDocumentForCompletedOrderList = action.payload;
    },

    getOrderDocumentByIdBase64Success(state, action) {
      state.GetOrderDocumentBase64 = action.payload;
    },

    GetWaybillNoOrDeliveryDocumentNooSuccess(state, action) {
      state.addWaybillNoOrDeliveryDocumentNo = action.payload;
    },
    SetDynamicListId(state, action) {
      state.DynamicListId = action.payload;
    },
    SetUETDSResponseFullScreeenModal(state, action) {
      state.UETDSResponseFullScreeenModal = action.payload;
    },
    successUETDSResponse(state, action) {
      state.ResponseUETDS = action.payload;
    },
    successAccountingResponse(state, action) {
      state.ResponseUETDS = action.payload;
    },
    setCompletedOrderDetailModal(state, action) {
      state.CompletedOrderDetailModal = action.payload;
    },
    getCompletedOrderDetailSuccess(state, action) {
      state.CompletedOrderDetail = action.payload;
    },
    SetCompletedOrderDetailUpdateisForeign(state, action) {
      state.CompletedOrderDetailUpdateisForeign = action.payload;
    },
    SetOrderRaportModal(state, action) {
      state.OrderRaportModal = action.payload;
    },

    SetShipmentConfirmFinalModal(state, action) {
      state.shipmentConfirmFinalModal = action.payload;
    }
  }
});
// Reducer
export default slice.reducer;
// Actions
export const {
  onToggleDetailModal,
  setOrderRequestId,
  onToggleOrderRequestDetailModal,
  setOrderRequestRowId,
  getOrderRequestByIdSplit,
  setCustomerCompanyName,
  setOrderListFullTabPanelIndex,
  setAllDocumentForCompletedOrderModal,
  orderRequestSplitFullScreenModal,
  setShipmentId,
  setcancelShipmentModal,
  setFinalShipmentModal,
  SetUpdatePriceForFinalShipmentModal,
  SetvehicleIdShipment,
  SetOrderId,
  SetwaybillInOrderModal,
  SetStationModal,
  setHandleCargoAmountDivideLoadModal,
  setfinalConfirmationOrderRequestsId,
  setorderIdForUpdateJoinRate,
  SetDynamicListId,
  SetUETDSResponseFullScreeenModal,
  setCompletedOrderDetailModal,
  SetCompletedOrderDetailUpdateisForeign,
  SetOrderRaportModal,
  SetShipmentConfirmFinalModal
} = slice.actions;

// ----------------------------------------------------------------------

// export async function OrderRaportFunc(model: any) {
//   dispatch(slice.actions.startLoading());
//   try {
//     const response = await axios.post(CONSTS.orderRaportFunction, model);
//     if (response.data.success) {
//       return response;
//     }
//     return false;
//   } catch (error) {
//     dispatch(slice.actions.hasError(error));
//     return false;
//   }
// }

export async function OrderRaportFunc(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.orderRaportFunction, model, {
      responseType: 'arraybuffer' // Bu, dosyanın binary veri olduğunu belirtir
    });

    if (response.data instanceof ArrayBuffer) {
      // Dosya indirme işlemi
      const blob = new Blob([response.data], {
        type: 'application/octet-stream'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'sipariş.xlsx'; // İndirilecek dosyanın adını belirtin
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      return true; // İşlem başarılı
    }

    // Başarısız işlem
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    // Hata durumu
    return false;
  }
}

export async function removeStationById(id: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.stationDeleteById}?stationid=${id}`
    );
    if (response) {
      dispatch(getStationtSelectList());
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function stationAddFunction(model: any) {
  // Customer Rol tarafındaki güncellemeyi yaptıgımız için localstrodaki customerıd yi alıyoruz.
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.stationAddFunction, model);
    if (response.data.success) {
      //   dispatch(slice.actions.SetstationAddOrEditSuccess(response.data.data));

      return response.data.success;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getStationById(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.stationGetFunction}?stationid=${id}`
      );
      const districtid = response.data.data.cityId.toString();
      dispatch(getAllCitySelectList());
      dispatch(getDistrictForCitySelectList(districtid));
      dispatch(slice.actions.SetstationAddOrEditSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function stationEditFunction(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.stationEditFunction, model);
    if (response.data.success) {
      //    dispatch(slice.actions.SetstationAddOrEditSuccess(response.data.data));
      dispatch(getStationtSelectList());
      return response.data.success;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getStationtSelectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONSTS.stationSelectList}`);
      dispatch(slice.actions.getstationSelectListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function addLocations(model: any) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(`${CONSTS.GetOrderRequests}`, {
//         customerCompanyName: model.customerCompanyName,
//         deliveryDate: model.deliveryDate,
//         vehicleTypeName: model.vehicleTypeName,
//         cargoDimensions: model.cargoDimensions
//       });
//       localStorage.setItem('id', response.data.data.id);

//       dispatch(getCustomerAllOrderRequest());
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function getAllOrderRequestFull() {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(`${CONSTS.GetAllOrderRequests}`);
//       dispatch(slice.actions.getAllOrderRequestFullSuccess(response.data.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function getCustomerAllOrderRequest() {
  const customerId = localStorage.getItem('Customer');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.GetCustomerOrderRequests}?customerId=${customerId}`
      );
      dispatch(slice.actions.getAllOrderRequestSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function orderRequestByIdSplit(orderRequestId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.getOrderRequestSplit}?orderRequestId=${Number(
          orderRequestId
        )}`
      );
      dispatch(slice.actions.getOrderRequestByIdSplit(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function orderRequestSplit(
  cargoAmount: any,
  amountDividedpost: any,
  orderRequestId: any
) {
  const amountDivided = amountDividedpost.value;

  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(
      `${CONSTS.getOrderRequestSplit2}?cargoAmount=${cargoAmount}&amountDivided=${amountDivided}&orderRequestId=${orderRequestId}`
    );
    if (response.data.message === null) {
      dispatch(slice.actions.getOrderRequestSplit(response.data.data));
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getOrderRequestById(orderRequestId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.getOrderRequestById}?orderRequestId=${orderRequestId}`
      );
      dispatch(slice.actions.getOrderRequestByIdSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function divideCargoListOrder(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${CONSTS.divideListOrder}`, model);
    if (response) {
      return true;
    }
    return true;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function removeOrderRequest(orderRequestIdListt: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.DeleteCustomerOrderRequest}`,
      orderRequestIdListt
    );
    if (response) {
      dispatch(getCustomerAllOrderRequest());
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}
export function GroupOrderRequestprocessing(
  orderRequestIdListt: any,
  customerId: any
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${CONSTS.GroupOrderRequestProcessing}`,
        orderRequestIdListt
      );
      dispatch(getGetCustomerOrderRequestForStaffById(customerId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function OrderRequestProcessing(
  orderRequestIdListt: any,
  customerId: any
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${CONSTS.OrderRequestProcessing}`,
        orderRequestIdListt
      );
      dispatch(getGetCustomerOrderRequestForStaffById(customerId));
      dispatch(getWayOrderRequest());

      dispatch(getProcessedList());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function removeOrderRequestStaff(
  orderRequestIdListt: any,
  customerId: any
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${CONSTS.DeleteCustomerOrderRequest}`,
        orderRequestIdListt
      );
      if (response) {
        dispatch(getGetCustomerOrderRequestForStaffById(customerId));
        dispatch(getWayOrderRequest());
        return true;
      }
      return false;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export async function removeOrderProccessStaff(id: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${CONSTS.deleteproccessorder}`, id);

    if (response.data) {
      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getGetCustomerOrderRequestForStaffById(customerId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.GetCustomerOrderRequests}?customerId=${Number(customerId)}`
      );
      if (response.data.data !== null) {
        dispatch(
          slice.actions.getCustomerOrderRequestForStaffById(response.data.data)
        );
        dispatch(getCustomerAllOrderRequest());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function customerUpdateOrderRequest(orderRequestUpdate: any) {
  // Customer Rol tarafındaki güncellemeyi yaptıgımız için localstrodaki customerıd yi alıyoruz.
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      CONSTS.UpdateCustomerOrderRequest,
      orderRequestUpdate
    );
    if (response.data.success) {
      const responsee = await axios.get(
        `${CONSTS.GetCustomerOrderRequests}?customerId=${orderRequestUpdate.customerId}`
      );
      dispatch(slice.actions.getAllOrderRequestSuccess(responsee.data.data));
      dispatch(getCustomerAllOrderRequest());

      return response.data.success;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getwaitingShipmentList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONSTS.waitingShipmentList}`);
      if (response.data.success) {
        dispatch(
          slice.actions.getwaitingShipmentListSuccess(response.data.data)
        );
        return response.data;
      }
      return false;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}
export async function cancelShipment(shipmentId: any, cancel: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.cancelShipment}?shipmentId=${shipmentId}&cancel=${cancel}`
    );
    if (response.data.success) {
      getwaitingShipmentList();

      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getPaymentMethotSelectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONSTS.paymentMethotSelectList}`);
      dispatch(
        slice.actions.getPaymentMethotSelectListSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGetCashierSelectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONSTS.cashierSelectList}`);
      dispatch(slice.actions.GetCashierSelectListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompleteOrder() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONSTS.completeOrder}`);
      if (response.data.data !== null) {
        await dispatch(
          slice.actions.getCompleteOrderSuccess(response.data.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function HandleDeliveryDateRangeList(firstDate: any, secondDate: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.HandleDeliveryDateRange}?firstDate=${firstDate}&secondDate=${secondDate}`
      );
      if (response.data.data !== null) {
        await dispatch(
          slice.actions.getCompleteOrderSuccess(response.data.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProcessedList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONSTS.proccesedList}`);
      if (response.data.data !== null) {
        await dispatch(
          slice.actions.getProcessedListSuccess(response.data.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCancelOrderrequestList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONSTS.CancelOrderRequestList}`);
      if (response.data.data !== null) {
        await dispatch(
          slice.actions.getCancelOrderrequestListSuccess(response.data.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function OrderCompleteOK(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.waybillanddeliverydocumentaddandCompleteOrder}`,
      {
        // documentId: 0,
        //  documentTypeName: model.documentTypeName,
        documentTypeId: model.documentTypeId,
        documentImageBaseString: model.documentImageBaseString,
        documentImageName: model.documentImageName,
        finalConfirmationOrderRequestId: Number(
          model.finalConfirmationOrderRequestId
        ),
        note: model.note
      }
    );

    if (response.data.success) {
      dispatch(getProcessedList());

      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getShippedShipmentList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONSTS.ShippedShipmentList}`);
      if (response.data.data !== null) {
        await dispatch(
          slice.actions.ShippedShipmentListSuccess(response.data.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWayOrderRequest() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONSTS.getWayOrderRequest}`);

      if (response.data.data !== null) {
        await dispatch(
          slice.actions.getWayOrderRequestSuccess(response.data.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function GetFinalApproval(vehicleId: number) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(
      `${CONSTS.finalApproval}?vehicleId=${vehicleId}`
    );
    if (response.data.success) {
      dispatch(slice.actions.GetFinalApprovalSuccess(response.data.data));
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function GetFinalApprovalReturnData(vehicleId: number) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(
      `${CONSTS.finalApproval}?vehicleId=${vehicleId}`
    );
    if (response.data.success) {
      dispatch(slice.actions.GetFinalApprovalSuccess(response.data.data));
      return response.data.data;
    }
    return null;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return null;
  }
}

export async function UpdatePriceShipment(
  id: any,
  joinRate: any,
  vehicleIdShipment: any,
  isJoinRate: any
) {
  dispatch(slice.actions.startLoading());
  try {
    // burada girilen parametre aslında shipmentıd

    const response = await axios.post(
      `${CONSTS.updatePriceShipment}?processOrderRequestId=${Number(
        id
      )}&joinRate=${Number(joinRate)}&isJoinRate=${isJoinRate}`
    );
    if (response.data.success) {
      getwaitingShipmentList();
      GetFinalApproval(vehicleIdShipment);

      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function UpdatePriceShipmentInCompleteList(
  id: any,
  joinRate: any
) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.updatePriceShipment}?processOrderRequestId=${Number(
        id
      )}&joinRate=${Number(joinRate)}`
    );
    if (response.data.success) {
      dispatch(getCompleteOrder());

      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function UpdateCargoAmountInCompleteList(
  processOrderRequestId: any,
  cargoAmount: any
) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(
      `${CONSTS.UpdateCargoAmountInCompleteList}?processOrderRequestId=${Number(
        processOrderRequestId
      )}&cargoAmount=${Number(cargoAmount)}`
    );
    if (response.data.success) {
      dispatch(getCompleteOrder());

      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function UpdateCustomerInCompleteList(
  processOrderRequestId: any,
  customerId: any
) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.UpdateCustomerInCompleteList}?customerId=${customerId}&processOrderId=${processOrderRequestId}`
    );
    if (response.data.success) {
      dispatch(getCompleteOrder());

      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function finalConfirmOrder(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${CONSTS.finalConfirmOrder}`, {
      driverNote: model.driverNote,
      isForeign: model.isForeign,
      transportPrice: model.transportPrice,
      commissionAmount: Number(model.commissionAmount),
      shipmentsId: model.shipmentsId,
      cashiersId: model.cashiersId,
      vehicleId: model.vehicleId,
      paymentMethodId: model.paymentMethodId,
      totalJoinRate: model.totalJoinRate,
      // PlannedPaymentDate: model.PlannedPaymentDate = ''
      //   ? null
      //   : model.PlannedPaymentDate,
      PlannedPaymentDate: model.PlannedPaymentDate,
      PayAsDieselChip: model.PayAsDieselChip,
      UnitPrice: Number(model.UnitPrice),
      UnitCommission: Number(model.UnitCommission),

      payDieselChipNo: model.payDieselChipNo,
      payDieselPrice: model.payDieselPrice,
      payAsCashChip: model.payAsCashChip,
      payCashPrice: model.payCashPrice,
      payCashChipNo: model.payCashChipNo,
      stationId: model.stationId
    });
    if (response && response.data.success) {
      getwaitingShipmentList();

      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getLoadingTypeSelectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONSTS.getLoadingTypeSelectList}`);

      dispatch(
        slice.actions.getLoadingTypeSelectListSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function AllDocumentForCompletedOrderListt(
  finalConfirmationOrderRequestId: string
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.AllDocumentForCompletedOrder}?finalConfirmOrderRequestId=${finalConfirmationOrderRequestId}`
      );

      if (response.data.data !== null) {
        await dispatch(
          slice.actions.AllDocumentForCompletedOrderSuccess(response.data.data)
        );

        GetWaybillNoOrDeliveryDocumentNoo(
          Number(finalConfirmationOrderRequestId)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export async function GetWaybillNoOrDeliveryDocumentNoo(id: number) {
  dispatch(slice.actions.startLoading());

  try {
    const response = await axios.get(
      `${CONSTS.getWaybillNoOrDeliveryDocumentNo}?finalConfirmationOrderRequest=${id}`
    );
    if (response.data.success) {
      dispatch(
        slice.actions.GetWaybillNoOrDeliveryDocumentNooSuccess(
          response.data.data
        )
      );

      return response.data.data;
    }
    return null;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return null;
  }
}

export function getOrderDocumentByIdBase64(id: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.orderDocumentIdByBase64}?documentId=${id}`
      );
      dispatch(
        slice.actions.getOrderDocumentByIdBase64Success(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function addWaybillNoOrDeliveryDocumentNoo(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.addWaybillNoOrDeliveryDocumentNo}`,
      {
        deliveryDocumentNo: Number(model.deliveryDocumentNo),
        finalConfirmationOrderRequestId: Number(
          model.finalConfirmationOrderRequestId
        ),
        wayBillNo: Number(model.wayBillNo)
      }
    );

    if (response.data.success) {
      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function deleteOrderDocument(id: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.deleteOrderdocument}?OrderDocumentId=${id}`
    );

    if (response.data.success) {
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function UETDSNotification(id: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${CONSTS.UETDSNotification}`, id);
    if (response) {
      dispatch(getCompleteOrder());
      dispatch(slice.actions.successUETDSResponse(response.data));
      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function AccountingTransfer(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.AccountingTransferReady}`,
      model
    );
    if (response) {
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function UETDSNotificationUpdate(id: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${CONSTS.UETDSNotificationUpdate}`, id);
    if (response.data.success) {
      dispatch(getCompleteOrder());
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getCompletedOrderDetail(id: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.getCompletedOrderDetail}?finalConfirmOrderRequesId=${id}`
      );
      dispatch(
        slice.actions.getCompletedOrderDetailSuccess(response.data.data)
      );

      dispatch(
        SetCompletedOrderDetailUpdateisForeign(response.data.data.isForeign)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function CompletedOrderDetailUpdate(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.CompletedOrderDetailUpdate}`,
      model
    );
    if (response.data.success) {
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit'; // redux bu
import { dispatch } from '../../store';
// utils
import axios from '../../../utils/axios';
import {
  AddCustomerContractAddContractModel,
  AddCustomerModel,
  CustomerAllModel,
  editCustomerInfoModel,
  LoginCustomerModel
} from '../../../@types/CustomerAll';
import { CONSTS } from '../const';
import { MapDeckglOverlay } from '../../../components/map';
import { saveCustomerContract } from '../Staff/CustomerContract';
// ----------------------------------------------------------------------
type GetCustomerAllState = {
  isLoading: boolean;
  error: boolean;
  detailModalIsOpen: boolean;
  customerContractAddisOpen: boolean;
  GetCustomerList: CustomerAllModel[];
  GetInContractCustomerList: CustomerAllModel[];
  GetNotContractCustomerList: CustomerAllModel[];
  GetInContractCustomer: CustomerAllModel;
  getCustomer: CustomerAllModel;
  customerIdForStaff: string;
  customerTabIndex: number;
  AddCustomer: AddCustomerModel;
  AddCustomerContractForCustomer: AddCustomerContractAddContractModel;
  base64FileConverterLogo: string | null;
  getEditCustomerInfo: editCustomerInfoModel;
  EditCustomerModal: boolean;
  avatarCustomerLogoBase64: string;
  LoginCustomer: LoginCustomerModel;
  CustomerCompanyName: string;
};
const getCustomerInit = {
  // gelen modelin propertylerin tipleri
  id: 0,
  currentCode: 0,
  customerCompanyName: '',
  address: '',
  phone: '',
  hasContract: true,
  dieselOilCreaseRate: 0
};

const AddCustomerInıt = {
  currentCode: 0,
  customerCompanyName: '',
  address: '',
  phone: '',
  hasContract: false,
  beneficiariesName: '',
  beneficiariesSurname: '',
  beneficiariesEmail: '',
  beneficiariesPhoneNumber: '',
  dieselOilncreaseRate: 0,
  beginDate: new Date(),
  endDate: new Date(),
  lastNotificationDate: new Date(),
  raiseRateToBeApplied: 0,
  raiseApplyDate: new Date(),
  logoBase64: '',
  logoImageName: '',
  contract64: '',
  contractImageName: '',
  taxNumber: '',
  cityId: 0,
  districtId: 0
};

const AddCustomerContractForCustomerInıt = {
  id: 0,
  customerId: 0,
  beginDate: new Date(),
  endDate: new Date(),
  lastNotificationDate: new Date(),
  raiseRateToBeApplied: 0,
  raiseApplyDate: new Date(),
  contractBase64String: '',
  contractImageFileType: '',
  contractImageName: '',
  contractImageParth: '',
  customerCompanyName: '',
  mimeType: '',
  cityId: 0,
  districtId: 0,
  code: ''
};

const getEditCustomerInfoInıt = {
  address: '',
  customerCompanyName: '',
  phone: '',
  hasContract: false,
  dieselOilncreaseRate: 0,
  logoBase64: '',
  customerId: 0,
  currentCode: 0,
  logoImageName: '',

  beneficiariesNameSurname: '',
  //  beneficiariesSurname: '',
  beneficiariesEmail: '',
  beneficiariesPhoneNumber: ''
};

const LoginCustomerInıt = {
  email: '',
  password: '',
  customerId: 0,
  cityId: 0,
  districtId: 0,
  phoneNumber: '',
  photoBase64: '',
  photoImageName: ''
};

const initialState: GetCustomerAllState = {
  // redux a bağlanıyor böyle
  isLoading: false,
  error: false,
  customerContractAddisOpen: false,
  detailModalIsOpen: false,
  GetCustomerList: [], // diğerleri fix
  getCustomer: getCustomerInit, // buda öyle announcementInit yukarıda
  customerIdForStaff: '',
  GetInContractCustomerList: [],
  GetNotContractCustomerList: [],
  AddCustomerContractForCustomer: AddCustomerContractForCustomerInıt,

  GetInContractCustomer: getCustomerInit,
  customerTabIndex: 0,
  AddCustomer: AddCustomerInıt,
  base64FileConverterLogo: '',
  getEditCustomerInfo: getEditCustomerInfoInıt,
  EditCustomerModal: false,
  avatarCustomerLogoBase64: '',
  LoginCustomer: LoginCustomerInıt,
  CustomerCompanyName: ''
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getCustomerAllSuccess(state, action) {
      state.isLoading = false;
      state.GetCustomerList = action.payload;
    },
    filterCustomerAllSuccess(state, action) {
      state.isLoading = false;
      state.GetCustomerList = action.payload;
    },
    saveCustomerAllSuccess(state, action) {
      state.isLoading = false;
      state.GetCustomerList = action.payload;
    },
    getByIdSuccess(state, action) {
      state.isLoading = false;
      state.GetCustomerList = action.payload;
    },

    setCustomerIdForStaff(state, action) {
      state.customerIdForStaff = action.payload;
    },

    setCustomerTabIndex(state, action) {
      state.customerTabIndex = action.payload;
    },

    getCustomerNotContractListSuccess(state, action) {
      state.isLoading = false;
      state.GetNotContractCustomerList = action.payload;
    },

    getCustomerInContractListSuccess(state, action) {
      state.isLoading = false;
      state.GetInContractCustomerList = action.payload;
    },

    onToggleDetailModal(state, action) {
      state.detailModalIsOpen = action.payload;
    },
    onToogleCustomerContract(state, action) {
      state.customerContractAddisOpen = action.payload;
    },
    setAddCustomerContractForCustomer(state, action) {
      state.AddCustomerContractForCustomer = action.payload;
    },
    setCustomerProfilBase64(state, action) {
      state.base64FileConverterLogo = action.payload;
    },
    getgetEditCustomerInfoSuccess(state, action) {
      state.getEditCustomerInfo = action.payload;
    },
    setEditCustomerModal(state, action) {
      state.EditCustomerModal = action.payload;
    },

    setAvatarCustomerLogoBase64(state, action) {
      state.avatarCustomerLogoBase64 = action.payload;
    },
    setCustomerCompanyName(state, action) {
      state.CustomerCompanyName = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setCustomerIdForStaff,
  onToggleDetailModal,
  onToogleCustomerContract,
  setCustomerTabIndex,
  setAddCustomerContractForCustomer,
  setCustomerProfilBase64,
  setEditCustomerModal,
  setAvatarCustomerLogoBase64,
  setCustomerCompanyName
} = slice.actions;

// ----------------------------------------------------------------------

export function addPriceDefination(model: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${CONSTS.AnnouncementAddAnnouncement}`,
        {
          title: model.title,
          description: model.description,
          publicationDate: model.publicationDate,
          announcementTypeId: Number(model.announcementTypeId),
          takedownDate: model.takedownDate
        }
      );
      localStorage.setItem('id', response.data.data.id);

      dispatch(getCustomerNotContractList());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomerAll() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.GetCustomerAll, {});
      dispatch(slice.actions.getCustomerAllSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomerNotContractList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.getCustomerNotContractList, {});
      if (response.data.data !== null) {
        dispatch(
          await slice.actions.getCustomerNotContractListSuccess(
            response.data.data
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomerInContractList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.getCustomerInContractList, {});
      if (response.data.data !== null) {
        dispatch(
          slice.actions.getCustomerInContractListSuccess(response.data.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function addCustomer(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    // const response = { data: { success: true } };

    const response = await axios.post(CONSTS.AddCustomer, {
      currentCode: Number(model.currentCode),
      customerCompanyName: model.customerCompanyName,
      address: model.address,
      phone: model.phone,
      hasContract: Boolean(model.hasContract),
      beneficiariesName: model.beneficiariesName,
      beneficiariesSurname: model.beneficiariesSurname,
      beneficiariesEmail: model.beneficiariesEmail,
      dieselOilncreaseRate: Number(model.dieselOilncreaseRate),
      beneficiariesPhoneNumber: model.beneficiariesPhoneNumber,
      logoBase64: model.logoBase64,
      logoImageName: model.logoImageName,
      // buradan sonrası sözleşme bilgileri
      beginDate: model.beginDate,
      endDate: model.endDate,
      lastNotificationDate: model.lastNotificationDate,
      raiseApplyDate: model.raiseApplyDate,
      raiseRateToBeApplied: Number(model.raiseRateToBeApplied),
      contract64: model.contract64,
      contractImageName: model.contractImageName,
      taxNumber: model.taxNumber,
      cityId: model.cityId,
      districtId: model.districtId,
      code: model.code
    });

    if (response.data.success) {
      dispatch(getCustomerInContractList());
    }
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return null;
  }
}

export function getgetEditCustomerInfo(customerId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.getEditCustomerInfo}?customerId=${customerId}`
      );
      dispatch(slice.actions.getgetEditCustomerInfoSuccess(response.data.data));
      dispatch(
        slice.actions.setAvatarCustomerLogoBase64(response.data.data.logoBase64)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateEditCustomerInfo(updatemodel: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      CONSTS.updateEditCustomerInfo,
      updatemodel
    );
    if (response.data.success) {
      dispatch(getCustomerInContractList());
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function addLoginCustomer(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.AddCustomerLogin, model);

    if (response.data.success) {
      // dispatch(getCustomerInContractList());
    }
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return null;
  }
}

export async function removeCustomer(id: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${CONSTS.deleteCustomer}`, id);

    if (response.data) {
      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

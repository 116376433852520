import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit'; // redux bu
import { dispatch } from '../../store';
// utils
import axios from '../../../utils/axios';
import {
  GetLocationById,
  GetLocationModel
} from '../../../@types/LocationModel';
import { CONSTS } from '../const';
import { MapDeckglOverlay } from '../../../components/map';
// ----------------------------------------------------------------------

type LocationsState = {
  isLoading: boolean;
  error: boolean;
  detailModalIsOpen: boolean;
  getLocationsbyId: GetLocationById[]; // farklı olan diğerleri fix
  locationsById: GetLocationById;
  getLocationList: GetLocationModel[];
  getLocation: GetLocationModel;
  definedLocationDetailIsOpen: boolean;
};
const locationbyIdInit = {
  // gelen modelin propertylerin tipleri
  customerId: 0,
  locationName: '',
  cityId: 0,
  districtId: 0,
  googleMapUrl: '',
  verbalAddress: '',
  createDate: new Date(),
  id: 0,
  latitude: '',
  longitude: ''
};

const locationsInit = {
  // gelen modelin propertylerin tipleri
  customerId: 0,
  locationName: '',
  cityId: 0,
  districtId: 0,
  googleMapUrl: '',
  verbalAddress: '',
  createDate: new Date(),
  id: 0,
  city: '',
  district: ''
};

const initialState: LocationsState = {
  // redux a bağlanıyor böyle
  isLoading: false,
  error: false,
  detailModalIsOpen: false,
  getLocationsbyId: [],
  locationsById: locationbyIdInit,
  definedLocationDetailIsOpen: false,
  getLocationList: [],
  getLocation: locationsInit
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getAllLocationsSuccess(state, action) {
      // her  metod için 2 tane error ve succes metodları yazdık
      state.isLoading = false;
      state.getLocationList = action.payload;
    },
    getLocationById(state, action) {
      state.isLoading = false;
      state.locationsById = action.payload;
    },
    saveLocationsSuccess(state, action) {
      state.isLoading = false;
      state.getLocationList = action.payload;
    },
    getLocationsByCustomerIdForStaffSuccess(state, action) {
      state.isLoading = false;
      state.getLocationList = action.payload;
    },

    onToggleDetailModal(state, action) {
      state.detailModalIsOpen = action.payload;
    },
    onToggleDefinedLocationDetailModal(state, action) {
      state.definedLocationDetailIsOpen = action.payload;
    }
    // GET PROFILE
    // getDocumentsByVehicleIdSuccess(state, action) {
    //   state.isLoading = false;
    //   state.vehicle = action.payload;
    // }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onToggleDetailModal,
  onToggleDefinedLocationDetailModal
} = slice.actions;

// ----------------------------------------------------------------------

export function addLocationsCustomerId(model: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${CONSTS.LocationAddLocationCustomerId}`,
        {
          id: model.id,
          customerId: model.customerId,
          locationName: model.locationName,
          cityId: model.cityId,
          citySelectList: [
            {
              disable: model.disable,
              group: null,
              selected: model.selected,
              text: model.text,
              value: model.value
            }
          ]
        }
      );
      localStorage.setItem('id', response.data.data.id);

      // dispatch(getAllLocations());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllLocations(customerId: any) {
  // const customerId = localStorage.getItem('Customer');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.GetCustomerLocations}?customerId=${Number(customerId)}`,
        {}
      );
      dispatch(slice.actions.getAllLocationsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function GetLocationsById(locationId: number) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(
      `${CONSTS.GetLocationById}?locationId=${locationId}`,
      {}
    );
    if (response) {
      dispatch(slice.actions.getLocationById(response.data.data));
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function removeDefinedLocation(locationIds: any, customerId: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.DeleteCustomerLocationList}`,
      locationIds
    );
    if (response.data.success) {
      dispatch(getAllLocations(customerId));
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getLocationsByCustomerIdForStaff(customerId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.GetCustomerLocations}?customerId=${customerId}`
      );
      dispatch(
        slice.actions.getLocationsByCustomerIdForStaffSuccess(
          response.data.data
        )
      );
      dispatch(slice.actions.getLocationById(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit'; // redux bu
// import axios from 'axios';
import axios from '../../../utils/axios';
import { dispatch } from '../../store';
import { CONSTS } from '../const';
import {
  CustomerContractAddModel,
  CustomerContractListModel,
  editCustomerConractModel,
  EscalationListModel
} from '../../../@types/CustomerContractModel';
import { getCustomerInContractList } from '../ManagementPanel/GetCustomerAll';
// utils

// ----------------------------------------------------------------------

type CustomerContractModelState = {
  isLoading: boolean;
  error: boolean;
  detailModalIsOpen: boolean;
  ContractList: CustomerContractListModel[]; // farklı olan diğerleri fix
  CustomerContract: CustomerContractAddModel;
  CustomerContractByCustomerId: editCustomerConractModel;
  customerContractEditModal: false;
  AddCustomerContractModal: false;
  escalationList: EscalationListModel[];
  customerCompanyNameForContractEdit: string;
  customerContractListModal: boolean;
};
const CustomerContractInit = {
  id: 0,
  code: '',
  customerId: 0,
  beginDate: '',
  endDate: '',
  lastNotificationDate: '',
  raiseRateToBeApplied: 0,
  raiseApplyDate: '',
  customerCompanyName: '',
  contract64: '',
  contractImageName: '',
  dieselOilncreaseRate: 0,
  cityId: 0,
  districtId: 0
};

const editCustomerContractInıt = {
  id: 0,
  contractCreateDate: new Date(),
  customerId: 0,
  beginDate: new Date(),
  endDate: new Date(),
  lastNotificationDate: new Date(),
  raiseRateToBeApplied: 0,
  raiseApplyDate: new Date(),
  contractBase64String: '',
  contractImageFileType: '',
  contractImageName: '',
  contractImageParth: '',
  customerCompanyName: '',
  mimeType: '',
  imageName: '',
  dieselOilncreaseRate: 0,
  cityId: 0,
  districtId: 0,
  code: '',
  cityName: '',
  districtName: ''
};

const initialState: CustomerContractModelState = {
  // redux a bağlanıyor böyle
  isLoading: false,
  error: false,
  detailModalIsOpen: false,
  ContractList: [], // diğerleri fix
  CustomerContract: CustomerContractInit,
  CustomerContractByCustomerId: editCustomerContractInıt,
  customerContractEditModal: false,
  AddCustomerContractModal: false,
  escalationList: [],
  customerCompanyNameForContractEdit: '',
  customerContractListModal: false
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    onToggleDetailModal(state, action) {
      state.detailModalIsOpen = action.payload;
    },
    getContractListSuccess(state, action) {
      state.ContractList = action.payload;
    },
    getCustomerContractByCustomerIdSuccess(state, action) {
      state.CustomerContractByCustomerId = action.payload;
    },
    setcustomerContractEditModal(state, action) {
      state.customerContractEditModal = action.payload;
    },
    setAddCustomerContractModal(state, action) {
      state.AddCustomerContractModal = action.payload;
    },
    getEscalationListSuccess(state, action) {
      state.escalationList = action.payload;
    },
    setcustomerCompanyNameForContractEdit(state, action) {
      state.customerCompanyNameForContractEdit = action.payload;
    },
    setcustomerContractListModal(state, action) {
      state.customerContractListModal = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onToggleDetailModal,
  setcustomerContractEditModal,
  setAddCustomerContractModal,
  setcustomerCompanyNameForContractEdit,
  setcustomerContractListModal
} = slice.actions;

// ----------------------------------------------------------------------

// export async function saveCustomerContract(CustomerContract: any) {
//   return  () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(
//         `${CONSTS.addCustomerContract}`,
//         CustomerContract
//       );
//       if (response.data.success) {
//         dispatch(getContractList());
//         return true;
//       }
//       return false;
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export async function saveCustomerContract(CustomerContract: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.addCustomerContract, {
      id: CustomerContract.id,
      customerId: Number(CustomerContract.customerId),
      beginDate: CustomerContract.beginDate,
      endDate: CustomerContract.endDate,
      lastNotificationDate: CustomerContract.lastNotificationDate,
      raiseRateToBeApplied: CustomerContract.raiseRateToBeApplied,
      raiseApplyDate: CustomerContract.raiseApplyDate,
      contract64: CustomerContract.contract64,
      contractImageName: CustomerContract.contractImageName,
      customerCompanyName: CustomerContract.CustomerCompanyName,
      dieselOilncreaseRate: CustomerContract.dieselOilncreaseRate,
      cityId: CustomerContract.cityId,
      districtId: CustomerContract.districtId,
      code: CustomerContract.code
    });
    if (response.data.success) {
      dispatch(getContractList());
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getContractList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.contractList, {});
      dispatch(slice.actions.getContractListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getCustomerContractByCustomerId(
  customerId: string,
  contractId: string
) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(
      `${CONSTS.getCustomerContractByCustomerId}?customerId=${customerId}&contractId=${contractId}`
    );
    if (response.data.data !== null) {
      dispatch(
        slice.actions.getCustomerContractByCustomerIdSuccess(response.data.data)
      );
      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}
export async function updateCustomerContract(updatemodel: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      CONSTS.updateCustomerContract,
      updatemodel
    );
    if (response.data.success) {
      dispatch(getContractList());
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function removeCustomerContract(id: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${CONSTS.deleteCustomerContract}`, id);

    if (response.data.success) {
      dispatch(getCustomerInContractList());

      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getEscalationList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.getEscalationList, {});
      dispatch(slice.actions.getEscalationListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function UpdatePriceDefination(customerId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${CONSTS.updatePriceDefination}?customerId=${customerId}`
      );
      if (response.data.success) {
        dispatch(getEscalationList());
        return true;
      }
      return false;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

import { createSlice } from '@reduxjs/toolkit'; // redux bu
import { dispatch } from '../../store';
// utils
import axios from '../../../utils/axios';
import { CONSTS } from '../const';
import {
  ForeignVehicleStateModel,
  VehicleStateModel,
  VehicleStateSelectList
} from '../../../@types/VehicleStateModel';
import { getAllSelfOwnedVehicle } from '../SelfOwnedVehicle';
import { getDistrictForCitySelectList } from '../customer/CitySelectListGet';
// ----------------------------------------------------------------------

type VehicleStateState = {
  isLoading: boolean;
  error: boolean;
  detailModalIsOpen: boolean;
  VehicleStateList: VehicleStateModel[]; // farklı olan diğerleri fix
  VehicleState: VehicleStateModel;
  foreignVehicleState: ForeignVehicleStateModel;
  VehicleStateSelectList: VehicleStateSelectList[];
};
const VehicleStateInıt = {
  vehicleId: 0,
  vehicleStateId: 0,
  vehicleStateName: '',
  currentLocationCityId: 0,
  currentLocationCityName: '',
  currentLocationDistrictId: 0,
  currentLocationDistrictName: '',
  currentCargoAmount: 0,
  isCurrent: true,
  isReady: true,
  isFull: false
};

const ForeignVehicleStateInıt = {
  vehicleId: 0,
  vehicleStateId: 0,
  vehicleStateName: '',
  currentLocationCityId: 0,
  currentLocationCityName: '',
  currentLocationDistrictId: 0,
  currentLocationDistrictName: '',
  currentCargoAmount: 0,
  isCurrent: true,
  isReady: true,
  isFull: false
};

const initialState: VehicleStateState = {
  // redux a bağlanıyor böyle
  isLoading: false,
  error: false,
  detailModalIsOpen: false,
  VehicleStateList: [],
  VehicleState: VehicleStateInıt,
  foreignVehicleState: ForeignVehicleStateInıt,
  VehicleStateSelectList: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getAllVehicleStateSuccess(state, action) {
      // her  metod için 2 tane error ve succes metodları yazdık
      state.isLoading = false;
      state.VehicleStateList = action.payload;
    },

    // GET PROFILE
    ChangeVehicleStateByVehicleIdSuccess(state, action) {
      state.isLoading = false;
      state.VehicleState = action.payload;
    },

    // GET PROFILE
    getVehicleStateSelectListSuccess(state, action) {
      state.isLoading = false;
      state.VehicleStateSelectList = action.payload;
    },

    // GET PROFILE
    getVehicleStateByVehicleIdSuccess(state, action) {
      state.isLoading = false;
      state.VehicleState = action.payload;
    },

    // GET PROFILE
    getForeignVehicleStateByVehicleIdSuccess(state, action) {
      state.isLoading = false;
      state.foreignVehicleState = action.payload;
    },
    onToggleDetailModal(state, action) {
      state.detailModalIsOpen = action.payload;
    }
    // GET PROFILE
    // getDocumentsByVehicleIdSuccess(state, action) {
    //   state.isLoading = false;
    //   state.vehicle = action.payload;
    // }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleDetailModal } = slice.actions;

// ----------------------------------------------------------------------

export function getVehicleStateSelectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.GetVehicleStateSelectLİst, {});
      dispatch(
        slice.actions.getVehicleStateSelectListSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVehicleStateBySelfVehicleId(vehicleId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.SelfOwnedVehicleState}?vehicleId=${vehicleId}`
      );
      if (response.data.data !== null) {
        await dispatch(
          slice.actions.getVehicleStateByVehicleIdSuccess(response.data.data)
        );

        // await dispatch(
        //   getDistrictForCitySelectList(
        //     response.data.data.currentLocationCityId.toString()
        //   )
        // );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function ChangeVehicleStateByVehicleId(updatemodel: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      CONSTS.ChangeSelfOwnedVehicleState,
      updatemodel
    );
    if (response.data.success) {
      dispatch(getVehicleStateBySelfVehicleId(updatemodel.vehicleId));
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getForeignVehicleStateByVehicleId(vehicleId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    //  dispatch(slice.actions.startDataLoading());

    try {
      const response = await axios.get(
        `${CONSTS.GetForeignVehicleState}?vehicleId=${vehicleId}`
      );
      if (response.data.data !== null) {
        dispatch(
          slice.actions.getForeignVehicleStateByVehicleIdSuccess(
            response.data.data
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function ChangeForeignVehicleStateByVehicleId(updatemodel: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      CONSTS.ChangeForeignVehicleState,
      updatemodel
    );
    if (response.data.success) {
      dispatch(getForeignVehicleStateByVehicleId(updatemodel.vehicleId));
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

// export function addAnnouncement(model: any) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(
//         `${CONSTS.AnnouncementAddAnnouncement}`,
//         {
//           title: model.title,
//           description: model.description,
//           publicationDate: model.publicationDate,
//           announcementTypeId: Number(model.announcementTypeId),
//           takedownDate: model.takedownDate
//         }
//       );
//       localStorage.setItem('id', response.data.data.id);

//       dispatch(getAllAnnouncement());
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function getAllAnnouncement() {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(
//         CONSTS.AnnouncementGetAnnouncementList,
//         {}
//       );
//       dispatch(slice.actions.getAllAnnouncementSuccess(response.data.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function deleteAnnouncement(model: any) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(
//         `${CONSTS.AnnouncementDeleteAnnouncement}`,
//         model
//       );
//       dispatch(getAllAnnouncement());
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function filterAnnouncement(_query: string, _registered: boolean) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(
//         CONSTS.AnnouncementGetAnnouncementList,
//         {
//           query: _query,
//           registered: _registered
//         }
//       );
//       dispatch(slice.actions.filterAnnouncementSuccess(response.data.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function getAllVehicle() {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(
//         CONSTS.ASSET_MANAGEMENT_VEHICLE_GET_ALL,
//         {}
//       );
//       dispatch(slice.actions.getAllVehicleSuccess(response.data.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function getVehicleById(vehicleId: string) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(
//         `${CONSTS.ASSET_MANAGEMENT_VEHICLE_GET}?vehicleId=${vehicleId}`
//       );
//       dispatch(slice.actions.getVehicleByIdSuccess(response.data.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function filterAnnouncement(_query: string, _registered: boolean) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(
//         CONSTS.ASSET_MANAGEMENT_VEHICLE_FILTER,
//         {
//           query: _query,
//           registered: _registered
//         }
//       );
//       dispatch(slice.actions.filterVehicleSuccess(response.data.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function saveAnnouncement(model: any) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(CONSTS.AnnouncementEditAnnounement, {
//         ...model
//       });
//       localStorage.setItem('vehicleId', response.data.data.id);
//       dispatch(getAllAnnouncement());
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// export function removeAnnouncement(model: any) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(
//         `${CONSTS.AnnouncementDeleteAnnouncement}`,
//         model
//       );
//       dispatch(getAllVehicle());
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

/*
export function getDocumentsByVehicleId(idForVehicle: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.ASSET_MANAGEMENT_VEHICLE_GET_DOCUMENS}?vehicleId=${idForVehicle}`
      );
      dispatch(
        slice.actions.getDocumentsByVehicleIdSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
*/

/* 
export function saveVehicleDocument(idForVehicle: string, model: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${CONSTS.ASSET_MANAGEMENT_VEHICLE_ADD_DOCUMENT}`,
        {
          vehicleId: idForVehicle,
          documentDetails: model
        }
      );
      localStorage.removeItem('vehicleId');
      localStorage.removeItem('documentPath');
      dispatch(getAllVehicle());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
*/

/* 
export function saveDocumentManagement(fileName: string, data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${CONSTS.MANAGEMENT_UPLOAD_FILE}`, {
        FileName: fileName,
        FormFile: data
      });
      localStorage.setItem('documentPath', response.data);
      // dispatch(getAllVehicle());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
*/

// export function getAllModels() {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(
//         CONSTS.,
//         {}
//       );
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

/*
export function getDocumentsByVehicleId(idForVehicle: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.ASSET_MANAGEMENT_VEHICLE_GET_DOCUMENS}?vehicleId=${idForVehicle}`
      );
      dispatch(
        slice.actions.getDocumentsByVehicleIdSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
*/

/* 
export function saveVehicleDocument(idForVehicle: string, model: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${CONSTS.ASSET_MANAGEMENT_VEHICLE_ADD_DOCUMENT}`,
        {
          vehicleId: idForVehicle,
          documentDetails: model
        }
      );
      localStorage.removeItem('vehicleId');
      localStorage.removeItem('documentPath');
      dispatch(getAllVehicle());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
*/

/* 
export function saveDocumentManagement(fileName: string, data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${CONSTS.MANAGEMENT_UPLOAD_FILE}`, {
        FileName: fileName,
        FormFile: data
      });
      localStorage.setItem('documentPath', response.data);
      // dispatch(getAllVehicle());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
*/

// ----------------------------------------------------------------------

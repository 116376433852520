import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';

import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { alpha } from '@material-ui/core/styles';
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  DialogActions,
  Tooltip,
  IconButton,
  styled,
  Popover
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PDFViewer } from '@react-pdf/renderer';
import { Invoice } from '../../@types/products';

// material

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { MIconButton } from '../../components/@material-extend';
import Avatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import Customer from '../../redux/slices/customer/Customer';
import { DialogAnimate } from '../../components/animate';
import { InvoicePDF } from '../../components/e-commerce/invoice';
import { dispatch, RootState } from '../../redux/store';
import { getCustomerContractByCustomerId } from '../../redux/slices/Staff/CustomerContract';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Anasayfa',
    icon: homeFill,
    linkTo: '/'
  },
  // {
  //   label: 'Ayarlar',
  //   icon: settings2Fill,
  //   linkTo: PATH_DASHBOARD.user.profile
  // },
  {
    label: 'Profil',
    icon: personFill,
    linkTo: PATH_DASHBOARD.transportation.CustomerAccount
  }
];

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginBottom: theme.spacing(5)
}));

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const history = useHistory();
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const customerId = JSON.parse(localStorage.getItem('Customer') || '{}');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [
    anchorElPopover,
    setAnchorElPopover
  ] = useState<HTMLButtonElement | null>(null);
  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElPopover(event.currentTarget);
    // dispatch(getCustomerContractByCustomerId(customerId));
  };

  const { CustomerContractByCustomerId } = useSelector(
    (state: RootState) => state.CustomerContract
  );

  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };

  const openPopover = Boolean(anchorElPopover);
  const idPopover = openPopover ? 'simple-popover' : undefined;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      if (isMountedRef.current) {
        history.push('/');
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  const userr = user;
  const [openPDF, setOpenPDF] = useState(false);

  const handleOpenPreview = () => {
    setOpenPDF(true);
  };

  const handleClosePreview = () => {
    setOpenPDF(false);
  };

  const AddCustomerPush = () => {
    history.push(PATH_DASHBOARD.transportation.AddCustomerInfo);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}
        <Divider sx={{ my: 1 }} />
        {/* {userr.role === 'Staff' && (
          <Box sx={{ p: 2, pt: 1.5 }}>
            <Button
              style={{
                display: userr.role != 'Staff' ? 'none' : ''
              }}
              fullWidth
              color="info"
              size="small"
              variant="contained"
              onClick={AddCustomerPush}
            >
              Müşteri Ekle
            </Button>
          </Box>
        )} */}

        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            Çıkış Yap
          </Button>
        </Box>
        {/* <Divider sx={{ my: 1 }} /> */}
        {userr.role === 'Customer' && userr.hasContract === true && (
          <Box sx={{ p: 2, pt: 1.5 }}>
            {/* <Button
              fullWidth
              color="info"
              size="small"
              variant="contained"
              aria-describedby={idPopover}
              onClick={handleClickPopover}
              endIcon={<Icon icon={eyeFill} />}
            >
              Sözleşmeyi Görüntüle
            </Button> */}
            <div>
              <Popover
                id={idPopover}
                open={openPopover}
                anchorEl={anchorElPopover}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
              >
                <div
                  style={{
                    display: 'block',
                    width: 850,
                    height: 1000
                  }}
                >
                  <img
                    src={`data:image/*;base64,${CustomerContractByCustomerId.contractBase64String}`}
                    width="850"
                    height="1000"
                  />
                </div>
              </Popover>
            </div>
            <DialogAnimate fullScreen open={openPDF}>
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <DialogActions
                  sx={{
                    zIndex: 9,
                    padding: '12px !important',
                    boxShadow: (theme) => theme.customShadows.z8
                  }}
                >
                  <Tooltip title="Close">
                    <IconButton color="inherit" onClick={handleClosePreview}>
                      <Icon icon={closeFill} />
                    </IconButton>
                  </Tooltip>
                </DialogActions>
                <Box sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
                  {/* <PDFViewer
                  width="100%"
                  height="100%"
                  style={{ border: 'none' }}
                >
                  <InvoicePDF invoice={invoice} />
                </PDFViewer> */}
                </Box>
              </Box>
            </DialogAnimate>
          </Box>
        )}
      </MenuPopover>
    </>
  );
}

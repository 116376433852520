import { createSlice } from '@reduxjs/toolkit'; // redux bu
import { dispatch } from '../../store';
// utils
import axios from '../../../utils/axios';
import { AddStaffModel } from '../../../@types/allStaffModel';
import { CONSTS } from '../const';
import { getGetSelfOwnedDriverList } from '../ManagementPanel/GetStaffList';
// ------------------------------------------------------------------------------------------------
type AddStaffState = {
  isLoading: boolean;
  error: boolean;
  addStaffList: AddStaffModel[];
  addStaff: AddStaffModel;
};
// ----------------------------------------------------------------

const addStaffInit = {
  currentCode: 0,
  tckNo: '',
  name: '',
  surname: '',
  titleId: 0,
  cityId: 0,
  districtId: 0,
  email: '',
  phone: '',
  isForeign: false,
  isActive: true,
  transitCompanyId: 0,
  staffStatusId: 0
};

const initialState: AddStaffState = {
  isLoading: false,
  error: false,
  addStaffList: [],
  addStaff: addStaffInit
};
// --------------------------------------------------------------------

const slice = createSlice({
  name: 'addStaff',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

// ----------------------------------------------------------------------------

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------------

export async function addStaffFunction(addStaff: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.addStaff, {
      currentCode: Number(addStaff.currentCode),
      tckNo: addStaff.tckNo,
      name: addStaff.name,
      surname: addStaff.surname,
      titleId: addStaff.titleId,
      cityId: addStaff.cityId,
      districtId: addStaff.districtId,
      email: addStaff.email,
      phone: addStaff.phone,
      isForeign: addStaff.isForeign,
      transitCompanyId: addStaff.transitCompanyId,
      staffStatusId: addStaff.staffStatusId,
      isActive: addStaff.isActive
    });

    if (response.data.success) {
      dispatch(getGetSelfOwnedDriverList());
    }
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return null;
  }
}

import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit'; // redux bu
import axios from '../../utils/axios';
import { CONSTS } from './const';
import { dispatch, RootState } from '../store';
import { MapDeckglOverlay } from '../../components/map';
import {
  SelfOwnedVehicleDocumentModel,
  SelfdocumentTypesSelectListModel,
  selfOwnedVehicleDocumentModel,
  selfOwnedVehicleInformationModel,
  SelfOwnedVehicleModel,
  getSelfOwnedVehicleDocumentModel,
  CarBrandSelectLİstModel,
  ChiferDriverSelectListModel,
  ChiferDrivergetModel,
  CarModelSelectListModel,
  TrailerListModel,
  TrailerDetailModel,
  CreateSelfOwnedVehicleModel,
  getTrailerDocumentTypeSelectListModel,
  AddTrailerModel,
  TrailerDocumentListModel,
  GetVehicleListWithCityModel,
  DocumentByIdBase64Model,
  trailerDocumentTypeModel,
  getGetDangerousMaterialsTransportTypeSelectListModel,
  getUnNumberSelectListModel,
  getSelfOwnedCompaniesSelectListModel,
  vehicleTypeModel
} from '../../@types/SelfOwnedVehicleModel';
import { getwaitingShipmentList } from './customer/orderRequest';
// ----------------------------------------------------------------------
type SelfOwnedVehicleState = {
  isLoading: boolean;
  isDataLoading: boolean;
  error: boolean;
  detailModalIsOpen: boolean;
  SelfOwnedVehicleList: SelfOwnedVehicleModel[]; // farklı olan diğerleri fix
  SelfOwnedVehicle: SelfOwnedVehicleModel;
  vechileDetailModalOpen: false;
  selfOwnedVehicleDocumentEditModalOpen: false;
  VehicleId: number;
  selfOwnedVehicleInformation: selfOwnedVehicleInformationModel;
  selfOwnedVehicleDocumentList: selfOwnedVehicleDocumentModel[];
  SelfdocumentTypesSelectList: SelfdocumentTypesSelectListModel[];
  SelfOwnedPlate: string;
  selfOwnedId: number;
  addselfOwnedVehicleDocument: SelfOwnedVehicleDocumentModel;
  getSelfOwnedVehicleDocumentByDocumentId: getSelfOwnedVehicleDocumentModel;
  documentId: number;
  CarBrandSelectLİst: CarBrandSelectLİstModel[];
  CarModelSelectLİst: CarModelSelectListModel[];
  ChiferDriverSelectList: ChiferDriverSelectListModel[];
  selfOwnedChiferDriverGet: ChiferDrivergetModel;
  carBrandId: string;
  TrailerEditModalOpen: false;
  TrailerList: TrailerListModel[];
  trailerDocumentType: trailerDocumentTypeModel[];
  trailerDetail: TrailerDetailModel;
  CreateSelfOwnedVehicle: CreateSelfOwnedVehicleModel;
  SelfOwnedCompaniesSelectList: getSelfOwnedCompaniesSelectListModel[];
  DocumentIdListForVehicle: string[];
  CreateSelfOwnedIsSuccess: boolean;
  TrailerDocumentTypeSelectList: getTrailerDocumentTypeSelectListModel[];
  DangerousMaterialsTransportTypeSelectList: getGetDangerousMaterialsTransportTypeSelectListModel[];
  UnNumberSelectList: getUnNumberSelectListModel[];
  addTrailerInfo: AddTrailerModel;
  trailerDocumentLİst: TrailerDocumentListModel[];
  VehicleListWithCity: GetVehicleListWithCityModel[];
  DocumentByIdBase64: DocumentByIdBase64Model;
  SelfOwnedCompaniesDetailModal: boolean;
  cityexport: string;
  getVehicleTypeList: vehicleTypeModel[];
};
const getSelfOwnedVehicleDocumentByDocumentIdInit = {
  documentTypeId: 0,
  startDate: '',
  endDate: '',
  documentImageFileBase64String: '',
  documentImageName: '',
  vehicleId: 0,
  documentExtension: '',
  documentTypeName: '',
  documnetStatüs: '',
  id: 0,
  mimeType: '',
  staffId: 0
};
const selfOwnedVehicleDocumentInit = {
  documentTypeId: 0,
  beginDate: new Date(),
  endDate: new Date(),
  documentImageFileBase64String: '',
  documentImageName: '',
  vehicleId: 0
};

const SelfOwnedVehicleInit = {
  vehicleId: 0,
  selfOwnedId: 0,
  licensePate: '',
  vehicleTypeName: '',
  cargoCapacity: 0,
  carmanName: '',
  carmanSurname: '',
  carmanPhoneNumber: '',
  carBrandName: '',
  carModel: '',
  isReady: true,
  isFull: false,
  isPastDocument: false,
  isDocument: '',
  carmanNameAndSurname: '',
  currentLocation: ''
};

const selfOwnedVehicleInformationInıt = {
  carBrandId: 0,
  carBrandName: '',
  carModelName: '',
  carModelId: 0,
  carModelYear: 0,
  carColor: '',
  cargoCapacity: 0,
  selfOwnedVehicleId: 0,
  carmanId: 0,
  selfOwnedCompaniesId: 0,
  vehicleTypeId: 0
};

const selfOwnedVehicleChiferDrivergetInıt = {
  text: '',
  value: ''
};

const TrailerDetailInıt = {
  carmanNameAndSurname: '',
  id: 0,
  isDocument: '',
  isFull: true,
  isPastDocument: true,
  isReady: true,
  trailerTypeId: 0,
  trailerTypeName: '',

  licensePlate: '',
  carmanId: 0,
  cargoCapacity: 0,
  selfOwnedCompaniesId: 0
};
const CreateSelfOwnedVehicleInıt = {
  licensePlate: '',
  vehicleTypeId: 0,
  chiefDriverId: 0,
  carModelYear: 0,
  carColor: '',
  carBrandId: 0,
  carModelId: 0,
  selfOwnedCompaniesId: 0
};

const addTrailerInıt = {
  licensePlate: '',
  trailerTypeId: 0,
  carmanId: 0,
  cargoCapacity: 0,
  trailerTypeName: '',
  carmanNameAndSurname: '',
  isReady: true,
  isFull: true,
  selfOwnedCompaniesId: 0
};

const DocumentByIdBase64Inıt = {
  id: 0,
  documentExtension: '',
  documentTypeId: 0,
  mimeType: '',
  documentTypeName: '',
  startDate: '',
  endDate: '',
  documentImageFileBase64String: '',
  documentImageName: '',
  documnetStatüs: '',
  vehicleId: 0,
  staffId: 0
};

const initialState: SelfOwnedVehicleState = {
  // redux a bağlanıyor böyle
  isLoading: false,
  error: false,
  detailModalIsOpen: false,
  SelfOwnedVehicleList: [], // diğerleri fix
  SelfOwnedVehicle: SelfOwnedVehicleInit,
  vechileDetailModalOpen: false,
  selfOwnedVehicleDocumentEditModalOpen: false,
  VehicleId: 0,
  selfOwnedVehicleInformation: selfOwnedVehicleInformationInıt,
  selfOwnedVehicleDocumentList: [],
  SelfOwnedPlate: '',
  selfOwnedId: 0,
  SelfdocumentTypesSelectList: [],
  addselfOwnedVehicleDocument: selfOwnedVehicleDocumentInit,
  getSelfOwnedVehicleDocumentByDocumentId: getSelfOwnedVehicleDocumentByDocumentIdInit,
  documentId: 0,
  CarBrandSelectLİst: [],
  CarModelSelectLİst: [],
  ChiferDriverSelectList: [],
  selfOwnedChiferDriverGet: selfOwnedVehicleChiferDrivergetInıt,
  carBrandId: '',
  TrailerEditModalOpen: false,
  TrailerList: [],
  trailerDetail: TrailerDetailInıt,
  CreateSelfOwnedVehicle: CreateSelfOwnedVehicleInıt,
  DocumentIdListForVehicle: [],
  CreateSelfOwnedIsSuccess: false,
  TrailerDocumentTypeSelectList: [],
  addTrailerInfo: addTrailerInıt,
  isDataLoading: false,
  trailerDocumentLİst: [],
  VehicleListWithCity: [],
  DocumentByIdBase64: DocumentByIdBase64Inıt,
  trailerDocumentType: [],
  DangerousMaterialsTransportTypeSelectList: [],
  UnNumberSelectList: [],
  SelfOwnedCompaniesSelectList: [],
  SelfOwnedCompaniesDetailModal: false,
  cityexport: '',
  getVehicleTypeList: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    // START LOADING
    startDataLoading(state) {
      state.isDataLoading = true;
    },
    // START LOADING
    finishDataLoading(state) {
      state.isDataLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getAllSelfOwnedVehicleSuccess(state, action) {
      // her  metod için 2 tane error ve succes metodları yazdık
      state.isLoading = false;
      state.SelfOwnedVehicleList = action.payload;
    },
    getVehicleTypeListSuccess(state, action) {
      // her  metod için 2 tane error ve succes metodları yazdık
      state.isLoading = false;
      state.getVehicleTypeList = action.payload;
    },

    // GET PROFILE
    filterLocationsSuccess(state, action) {
      state.isLoading = false;
      state.SelfOwnedVehicleList = action.payload;
    },
    // GET PROFILE
    saveLocationsSuccess(state, action) {
      state.isLoading = false;
      state.SelfOwnedVehicleList = action.payload;
    },

    // GET PROFILE
    getGetselfOwnedVehicleInformationSuccess(state, action) {
      state.isLoading = false;
      state.selfOwnedVehicleInformation = action.payload;
    },

    getGetselfOwnedVehicleDokumentsSuccess(state, action) {
      state.isLoading = false;
      state.selfOwnedVehicleDocumentList = action.payload;
    },
    onToggleDetailModal(state, action) {
      state.detailModalIsOpen = action.payload;
    },
    onToggleVechileDetailModalOpen(state, action) {
      state.vechileDetailModalOpen = action.payload;
    },
    setselfOwnedVehicleDocumentEditModalOpen(state, action) {
      state.selfOwnedVehicleDocumentEditModalOpen = action.payload;
    },
    setVehicleId(state, action) {
      state.VehicleId = action.payload;
    },

    setSelfOwnedPlate(state, action) {
      state.SelfOwnedPlate = action.payload;
    },

    setSelfOwnedId(state, action) {
      state.selfOwnedId = action.payload;
    },
    setDocumentId(state, action) {
      state.documentId = action.payload;
    },

    getSelfOwneddocumentTypeSelectListSuccess(state, action) {
      state.SelfdocumentTypesSelectList = action.payload;
    },

    getSelfOwnedCompaniesSelectListSuccess(state, action) {
      state.SelfOwnedCompaniesSelectList = action.payload;
    },
    getCarBrandSelectListSuccess(state, action) {
      state.CarBrandSelectLİst = action.payload;
    },

    getGetDangerousMaterialsTransportTypeSelectListSuccess(state, action) {
      state.DangerousMaterialsTransportTypeSelectList = action.payload;
    },

    getUnNumberSelectListSuccess(state, action) {
      state.UnNumberSelectList = action.payload;
    },

    GetgetSelfOwnedVehicleDocumentByDocumentIdSuccess(state, action) {
      state.getSelfOwnedVehicleDocumentByDocumentId = action.payload;
    },

    getChiferDriverSelectListSuccess(state, action) {
      state.ChiferDriverSelectList = action.payload;
    },
    getCarModelSelectListSuccess(state, action) {
      state.CarModelSelectLİst = action.payload;
    },
    setcarBrandId(state, action) {
      state.carBrandId = action.payload;
    },
    setTrailerEditModalOpen(state, action) {
      state.TrailerEditModalOpen = action.payload;
    },
    gettrailerListSuccess(state, action) {
      state.TrailerList = action.payload;
    },
    getTrailerDetailSuccess(state, action) {
      state.trailerDetail = action.payload;
    },
    setDocumentIdListForVehicle(state, action) {
      state.DocumentIdListForVehicle = action.payload;
    },
    setCreateSelfOwnedIsSuccess(state, action) {
      state.CreateSelfOwnedIsSuccess = action.payload;
    },
    getTrailerDocumentTypeSelectList(state, action) {
      state.TrailerDocumentTypeSelectList = action.payload;
    },
    getTrailerDocumentLİst(state, action) {
      state.trailerDocumentLİst = action.payload;
    },

    GetVehicleListWithCitySuccess(state, action) {
      state.VehicleListWithCity = action.payload;
    },

    getDocumentByIdBase64Success(state, action) {
      state.DocumentByIdBase64 = action.payload;
    },
    getTrailerDocumentTypeSelectListSuccess(state, action) {
      state.trailerDocumentType = action.payload;
    },
    setSelfOwnedCompaniesDetailModal(state, action) {
      state.SelfOwnedCompaniesDetailModal = action.payload;
    },
    getcityex(state, action) {
      state.cityexport = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onToggleDetailModal,
  onToggleVechileDetailModalOpen,
  setselfOwnedVehicleDocumentEditModalOpen,
  setVehicleId,
  setSelfOwnedPlate,
  setSelfOwnedId,
  setDocumentId,
  setcarBrandId,
  setTrailerEditModalOpen,
  setCreateSelfOwnedIsSuccess,
  setSelfOwnedCompaniesDetailModal
} = slice.actions;

export function getAllSelfOwnedVehicle() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.SelfOwnedVehicleList, {});
      dispatch(slice.actions.getAllSelfOwnedVehicleSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getgetVEhicleTypeList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.getVehicleTypeList, {});
      dispatch(slice.actions.getVehicleTypeListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSelfOwnedCompaniesVehicles(selfownedcompaniesid: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.seltownedcompaniesvehicles}?selfownedcompaniesid=${selfownedcompaniesid}`
      );
      dispatch(slice.actions.getAllSelfOwnedVehicleSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSelfOwneddocumentTypeSelectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        CONSTS.SelfOwneddocumentTypeSelectList,
        {}
      );
      dispatch(
        slice.actions.getSelfOwneddocumentTypeSelectListSuccess(
          response.data.data
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGetselfOwnedVehicleInformation(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startDataLoading());

    try {
      const response = await axios.get(
        `${CONSTS.getGetselfOwnedVehicleInformation}?vehicleId=${id}`
      );
      await dispatch(
        slice.actions.getGetselfOwnedVehicleInformationSuccess(
          response.data.data
        )
      );
      await dispatch(getCarModelSelectList(response.data.data.carBrandId));

      dispatch(slice.actions.finishLoading());
      dispatch(slice.actions.finishDataLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateSelfOwnedVehicleInformation(
  updatemodel: any,
  VehicleId: any
) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      CONSTS.updateSelfOwnedVehicleInformation,
      {
        carBrandId: updatemodel.carBrandId,
        carModelId: updatemodel.carModelId,
        carBrandName: updatemodel.carBrandName,
        carModelName: updatemodel.carModelName,
        carModelYear: Number(updatemodel.carModelYear),
        carColor: updatemodel.carColor,
        cargoCapacity: Number(updatemodel.cargoCapacity),
        selfOwnedVehicleId: Number(updatemodel.selfOwnedVehicleId),
        carmanId: Number(updatemodel.carmanId),
        selfOwnedCompaniesId: Number(updatemodel.selfOwnedCompaniesId),
        vehicleTypeId: Number(updatemodel.vehicleTypeId)
      }
    );
    if (response.data.success) {
      dispatch(getAllSelfOwnedVehicle());

      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getGetselfOwnedVehicleDokuments(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.getGetselfOwnedVehicleDokuments}?vehicleId=${id}&IsForeign=false`
      );
      dispatch(
        slice.actions.getGetselfOwnedVehicleDokumentsSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function removeVehicle(id: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${CONSTS.deleteSelfOwnedVehicle}`, id);
    if (response.data.success) {
      dispatch(getAllSelfOwnedVehicle());
      return response;
    }
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function removeSelfOwnedVehicleDocument(id: any, VehicleId: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.deleteSelfOwnedVehicleDocument}`,
      id
    );
    if (response.data.success) {
      dispatch(getGetselfOwnedVehicleDokuments(VehicleId));
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function addSelfOwnedNewVehicleDocument(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      CONSTS.addSelfOwnedVehicleDocument,
      model
    );
    if (response.data.success) {
      dispatch(getGetselfOwnedVehicleDokuments(model.vehicleId));
      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function GetgetSelfOwnedVehicleDocumentByDocumentId(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.getSelfOwnedDocumentByDocumentId}?documentId=${id}`
      );
      dispatch(
        slice.actions.GetgetSelfOwnedVehicleDocumentByDocumentIdSuccess(
          response.data.data
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateSelfOwnedVehicleDocument(
  updatemodel: any,
  vehicleId: any
) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.updateSelfOwnedVehicleDocument, {
      oldDocumentId: Number(updatemodel.id),
      documentTypeId: Number(updatemodel.documentTypeId),
      beginDate: updatemodel.startDate,
      endDate: updatemodel.endDate,
      documentImageFileBase64String: updatemodel.documentImageFileBase64String,
      documentImageName: updatemodel.documentImageName,
      vehicleId: Number(updatemodel.vehicleId),
      staffId: 0
    });
    if (response.data.success) {
      dispatch(getGetselfOwnedVehicleDokuments(vehicleId));
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getCarBrandSelectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.getCarBrandSelectList, {});
      dispatch(slice.actions.getCarBrandSelectListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCarModelSelectList(brandId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.getCarModelSelectList}?carBrandId=${brandId}`
      );
      dispatch(slice.actions.getCarModelSelectListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDangerousMaterialsTransportTypeSelectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        CONSTS.DangerousMaterialsTransportTypeSelectList,
        {}
      );
      dispatch(
        slice.actions.getGetDangerousMaterialsTransportTypeSelectListSuccess(
          response.data.data
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUnNumberSelectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.Unnumber, {});
      dispatch(slice.actions.getUnNumberSelectListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateSelfOwnedVehicleChiferDriver(
  updatemodel: any,
  vehicleId: any
) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      CONSTS.updateSelfOwnedVehicleChiferDriver,
      {
        oldDocumentId: Number(updatemodel.id),
        documentTypeId: Number(updatemodel.documentTypeId),
        vehicleId: Number(updatemodel.vehicleId),
        staffId: 0
      }
    );
    if (response.data.success) {
      dispatch(getGetselfOwnedVehicleInformation(vehicleId));
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getChiferDriverSelectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.ChiferDriverSelectList, {});
      dispatch(
        slice.actions.getChiferDriverSelectListSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSelfOwnedCompaniesSelectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.SelfOwnedCompaniesSelectList, {});
      dispatch(
        slice.actions.getSelfOwnedCompaniesSelectListSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function gettrailerList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.trailerList, {});
      dispatch(slice.actions.gettrailerListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSelfOwnedCompaniesTrailer(selfOwnedCompaniesId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.selfOwndeCompaniesTrailer}?selfOwnedCompaniesid=${selfOwnedCompaniesId}`
      );
      dispatch(slice.actions.gettrailerListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTrailerDetail(vehicleId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startDataLoading());
    try {
      const response = await axios.get(
        `${CONSTS.getTrailerDetail}?trailerId=${vehicleId}`
      );
      dispatch(slice.actions.getTrailerDetailSuccess(response.data.data));
      dispatch(slice.actions.finishDataLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateTrailerDetail(updatemodel: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.updateTrailerDetail, updatemodel);
    if (response.data.success) {
      dispatch(gettrailerList());
      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function createSelfOwnedVehicle(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.createSelfOwnedVehicle, model);
    if (response.data.success) {
      dispatch(getGetselfOwnedVehicleDokuments(model.vehicleId));

      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getTrailerTypeSelectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.getTrailerTypeSelectList, {});
      dispatch(
        slice.actions.getTrailerDocumentTypeSelectList(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTrailerDocumentTypeSelectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.trailerDocumentType, {});
      dispatch(
        slice.actions.getTrailerDocumentTypeSelectListSuccess(
          response.data.data
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function addTrailer(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.addTrailer, {
      licensePlate: model.licensePlate,
      cargoCapacity: Number(model.cargoCapacity),
      trailerTypeId: model.trailerTypeId,
      carmanId: model.carmanId,
      trailerTypeName: model.trailerTypeName,
      carmanNameAndSurname: model.carmanNameAndSurname,
      isReady: model.isReady,
      isFull: model.isFull,
      selfOwnedCompaniesId: model.selfOwnedCompaniesId
    });
    if (response.data.success) {
      //     dispatch(getGetselfOwnedVehicleDokuments(model.vehicleId));

      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getTrailerDocumentLİst(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.trailerDocumentList}?vehicleId=${id}&IsForeign=false`
      );
      dispatch(slice.actions.getTrailerDocumentLİst(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGetVehicleListWithCity(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.GetVehicleListWithCity}?cityId=${id}`
      );
      dispatch(slice.actions.GetVehicleListWithCitySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function exportcity() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONSTS.ExportCity}`);
      dispatch(slice.actions.getcityex(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDocumentByIdBase64(id: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.DocumentIdByBase64}?documentId=${id}`
      );
      dispatch(slice.actions.getDocumentByIdBase64Success(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function AddExcelFİle(model: any) {
  // const customerId = localStorage.getItem('Customer');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${CONSTS.AddExcelFile}`, model);
      if (response.status == 200) {
        alert('okeyy');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

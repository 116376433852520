import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit'; // redux bu
// import axios from 'axios';
import axios from '../../utils/axios';
import { dispatch } from '../store';
import { CONSTS } from './const';
// utils
import {
  AddPriceFormulFixedModel,
  AddPriceModel
} from '../../@types/AddPriceFormulFixedModel';
import { getPriceDefnationByCustomerIdForStaff } from './customer/GetPriceDefinition';

// ----------------------------------------------------------------------

type AddPriceFormulFixedModelState = {
  isLoading: boolean;
  error: boolean;
  detailModalIsOpen: boolean;
  AddPriceFormulFixedList: AddPriceFormulFixedModel[]; // farklı olan diğerleri fix
  AddPriceFormulFixed: AddPriceFormulFixedModel;
  AddPrice: AddPriceModel;
};
const AddPriceFormulFixedInit = {
  customerId: 0,
  dieselOilOldPrice: 0,
  dieselOilNewPrice: 0,
  fuelToBeApplied: 0,
  dieselOilIncreaseRate: 0,
  createDate: new Date(),
  isDeleted: false
};

const AddPriceInıt = {
  sourceLocationCityId: 0,
  sourceLocationDistrictId: 0,
  tonnage: 0,
  contractId: 0,
  vehicleTypeId: 0
};

const initialState: AddPriceFormulFixedModelState = {
  // redux a bağlanıyor böyle
  isLoading: false,
  error: false,
  detailModalIsOpen: false,
  AddPriceFormulFixedList: [],
  AddPriceFormulFixed: AddPriceFormulFixedInit,
  AddPrice: AddPriceInıt
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getAllAddPriceFormulFixedSuccess(state, action) {
      // her  metod için 2 tane error ve succes metodları yazdık
      state.isLoading = false;
      state.AddPriceFormulFixedList = action.payload;
    },

    // GET PROFILE
    filterAddPriceFormulFixedSuccess(state, action) {
      state.isLoading = false;
      state.AddPriceFormulFixedList = action.payload;
    },
    // GET PROFILE
    saveAddPriceFormulFixedSuccess(state, action) {
      state.isLoading = false;
      state.AddPriceFormulFixed = action.payload;
    },

    // GET PROFILE
    getAddPriceFormulFixedByIdSuccess(state, action) {
      state.isLoading = false;
      state.AddPriceFormulFixed = action.payload;
    },

    // GET PROFILE
    getAddPriceFormulFixedByCustomerIdForStaffSuccess(state, action) {
      state.isLoading = false;
      state.AddPriceFormulFixed = action.payload;
    },

    onToggleDetailModal(state, action) {
      state.detailModalIsOpen = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleDetailModal } = slice.actions;

// ----------------------------------------------------------------------

export function saveAddPriceFormulFixed(AddPriceFormulFixed: any) {
  // const customerId = localStorage.getItem('Customer');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${CONSTS.AddPriceFFormulFixed}`, {
        customerId: AddPriceFormulFixed.customerId,
        sourceLocationci: Number(AddPriceFormulFixed.dieselOilOldPrice),
        dieselOilNewPrice: Number(AddPriceFormulFixed.dieselOilNewPrice),
        fuelToBeApplied: Number(AddPriceFormulFixed.fuelToBeApplied),
        dieselOilIncreaseRate: Number(
          AddPriceFormulFixed.dieselOilIncreaseRate
        ),
        createDate: AddPriceFormulFixed.createDate,
        isDeleted: AddPriceFormulFixed.isDeleted
      });
      dispatch(
        getPriceDefnationByCustomerIdForStaff(AddPriceFormulFixed.customerId)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function CustomerPriceDefinition(priceDefinition: any) {
  // const customerId = localStorage.getItem('Customer');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${CONSTS.CustomerPriceDefinition}`,
        priceDefinition
      );
      dispatch(
        getPriceDefnationByCustomerIdForStaff(priceDefinition.customerId)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddPriceFormulFixedByCustomerIdForStaff(customerId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.GetCustomerPriceFixedComponent}?customerId=${customerId}`
      );
      if (response.data.data !== null) {
        await dispatch(
          slice.actions.getAddPriceFormulFixedByCustomerIdForStaffSuccess(
            response.data.data
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

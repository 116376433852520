import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit'; // redux bu
import { dispatch } from '../../store';
// utils
import axios from '../../../utils/axios';
import {
  CarBrandModel,
  CarModelModel,
  CashiersModel,
  GetVehicleTypesModel
} from '../../../@types/GetVehicleTypes';
import { CONSTS } from '../const';
import {
  getCarBrandSelectList,
  getCarModelSelectList
} from '../SelfOwnedVehicle';
import { getGetCashierSelectList } from '../customer/orderRequest';
// ----------------------------------------------------------------------

type VehicleTypes = {
  isLoading: boolean;
  error: boolean;
  detailModalIsOpen: boolean;
  GetVehicleTypeList: GetVehicleTypesModel[]; // farklı olan diğerleri fix
  getVehicleType: GetVehicleTypesModel;
  AddCarBrand: CarBrandModel;
  AddCarModel: CarModelModel;
  CashierAddAndEditModel: CashiersModel;
  CashierModal: boolean;
};
const vehicleTypeInit = {
  // gelen modelin propertylerin tipleri
  disabled: '',
  group: '',
  selected: true,
  text: '',
  value: ''
};

const AddCarBrandInit = {
  brandName: ''
};
const AddCashierInit = {
  name: '',
  description: '',
  id: 0
};
const AddCarModelInit = {
  modelName: '',
  brandId: 0
};
const initialState: VehicleTypes = {
  // redux a bağlanıyor böyle
  isLoading: false,
  error: false,
  detailModalIsOpen: false,
  GetVehicleTypeList: [], // diğerleri fix
  getVehicleType: vehicleTypeInit, // buda öyle announcementInit yukarıda
  AddCarBrand: AddCarBrandInit,
  AddCarModel: AddCarModelInit,
  CashierAddAndEditModel: AddCashierInit,
  CashierModal: false
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getAllVehicleTypesSuccess(state, action) {
      // her  metod için 2 tane error ve succes metodları yazdık
      state.isLoading = false;
      state.GetVehicleTypeList = action.payload;
    },

    // GET PROFILE
    filterVehicleTypesSuccess(state, action) {
      state.isLoading = false;
      state.GetVehicleTypeList = action.payload;
    },
    // GET PROFILE
    saveForeignVehicleSuccess(state, action) {
      state.isLoading = false;
      state.GetVehicleTypeList = action.payload;
    },

    // GET PROFILE
    getVehicleTypesByIdSuccess(state, action) {
      state.isLoading = false;
      state.GetVehicleTypeList = action.payload;
    },
    onToggleDetailModal(state, action) {
      state.detailModalIsOpen = action.payload;
    },
    setCashierModal(state, action) {
      state.CashierModal = action.payload;
    },
    setCashierAddAndEditModelSuccess(state, action) {
      state.CashierAddAndEditModel = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleDetailModal, setCashierModal } = slice.actions;

// ----------------------------------------------------------------------
export async function saveCarBrand(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.addCarBrand, {
      brandName: model.brandName
    });
    if (response.data.success) {
      dispatch(getCarBrandSelectList());

      return response.data.success;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function saveCarModel(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.addCarModel, {
      modelName: model.carModel,
      brandId: model.brandId
    });
    if (response.data.success) {
      dispatch(getCarModelSelectList(model.brandId));

      return response.data.success;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function saveCashier(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.addCashier, {
      name: model.name,
      description: model.description
    });
    if (response.data.success) {
      dispatch(getGetCashierSelectList());

      return response.data.success;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function removeCashierById(id: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${CONSTS.cashierDeleteById}?id=${id}`);
    if (response) {
      dispatch(getGetCashierSelectList());
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getCashierById(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONSTS.cashierGetFunction}?id=${id}`);

      dispatch(
        slice.actions.setCashierAddAndEditModelSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function cashierEditFunction(model: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.cashierEditFunction, model);
    if (response.data.success) {
      dispatch(getGetCashierSelectList());
      return response.data.success;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function addVehicleTypes(model: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${CONSTS.AnnouncementAddAnnouncement}`,
        {
          title: model.title,
          description: model.description,
          publicationDate: model.publicationDate,
          announcementTypeId: Number(model.announcementTypeId),
          takedownDate: model.takedownDate
        }
      );
      localStorage.setItem('id', response.data.data.id);

      dispatch(getAllVehicleTypes());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllVehicleTypes() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.GetVehicleTypes, {});
      dispatch(slice.actions.getAllVehicleTypesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteVehicleType(model: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${CONSTS.AnnouncementDeleteAnnouncement}`,
        model
      );
      dispatch(getAllVehicleTypes());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVehicleTypeById(announcementId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.AnnouncementGetAnnouncementById}${announcementId}`
      );
      dispatch(slice.actions.getVehicleTypesByIdSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateVehicleType(model: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${CONSTS.AnnouncementEditAnnounement}`,
        {
          id: Number(model.id),
          title: model.title,
          description: model.description,
          publicationDate: model.publicationDate,
          announcementTypeId: Number(model.announcementTypeId),
          takedownDate: model.takedownDate
        }
      );
      localStorage.setItem('id', response.data.data.id);

      dispatch(getAllVehicleTypes());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function filterAnnouncement(_query: string, _registered: boolean) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        CONSTS.AnnouncementGetAnnouncementList,
        {
          query: _query,
          registered: _registered
        }
      );
      dispatch(slice.actions.filterVehicleTypesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

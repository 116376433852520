import { ReactNode, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import {
  Link as RouterLink,
  useLocation,
  matchPath,
  useHistory
} from 'react-router-dom';
import personFill from '@iconify/icons-eva/person-fill';
import LogoutIcon from '@material-ui/icons/Logout';
import { Icon } from '@iconify/react';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import FaceIcon from '@material-ui/icons/Face';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import BusinessSharpIcon from '@material-ui/icons/BusinessSharp';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import ArrowBackIosNewOutlinedIcon from '@material-ui/icons/ArrowBackIosNewOutlined';
import {
  Box,
  Link,
  List,
  Button,
  Drawer,
  Hidden,
  Typography,
  ListSubheader,
  Stack,
  IconButton,
  Tooltip
} from '@material-ui/core';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import useIsMountedRef from '../../hooks/useIsMountedRef';

import useAuth from '../../hooks/useAuth';
// routes
import { PATH_DASHBOARD, PATH_DOCS } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import Logo2 from '../../components/Logo2';
import Scrollbar from '../../components/Scrollbar';
//
import MenuLinks from './SidebarConfig';
import SidebarItem from './SidebarItem';
// Multi Language
import useLocales from '../../hooks/useLocales';
import { RootState } from '../../redux/store';
import { MHidden, MIconButton } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import { logout } from '../../redux/slices/authJwt';
import * as S from '../../views/transportation/kanban';

// ----------------------------------------------------------------------

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(1, 2.5, 5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

const DocStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadiusMd,
  backgroundColor:
    theme.palette.mode === 'light'
      ? alpha(theme.palette.primary.main, 0.08)
      : theme.palette.primary.lighter
}));
// ----------------------------------------------------------------------

type TNavItem = {
  icon?: ReactNode;
  info?: ReactNode;
  href: string;
  title: string;
  items?: TNavItem[];
  role?: string;
};

type ReduceChildParams = {
  array: ReactNode[];
  item: TNavItem;
  pathname: string;
  level: number;
};

function reduceChild({ array, item, pathname, level }: ReduceChildParams) {
  const key = item.href + level;

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    return [
      ...array,
      <SidebarItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        open={Boolean(match)}
      >
        {renderSidebarItems({
          pathname,
          level: level + 1,
          items: item.items
        })}
      </SidebarItem>
    ];
  }
  return [
    ...array,
    <SidebarItem
      key={key}
      level={level}
      href={item.href}
      icon={item.icon}
      info={item.info}
      title={item.title}
    />
  ];
}

type RenderSidebarItemsParams = {
  items: TNavItem[];
  pathname: string;
  onClick?: any;
  level?: number;
};

function renderSidebarItems({
  items,
  pathname,
  onClick,
  level = 0
}: RenderSidebarItemsParams) {
  return (
    <List disablePadding>
      <button onClick={onClick}>
        {items.reduce<ReactNode[]>(
          (array, item) => reduceChild({ array, item, pathname, level }),
          []
        )}
      </button>
    </List>
  );
}

type NavBarProps = {
  isOpenSidebar?: boolean;
  onCloseSidebar?: VoidFunction;
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar
}: NavBarProps) {
  const { allLang, currentLang, translate, onChangeLang } = useLocales();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  // const { users } = useAuth();
  const { user } = useSelector((state: RootState) => state.authJwt);
  const [isShow, setIsShow] = useState(false);
  const [DRAWER_WIDTH, setDRAWER_WIDTH] = useState(280);
  const [open, setOpen] = useState(false);

  // let DRAWER_WIDTH = 280;

  const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
      flexShrink: 0,
      width: DRAWER_WIDTH
    }
  }));

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  function translateConverter(menulist: TNavItem[]) {
    menulist.map((p) => {
      p.title = translate(p.title);
      if (p.items) {
        translateConverter(p.items);
      }
    });
  }
  function filterAuth(items: TNavItem[]) {
    // item.role === user.userRole gelicek
    return items.filter((item) => item.role === user.role);
  }

  const handleClickShowAndHide = () => {
    if (!isShow) {
      setIsShow(true);
      setDRAWER_WIDTH(80);
    } else {
      setIsShow(false);
      setDRAWER_WIDTH(280);
    }
  };
  const handleClickOpen = () => {};

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      if (isMountedRef.current) {
        history.push('/');
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  const renderContent = (
    <S.Container>
      <Scrollbar>
        <div className="account-bar">
          <AccountPopover />
          <div className="account-links">
            <Box sx={{ p: 2, pt: 1.5 }}>
              <MIconButton
                // @ts-ignore
                to={PATH_DASHBOARD.transportation.CustomerAccount}
                component={RouterLink}
                id="account-profile"
              >
                {' '}
                <PersonIcon />
                Profil
              </MIconButton>
            </Box>

            <Box sx={{ p: 2, pt: 1.5 }}>
              {/* <Button
          fullWidth
          color="inherit"
          variant="outlined"
          onClick={handleLogout}
        >
          Çıkış Yap
        </Button> */}

              <MIconButton id="account-log-out" onClick={handleLogout}>
                {' '}
                <LogoutIcon /> Çıkış yap
              </MIconButton>
            </Box>
          </div>
        </div>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {/* {!isShow ? (
            <Box sx={{ px: 2.5, py: 3 }}>
              <RouterLink to="#">
                <Logo />
              </RouterLink>
            </Box>
          ) : null} */}

          <MHidden width="lgDown">
            {/* <Button
            style={{ display: isShow == false ? '' : 'none' }}
            onClick={() => handleClickHide()}
          >
            Gizle
          </Button> */}
            {/* <Tooltip title="Menüyü Gizle">
              <IconButton
                style={{ display: isShow == false ? '' : 'none' }}
                onClick={() => handleClickOpen()}
                color="inherit"
                edge="start"
              >
                <ArrowBackIosNewOutlinedIcon />
              </IconButton>
            </Tooltip> */}
            {/* <Button
            style={{ display: isShow == true ? '' : 'none' }}
            onClick={() => handleClickOpen()}
          >
            Aç
          </Button> */}
            {/* <Tooltip title="Menüyü Göster">
              <IconButton
                style={{ display: isShow == true ? '' : 'none' }}
                onClick={() => handleClickOpen()}
                color="inherit"
              >
                <ArrowForwardIosOutlinedIcon />
              </IconButton>
            </Tooltip> */}
          </MHidden>
        </Stack>
        {/* {isShow ? (
          <Stack>
            <IconButton color="inherit" edge="start">
              <FaceIcon />
            </IconButton>
            <IconButton color="inherit" edge="start">
              <LocalShippingIcon />
            </IconButton>
            <IconButton color="inherit" edge="start">
              <ShoppingCartIcon />
            </IconButton>
            <IconButton color="inherit" edge="start">
              <PersonIcon />
            </IconButton>
            <IconButton color="inherit" edge="start">
              <EmojiTransportationIcon />
            </IconButton>
            <IconButton color="inherit" edge="start">
              <BusinessSharpIcon />
            </IconButton>
          </Stack>
        ) : null} */}
        {/* {!isShow ? ( */}
        <div className={!isShow ? 'open_navbar' : 'close_navbar'}>
          {MenuLinks.map((list) => (
            <List
              disablePadding
              key={list.subheader}
              className="navbar-menu-left"
              subheader={
                <ListSubheader
                  disableSticky
                  disableGutters
                  sx={{
                    mt: 3,
                    mb: 2,
                    pl: 5,
                    color: 'text.primary',
                    typography: 'overline'
                  }}
                >
                  {/* {translate(list.subheader)} */}
                  {translateConverter(filterAuth(list.items))}
                </ListSubheader>
              }
            >
              {renderSidebarItems({
                items: filterAuth(list.items),
                pathname,
                onClick: handleClickShowAndHide
              })}
            </List>
          ))}
        </div>
        {/* ) : null} */}
        {!isShow ? (
          <div>
            <Box sx={{ px: 2.5, py: 3 }} style={{ alignContent: 'flex-end' }}>
              <RouterLink to="#">
                {/* <Logo /> */}
                {user.role === 'Customer' && <Logo2 />}
              </RouterLink>
            </Box>
          </div>
        ) : null}
        {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <DocStyle>
          <Box
            component="img"
            alt="doc"
            src="/static/icons/ic_doc.svg"
            sx={{ width: 36, height: 36, mb: 2 }}
          />
          <Typography
            gutterBottom
            variant="subtitle1"
            sx={{ color: 'grey.800' }}
          >
            Hi
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, color: 'grey.600' }}>
            Need help?
            <br /> Please check our docs
          </Typography>
          <Button
            fullWidth
            to={PATH_DOCS.root}
            variant="contained"
            component={RouterLink}
          >
            Documentation
          </Button>
        </DocStyle>
      </Box> */}
      </Scrollbar>
    </S.Container>
  );

  return (
    <RootStyle>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'lodash';
// utils
import axios from '../../utils/axios';
import {
  GetTrailerModel,
  getTowTruckVehicleModel,
  getSelfOwnedDriverModel,
  TowTruckVehicleReadyModel,
  ProcessOrderRequestModel
} from '../../@types/kanban';
import { dispatch } from '../store';
import { CONSTS } from './const';
// ----------------------------------------------------------------------

function objFromArray<Type extends Record<string, any>>(
  array: Type[],
  key: string = 'id'
) {
  return array.reduce<Record<string, Type>>((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

type InitialState = {
  isLoading: boolean;
  error: boolean;
  getTrailerList: GetTrailerModel[];
  getProcessOrderRequest: ProcessOrderRequestModel[];
  getTowTruckVehicleList: getTowTruckVehicleModel[];
  getTowTruckVehicleReadyList: TowTruckVehicleReadyModel[];
  getSelfOwnedDriverList: getSelfOwnedDriverModel[];
  reset: boolean;
  isDataLoading: boolean;
};

const initialState: InitialState = {
  isLoading: false,
  error: false,
  getTrailerList: [],
  getTowTruckVehicleList: [],
  getSelfOwnedDriverList: [],
  getProcessOrderRequest: [],
  getTowTruckVehicleReadyList: [],
  reset: false,
  isDataLoading: false
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getTrailer(state, action) {
      state.isLoading = false;
      state.getTrailerList = action.payload;
    },
    getTowTruckVehicle(state, action) {
      // state.getSelfOwnedDriverList = null;
      state.isLoading = false;
      state.getTowTruckVehicleList = action.payload;
    },
    getSelfOwnedDriver(state, action) {
      state.isLoading = false;
      state.getSelfOwnedDriverList = action.payload;
    },
    getTowTruckVehicleReady(state, action) {
      state.isLoading = false;
      state.getTowTruckVehicleReadyList = action.payload;
    },
    getProcessOrderRequestList(state, action) {
      state.isLoading = false;
      state.getProcessOrderRequest = action.payload;
    },

    setReset(state, action) {
      state.reset = action.payload;
    },
    // START LOADING
    startDataLoading(state) {
      state.isDataLoading = true;
    },
    // START LOADING
    finishDataLoading(state) {
      state.isDataLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

export const { setReset } = slice.actions;

// ----------------------------------------------------------------------

export async function TowTruckVehilceDeleteProcessOrder(processorderId: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.TowTruckVehicleDeleteProcessOrder}?processedOrderId=${processorderId}`
    );
    if (response.data.success) {
      getTowTruckVehilceTableReady();
      // return response.data;
    }
    const getResponseProcessOrderRequest = await axios.get(
      CONSTS.GetProcessOrderRequestKanban,
      {}
    );
    if (getResponseProcessOrderRequest.data.data !== null) {
      dispatch(
        slice.actions.getProcessOrderRequestList(
          getResponseProcessOrderRequest.data
        )
      );
      // return response.data;
    }
    return true;
    // return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}
export async function getTowTruckVehilceTableReady() {
  dispatch(slice.actions.startLoading());
  dispatch(slice.actions.startDataLoading());

  try {
    const response = await axios.get(CONSTS.TowTruckVehicleReadyTable, {});
    if (response.data.data !== null) {
      dispatch(slice.actions.getTowTruckVehicleReady(response.data));
      // return response.data;
    }
    const getResponseProcessOrderRequest = await axios.get(
      CONSTS.GetProcessOrderRequestKanban,
      {}
    );
    if (getResponseProcessOrderRequest.data.data !== null) {
      dispatch(
        slice.actions.getProcessOrderRequestList(
          getResponseProcessOrderRequest.data
        )
      );
      // return response.data;
    }
    dispatch(slice.actions.finishDataLoading());
    return true;
    // return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}
export async function getFilterReadyTowTruckVehicle(processorderId: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(
      `${CONSTS.GetFilterReadyTowTruckVehicle}?processOrderId=${processorderId}`
    );
    if (response.data.success) {
      dispatch(slice.actions.getTowTruckVehicleReady(response.data));
      // return response.data;
    }
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function ApprovedProcessOrder(vehicleId: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.ApprovedProcessOrder}?vehicleId=${vehicleId}`
    );
    if (response.data.success) {
      getTowTruckVehilceTableReady();
      // return response.data;
    }
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}
export async function TowTruckVehicleChangeProcessOrder(
  towTruckVehicleId: any,
  processorderId: any
) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${
        CONSTS.TowTruckVehicleProcessOrderChange
      }?processOrderId=${processorderId}&towTruckVehicleId=${Number(
        towTruckVehicleId
      )}`
    );
    if (response.data.success) {
      getTowTruckVehilceTableReady();
      return response.data;
    }
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}
export async function TowTruckVehicleAddProcesOrder(
  towTruckVehicleId: any,
  processorderId: any
) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${
        CONSTS.TowTruckVehicleAddProcessOrderRequest
      }?towtTruckVehicleId=${Number(
        towTruckVehicleId
      )}&processedOrderId=${processorderId}`
    );
    if (response.data.success) {
      getTowTruckVehilceTableReady();
      return response.data;
    }
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}
export async function getTrailerWithReturn() {
  dispatch(slice.actions.startLoading());
  dispatch(slice.actions.startDataLoading());
  try {
    const chiefDriverEmail = localStorage.getItem('Email');
    const response = await axios.get(
      `${CONSTS.TrailerListUseKanban}?chiefDriverEmail=${chiefDriverEmail}`
    );
    const response3 = await axios.get(CONSTS.SelfOwnedDriverUseKanban, {});
    const response2 = await axios.get(
      `${CONSTS.TowTruckVehicleListUseKanban}?chiefDriverEmail=${chiefDriverEmail}`
    );
    if (response.data.data !== null) {
      dispatch(slice.actions.getTrailer(response.data));
    }
    if (response3.data.data !== null) {
      dispatch(slice.actions.getSelfOwnedDriver(response3.data));
    }
    if (response2.data.data !== null) {
      dispatch(slice.actions.getTowTruckVehicle(response2.data));
    }
    dispatch(slice.actions.finishDataLoading());

    return true;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

// export function getTowTruckVehicleList() {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(CONSTS.TowTruckVehicleListUseKanban, {});
//       if (response.data.data !== null) {
//         dispatch(slice.actions.getTowTruckVehicle(response.data));
//         return response.data;
//       }
//       return null;
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//       return null;
//     }
//   };
// }

export async function AddTowTruckVehicleStaff(
  towTruckVehicleId: any,
  staffId: any
) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.TowTruckVehicleAddStaff}?towTruckVehicleId=${Number(
        towTruckVehicleId
      )}&staffId=${staffId}`
    );
    if (response.data.success) {
      getTrailerWithReturn();
      return true;
    }
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}
export async function AddTowTruckVehiclesTrailer(
  towTruckVehicleId: any,
  trailerId: any
) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.TowTruckVehicleAddTrailer}?towTruckId=${Number(
        towTruckVehicleId
      )}&trailerId=${trailerId}`
    );
    if (response.data.success) {
      getTrailerWithReturn();
      return response.data;
    }
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}
export async function DeleteTowTruckVehicleStaff(staffId: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.TowTruckVehicleDeleteStaff}?staffId=${staffId}`
    );
    if (response.data.success) {
      getTrailerWithReturn();
      return response.data;
    }
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function DeleteTowTruckVehicleTrailer(trailerId: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.TowTruckVehicleDeleteTrailer}?trailerId=${trailerId}`
    );
    if (response.data.success) {
      getTrailerWithReturn();
      return response.data;
    }
    return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function ChangeVehilceStatüs(
  label: any,
  isReady: any,
  isFull: any
) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${
        CONSTS.TowTruckVehicleChangeStatüs
      }?licensePlate=${label}&isReady=${Boolean(isReady)}&isFull=${Boolean(
        isFull
      )}`
    );
    if (response.data.success) {
      //    getTrailerWithReturn();
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getTowTruckVehilceTableReadySearch(search: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(
      `${CONSTS.TowTruckVehicleReadyTableSearch}?search=${search}`
    );
    if (response.data.data !== null) {
      dispatch(slice.actions.getTowTruckVehicleReady(response.data));
      // return response.data;
    }
    const getResponseProcessOrderRequest = await axios.get(
      CONSTS.GetProcessOrderRequestKanban,
      {}
    );
    if (getResponseProcessOrderRequest.data.data !== null) {
      dispatch(
        slice.actions.getProcessOrderRequestList(
          getResponseProcessOrderRequest.data
        )
      );
      // return response.data;
    }
    return true;
    // return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getTowTruckVehilceTableReadyForSearchOrder(search: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(CONSTS.TowTruckVehicleReadyTable, {});
    if (response.data.data !== null) {
      dispatch(slice.actions.getTowTruckVehicleReady(response.data));
      // return response.data;
    }
    const getResponseProcessOrderRequest = await axios.get(
      `${CONSTS.OrderKanbanReadyTableSearch}?search=${search}`
    );
    if (getResponseProcessOrderRequest.data.data !== null) {
      dispatch(
        slice.actions.getProcessOrderRequestList(
          getResponseProcessOrderRequest.data
        )
      );
      // return response.data;
    }
    return true;
    // return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getTowTruckVehilceTableReadySelfOwnedVehicleSearch() {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(CONSTS.searchSelfOwnedKanban, {});
    if (response.data.data !== null) {
      dispatch(slice.actions.getTowTruckVehicleReady(response.data));
      // return response.data;
    }
    const getResponseProcessOrderRequest = await axios.get(
      CONSTS.GetProcessOrderRequestKanban,
      {}
    );
    if (getResponseProcessOrderRequest.data.data !== null) {
      dispatch(
        slice.actions.getProcessOrderRequestList(
          getResponseProcessOrderRequest.data
        )
      );
      // return response.data;
    }
    return true;
    // return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getTowTruckVehilceTableReadyForeignSearch() {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(CONSTS.searchForeignKanban, {});
    if (response.data.data !== null) {
      dispatch(slice.actions.getTowTruckVehicleReady(response.data));
      // return response.data;
    }
    const getResponseProcessOrderRequest = await axios.get(
      CONSTS.GetProcessOrderRequestKanban,
      {}
    );
    if (getResponseProcessOrderRequest.data.data !== null) {
      dispatch(
        slice.actions.getProcessOrderRequestList(
          getResponseProcessOrderRequest.data
        )
      );
      // return response.data;
    }
    return true;
    // return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getTowTruckVehilceTableReadyPendingApprovalSearch() {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(CONSTS.searchPendingApprovalKanban, {});
    if (response.data.data !== null) {
      dispatch(slice.actions.getTowTruckVehicleReady(response.data));
      // return response.data;
    }
    const getResponseProcessOrderRequest = await axios.get(
      CONSTS.GetProcessOrderRequestKanban,
      {}
    );
    if (getResponseProcessOrderRequest.data.data !== null) {
      dispatch(
        slice.actions.getProcessOrderRequestList(
          getResponseProcessOrderRequest.data
        )
      );
      // return response.data;
    }
    return true;
    // return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getTowTruckVehilceTableReadyOnWaySearch() {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(CONSTS.searchOnWayKanban, {});
    if (response.data.data !== null) {
      dispatch(slice.actions.getTowTruckVehicleReady(response.data));
      // return response.data;
    }
    const getResponseProcessOrderRequest = await axios.get(
      CONSTS.GetProcessOrderRequestKanban,
      {}
    );
    if (getResponseProcessOrderRequest.data.data !== null) {
      dispatch(
        slice.actions.getProcessOrderRequestList(
          getResponseProcessOrderRequest.data
        )
      );
      // return response.data;
    }
    return true;
    // return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getTowTruckVehilceTableReadyForSearchNormalOrder() {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(CONSTS.TowTruckVehicleReadyTable, {});
    if (response.data.data !== null) {
      dispatch(slice.actions.getTowTruckVehicleReady(response.data));
      // return response.data;
    }
    const getResponseProcessOrderRequest = await axios.get(
      CONSTS.searchNormalOrder,
      {}
    );
    if (getResponseProcessOrderRequest.data.data !== null) {
      dispatch(
        slice.actions.getProcessOrderRequestList(
          getResponseProcessOrderRequest.data
        )
      );
      // return response.data;
    }
    return true;
    // return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getTowTruckVehilceTableReadyForSearchParsiyelOrder() {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(CONSTS.TowTruckVehicleReadyTable, {});
    if (response.data.data !== null) {
      dispatch(slice.actions.getTowTruckVehicleReady(response.data));
      // return response.data;
    }
    const getResponseProcessOrderRequest = await axios.get(
      CONSTS.searchParsiyelOrder,
      {}
    );
    if (getResponseProcessOrderRequest.data.data !== null) {
      dispatch(
        slice.actions.getProcessOrderRequestList(
          getResponseProcessOrderRequest.data
        )
      );
      // return response.data;
    }
    return true;
    // return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getTowTruckVehilceTableReadyForSearchGecmisOrder() {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(CONSTS.TowTruckVehicleReadyTable, {});
    if (response.data.data !== null) {
      dispatch(slice.actions.getTowTruckVehicleReady(response.data));
      // return response.data;
    }
    const getResponseProcessOrderRequest = await axios.get(
      CONSTS.searchGecmisOrder,
      {}
    );
    if (getResponseProcessOrderRequest.data.data !== null) {
      dispatch(
        slice.actions.getProcessOrderRequestList(
          getResponseProcessOrderRequest.data
        )
      );
      // return response.data;
    }
    return true;
    // return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getTowTruckVehilceTableReadyForSearch1To3DayOrder() {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(CONSTS.TowTruckVehicleReadyTable, {});
    if (response.data.data !== null) {
      dispatch(slice.actions.getTowTruckVehicleReady(response.data));
      // return response.data;
    }
    const getResponseProcessOrderRequest = await axios.get(
      CONSTS.search1To3DayOrder,
      {}
    );
    if (getResponseProcessOrderRequest.data.data !== null) {
      dispatch(
        slice.actions.getProcessOrderRequestList(
          getResponseProcessOrderRequest.data
        )
      );
      // return response.data;
    }
    return true;
    // return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}
export async function getTowTruckVehilceTableReadyForSearch1to7DayOrder() {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(CONSTS.TowTruckVehicleReadyTable, {});
    if (response.data.data !== null) {
      dispatch(slice.actions.getTowTruckVehicleReady(response.data));
      // return response.data;
    }
    const getResponseProcessOrderRequest = await axios.get(
      CONSTS.search1To7DayOrder,
      {}
    );
    if (getResponseProcessOrderRequest.data.data !== null) {
      dispatch(
        slice.actions.getProcessOrderRequestList(
          getResponseProcessOrderRequest.data
        )
      );
      // return response.data;
    }
    return true;
    // return response.data;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getSearchVehicleForChefScreen(search: any) {
  dispatch(slice.actions.startLoading());
  try {
    const chiefDriverEmail = localStorage.getItem('Email');
    const response2 = await axios.get(
      `${CONSTS.searchVehicleChefScreen}?search=${search}&chefmail=${chiefDriverEmail}`
    );

    if (response2.data.data !== null) {
      dispatch(slice.actions.getTowTruckVehicle(response2.data));
    }

    return true;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getSearchTrailerForChefScreen(search: any) {
  dispatch(slice.actions.startLoading());
  try {
    const chiefDriverEmail = localStorage.getItem('Email');

    const response2 = await axios.get(
      `${CONSTS.searchTrailerChefScreen}?search=${search}&chefmail=${chiefDriverEmail}`
    );

    if (response2.data.data !== null) {
      dispatch(slice.actions.getTrailer(response2.data));
    }
    return true;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getSearchDriverForChefScreen(search: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response3 = await axios.get(
      `${CONSTS.searchDriverChefScreen}?search=${search}`
    );

    if (response3.data.data !== null) {
      dispatch(slice.actions.getSelfOwnedDriver(response3.data));
    }

    return true;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getrefreshAllVehicle() {
  dispatch(slice.actions.startLoading());
  try {
    const chiefDriverEmail = localStorage.getItem('Email');

    const response2 = await axios.get(
      `${CONSTS.TowTruckVehicleListUseKanban}?chiefDriverEmail=${chiefDriverEmail}`
    );

    if (response2.data.data !== null) {
      dispatch(slice.actions.getTowTruckVehicle(response2.data));
    }
    return true;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getrefreshAllTrailer() {
  dispatch(slice.actions.startLoading());
  try {
    const chiefDriverEmail = localStorage.getItem('Email');
    const response = await axios.get(
      `${CONSTS.TrailerListUseKanban}?chiefDriverEmail=${chiefDriverEmail}`
    );

    if (response.data.data !== null) {
      dispatch(slice.actions.getTrailer(response.data));
    }

    return true;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function getrefreshAllDriver() {
  dispatch(slice.actions.startLoading());
  try {
    const response3 = await axios.get(CONSTS.SelfOwnedDriverUseKanban, {});
    if (response3.data.data !== null) {
      dispatch(slice.actions.getSelfOwnedDriver(response3.data));
    }
    return true;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

import jwtDecode from 'jwt-decode';
import { createSlice } from '@reduxjs/toolkit';
import useAuth from '../../../hooks/useAuth';
import { dispatch, store } from '../../store';
// utils
import axios from '../../../utils/axios';
// @types
import {
  CustomerAccouuntInformation,
  CustomerBasicInfo
} from '../../../@types/CustomerAll';
import { CONSTS } from '../const';
// ----------------------------------------------------------------------

type CustomerState = {
  isLoading: boolean;
  error: boolean;
  customerInformation: CustomerAccouuntInformation;
  customerBasicInformation: CustomerBasicInfo;
};

const customerInformationInit = {
  userName: '',
  customerId: '',
  email: '',
  role: '',
  phoneNumber: '',
  companyAddress: '',
  companyPhonenumber: '',
  customerCompanyName: '',
  adress: ''
};

const customerBasicInfoInit = {
  address: '',
  currentCode: 0,
  customerCompanyName: '',
  phone: '',
  hasContract: false
};

const initialState: CustomerState = {
  isLoading: false,
  error: false,
  customerInformation: customerInformationInit,
  customerBasicInformation: customerBasicInfoInit
};

const slice = createSlice({
  name: 'customerDetailInformation',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    customerDetailInformation(state, action) {
      state.isLoading = false;
      state.customerInformation = action.payload.data.data;
    },
    customerBasicInformation(state, action) {
      state.isLoading = false;
      state.customerBasicInformation = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const setcustomerCompany = (customerCompany: string | null) => {
  if (customerCompany) {
    localStorage.setItem('customerCompanyName', customerCompany);
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('customerCompanyName');
    // delete axios.defaults.headers.common.Authorization;
  }
};

export function customerDetailInformation(email: any) {
  // const email = localStorage.getItem('Email');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.CustomerDetailInformation}?email=${email}`
      );
      dispatch(slice.actions.customerDetailInformation(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function CustomerBasicInformation(customerId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.getCustomerBasicInfo}?customerId=${Number(customerId)}`
      );
      dispatch(slice.actions.customerBasicInformation(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function customerPasswordChange(resetPassword: any) {
  const email = localStorage.getItem('Email');
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.CustomerResetPasswordChange, {
      PasswordNew: resetPassword.PasswordNew,
      Email: email,
      OldPassword: resetPassword.OldPassword
    });
    if (response.data.success) {
      return response.data;
    }
    return response.data;
  } catch (error) {
    return false;
  }
}

export function CustomerCompanyNameSetLocalStored(customerCompanyName: any) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      setcustomerCompany(customerCompanyName);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit'; // redux bu
import { dispatch } from '../../store';
// utils
import axios from '../../../utils/axios';
import {
  DistrictForCitySelectListModel,
  DistrictSelectListModel
} from '../../../@types/DistrictForCitySelectListModel';
import {
  AddLocationModel,
  citySelectList,
  GetLocationByIdModel,
  searchdatamodel
} from '../../../@types/LocationModel';
// import { getAllLocations } from './GetLocations';
import { CONSTS } from '../const';
import {
  getAllLocations,
  getLocationsByCustomerIdForStaff
} from './GetLocations';
// ----------------------------------------------------------------------

type CitySelectListState = {
  isLoading: boolean;
  error: boolean;
  detailModalIsOpen: boolean;
  CitySelectListList: citySelectList[]; // farklı olan diğerleri fix
  CitySelectList: citySelectList;
  GetDistrictSelectList: DistrictSelectListModel[];
  DistrictSelectList: DistrictSelectListModel;
  DistrictForCitySelectListLİst: DistrictForCitySelectListModel[]; // farklı olan diğerleri fix
  DistrictForCistSelectList: DistrictForCitySelectListModel;
  AddLocationList: AddLocationModel[];
  AddLocation: AddLocationModel;
  GetLocationById: GetLocationByIdModel;
  verballAddresss: string;
  cityName: string;
  latitudee: string;
  longitudee: string;
  latitudee2: string;
  longitudee2: string;
  cityIdd: number;
  districtIdd: number;
  districtName: string;
  locationNName: string;
  toggleLocationEdit: boolean;
  locationID: number;
  searchAddressData: searchdatamodel[];
};

const addLocationInit = {
  id: 0,
  customerId: 0,
  locationName: '',
  cityId: 0,
  // citySelectList: citySelectList[];
  districtId: 0,
  // districtSelectList: districtSelectList[];
  googleMapUrl: '',
  verbalAddress: '',
  cityname: '',
  districtName: ''
};
const getLocationByLocationIdInıt = {
  city: '',
  cityId: 0,
  locationName: '',
  createDate: new Date(),
  customerId: 0,
  district: '',
  districtId: 0,
  googleMapUrl: '',
  id: 0,
  latitude: '',
  longitude: '',
  verbalAddress: ''
};

const CitySelectListInit = {
  disabled: false,
  group: {
    disabled: false,
    name: ''
  },
  selected: false,
  text: '',
  value: ''
};
const DistrictForCistSelectListInit = {
  disabled: false,
  group: {
    disabled: false,
    name: ''
  },
  selected: false,
  text: '',
  value: ''
};
const DistrictSelectListInit = {
  // gelen modelin propertylerin tipleri
  disabled: false,
  group: {
    disabled: false,
    name: ''
  },
  selected: false,
  text: '',
  value: ''
};

const initialState: CitySelectListState = {
  // redux a bağlanıyor böyle
  isLoading: false,
  error: false,
  detailModalIsOpen: false,
  CitySelectListList: [], // diğerleri fix
  CitySelectList: CitySelectListInit, // buda öyle announcementInit yukarıda
  DistrictForCitySelectListLİst: [], // diğerleri fix
  DistrictForCistSelectList: DistrictForCistSelectListInit, // buda öyle announcementInit yukarıda
  AddLocationList: [],
  AddLocation: addLocationInit,
  GetDistrictSelectList: [],
  DistrictSelectList: DistrictSelectListInit,
  GetLocationById: getLocationByLocationIdInıt,
  verballAddresss: '',
  cityName: '',
  latitudee: '',
  longitudee: '',
  latitudee2: '',
  longitudee2: '',
  cityIdd: 0,
  districtIdd: 0,
  districtName: '',
  locationNName: '',
  toggleLocationEdit: false,
  locationID: 0,
  searchAddressData: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getAlCitySelectListSuccess(state, action) {
      state.isLoading = false;
      state.CitySelectListList = action.payload;
    },
    setLatitude(state, action) {
      state.latitudee = action.payload;
    },
    setLongitude(state, action) {
      state.longitudee = action.payload;
    },
    Latitude(state, action) {
      state.latitudee2 = action.payload;
    },
    Longitude(state, action) {
      state.longitudee2 = action.payload;
    },
    saveCustomerLocationSuccess(state, action) {
      state.isLoading = false;
      state.AddLocationList = action.payload;
    },
    setVerballAddress(state, action) {
      state.verballAddresss = action.payload;
    },
    setCityName(state, action) {
      state.cityName = action.payload;
    },
    setDistrictName(state, action) {
      state.districtName = action.payload;
    },

    setlocationName(state, action) {
      state.locationNName = action.payload;
    },

    getCitySelectListByIdSuccess(state, action) {
      state.isLoading = false;
      state.CitySelectListList = action.payload;
    },
    onToggleDetailModal(state, action) {
      state.detailModalIsOpen = action.payload;
    },
    setcityId(state, action) {
      state.cityIdd = action.payload;
    },
    setdistrictId(state, action) {
      state.districtIdd = action.payload;
    },
    getAlDistrictForCistSelectListSuccess(state, action) {
      state.isLoading = false;
      state.DistrictForCitySelectListLİst = action.payload;
    },
    getAllDistrictSelectList(state, action) {
      state.isLoading = false;
      state.GetDistrictSelectList = action.payload;
    },

    SearchAddressApiSuccess(state, action) {
      state.isLoading = false;
      state.searchAddressData = action.payload;
    },
    setToggleLocationEdit(state, action) {
      state.isLoading = false;
      state.toggleLocationEdit = action.payload;
    },

    getLocationByLocationIdSuccess(state, action) {
      state.isLoading = false;
      state.GetLocationById = action.payload;
    },
    setLocationID(state, action) {
      state.isLoading = false;
      state.locationID = action.payload;
    },
    getLocationByIdsetNull(state, action) {
      state.isLoading = false;
      state.GetLocationById = action.payload;
    }
  }
});

export default slice.reducer;

export const {
  onToggleDetailModal,
  setVerballAddress,
  setCityName,
  setdistrictId,
  setLatitude,
  setcityId,
  setLongitude,
  Latitude,
  Longitude,
  setDistrictName,
  setlocationName,
  setToggleLocationEdit,
  setLocationID,
  getLocationByIdsetNull
} = slice.actions;

export function getAllCitySelectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.CitySelectList, {});
      dispatch(slice.actions.getAlCitySelectListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDistrictForCitySelectList(idForCity: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.DistrictForCitySelectList}?cityId=${idForCity}`
      );
      dispatch(
        slice.actions.getAlDistrictForCistSelectListSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function AllDistrict() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(CONSTS.allDistrict, {});

      dispatch(
        slice.actions.getAlDistrictForCistSelectListSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDistrictCitySelectList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONSTS.DistrictSelectList}`);
      dispatch(slice.actions.getAllDistrictSelectList(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function editCustomerLocation(model: any) {
  dispatch(slice.actions.startLoading());

  try {
    const response = await axios.post(CONSTS.EditCustomerLocation, {
      customerId: Number(model.customerId),
      locationName: model.locationName,
      id: model.id,
      cityId: Number(model.cityId),
      // citySelectList: citySelectList[];
      districtId: Number(model.districtId),
      // districtSelectList: districtSelectList[];
      googleMapUrl: model.googleMapUrl,
      verbalAddress: model.verbalAddress,
      cityname: model.cityname,
      districtName: model.districtName,
      latitude: model.latitude,
      longitude: model.longitude
    });
    if (response.data.success) {
      dispatch(getAllLocations(model.customerId));
      return response.data.success;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export async function saveCustomerLocation(AddLocation: any) {
  dispatch(slice.actions.startLoading());

  try {
    const response = await axios.post(CONSTS.AddCustomerLocation, {
      customerId: Number(AddLocation.customerId),
      locationName: AddLocation.locationName,

      cityId: Number(AddLocation.cityId),
      // citySelectList: citySelectList[];
      districtId: Number(AddLocation.districtId),
      // districtSelectList: districtSelectList[];
      googleMapUrl: AddLocation.googleMapUrl,
      verbalAddress: AddLocation.verbalAddress,
      cityname: AddLocation.cityname,
      districtName: AddLocation.districtName,
      latitude: AddLocation.latitude,
      longitude: AddLocation.longitude
    });
    if (response.data.success) {
      dispatch(getAllLocations(AddLocation.customerId));
      return response.data.success;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

// export async function saveCustomerLocationWithReturn(AddLocation: any) {
//   const customerId = localStorage.getItem('Customer');
//   const response = await axios.post(CONSTS.AddCustomerLocation, {
//     customerId: Number(customerId),
//     locationName: AddLocation.locationName,
//     cityId: Number(AddLocation.cityId),
//     // citySelectList: citySelectList[];
//     districtId: Number(AddLocation.districtId),
//     // districtSelectList: districtSelectList[];
//     googleMapUrl: AddLocation.googleMapUrl,
//     verbalAddress: AddLocation.verbalAddress,
//     cityname: AddLocation.cityname,
//     districtName: AddLocation.districtName
//   });
// if (response.data.success) {
//   return response.data;
// }
//   return null;
// }
export async function updateCustomerLocation(AddLocation: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(CONSTS.EditCustomerLocation, {
      id: Number(AddLocation.id),
      customerId: Number(AddLocation.customerId),
      locationName: AddLocation.locationName,
      cityId: Number(AddLocation.cityId),
      districtId: Number(AddLocation.districtId),
      createDate: new Date(AddLocation.createDate),
      googleMapUrl: AddLocation.googleMapUrl,
      verbalAddress: AddLocation.verbalAddress,
      cityname: AddLocation.cityname,
      districtName: AddLocation.districtName,
      latitude: AddLocation.latitude,
      longitude: AddLocation.longitude
    });
    if (response.data.success) {
      dispatch(getAllLocations(AddLocation.customerId));
      return response.data;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}
export function mapistek() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        'https://nominatim.openstreetmap.org/reverse?format=json&lat=54.9824031826&lon=9.2833114795&zoom=18&addressdetails=1'
      );
      dispatch(slice.actions.getAllDistrictSelectList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function searchAddressFromApi(searchname: string) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(
      `${CONSTS.searchAddressApiRequest}?searchname=${searchname}`
    );
    if (response) {
      return response.data.results;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function getLocationByLocationId(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.getLocationByLocationID}?locationId=${id}`
      );
      dispatch(
        slice.actions.getLocationByLocationIdSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

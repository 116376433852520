import styled from 'styled-components';

export const Container = styled.div`
  :root {
    --border-radius-500: 8px;
    --color-on-background: #ffffff;
    --fonts-primary: Karla, sans-serif;
  }

  * {
    box-sizing: border-box;
  }

  .radio-left [data-testid='RadioButtonUncheckedIcon'] {
    left: 41px;
  }

  div#root {
    overflow: hidden !important;
  }
  .list_item_action {
    position: absolute;
    z-index: 10;
    right: 10px;
  }
  .holderTitleItem {
    padding: 10px 0px 10px 10px;
    color: white;
    width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    font-size: 12px;
  }

  .text-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 13px !important;
  }

  .holderTitleItem + .holderTitleItem {
    //   margin-left: 12px;
  }
  body {
    background-color: #2a385c;
    background-image: linear-gradient(
      320deg,
      hsl(215, 10%, 12%) 0% /* down */,
      hsl(215, 19%, 24%) /* up */ 100%
    );
    font-family: var(--fonts-primary);
    font-size: 14px;
    margin: 0;
    min-height: 100vh;
  }

  .layout__wrapper {
    background-image: linear-gradient(
      0deg,
      hsl(215, 14%, 16%) 0%,
      hsl(215, 19%, 29%) 100%
    );
    border-radius: 3px;
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.25);
    margin: 3rem auto;
    max-width: 370px;
  }

  .app-bar {
    border-radius: 3px;
  }

  .app-bar__title {
    color: var(--color-on-background);
    font-size: 1rem;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
  }

  .list {
    list-style: none;
    margin: 0;
    min-height: 1.5rem;
    padding: 0;
    position: relative;
  }

  .list__item {
  }

  .legend-Container {
    display: block;
    list-style: none;
    width: 100%;
    padding: 0px 32px 48px 32px;
  }
  .legend-Container2 {
    display: flex;
    list-style: none;
    width: 100%;
    padding: 0px 56px 48px 56px;
  }
  .legend-item:before {
    content: '';
    position: relative;
    top: 4px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid white;
    margin-right: 8px;
  }
  .legend-Selfownedvehicle:before {
    background-color: cornflowerblue;
  }
  .legend-OnTheWay:before {
    background-color: #8b658b;
  }

  .legend-ForeingVehicle:before {
    background-color: cadetblue;
  }

  .legend-normal:before {
    background-color: burlywood;
  }
  .legend-partial:before {
    background-color: firebrick;
  }

  .legend-past:before {
    background-color: #f90808;
  }

  .legend-1-3:before {
    background-color: #14ff00;
  }
  .legend-1-7:before {
    background-color: #ffa000;
  }

  .legend-item {
    display: inline-block;
    float: left;
    margin-right: 17px;
    width: auto;
  }

  .border1 ul.order-details {
    background: firebrick;
  }

  .card {
    //  background-color: hsl(215, 14%, 37.5%);
    //   border-radius: var(--border-radius-500);
    cursor: pointer;
    color: hsl(228, 19%, 98%);
    //   padding: 0.66rem 1rem;
    position: relative;
  }

  .card1 {
    background-color: firebrick;
    border-radius: var(--border-radius-500);
    cursor: pointer;
    color: hsl(228, 19%, 98%);
    padding: 0.66rem 1rem;
    position: relative;
  }

  .card2 {
    background-color: burlywood;
    border-radius: var(--border-radius-500);
    cursor: pointer;
    color: hsl(228, 19%, 98%);
    padding: 0.66rem 1rem;
    position: relative;
  }
  .card2-1 {
    background-color: #8b658b;
  }
  .card2-0 {
    background-color: burlywood;
  }
  .list__item + .list__item {
    // margin-top: 0.5rem;
  }

  .holder {
    //  padding: 1rem 1.5rem;
  }

  .holder__title {
    color: #0c0c0c;
    margin-bottom: 1rem;
    padding: 0;
    background-image: none;

    font-size: 14px;
  }

  .holder--tint-2 .holder__title {
    background-image: linear-gradient(90deg, #2fd4bd 0%, #66e887 100%);
  }

  .holder--tint-3 .holder__title {
    background-image: linear-gradient(90deg, #fe8080 33%, #fea380 100%);
  }
  *.kanbanCol {
    height: calc(100vh - 250px);
    overflow-y: hidden;
    padding-right: 20px;
  }
  /* *.list.orderrequest {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(320px,1fr));
    gap: 16px;
  } */
  *.kanbanCol:hover {
    overflow-y: overlay;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 9999px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  .statusSelector {
    display: block;
    width: 65px;
    padding: 10px 0;
    position: absolute;
    // right: -55px;
    height: auto;
    background-color: white;
    z-index: 10;
    border-radius: 4px;
    top: 0;
  }
  .statusSelector ul {
    list-style: none;
    width: 100%;
    display: block;
  }
  .statusSelector ul li {
    padding: 0 8px;
  }
  .statusSelector ul li + li {
    margin-top: 4px;
  }

  .container {
    visibility: hidden;
  }

  .loader {
    color: #008cff;
    height: 40px;
    left: 45%;
    position: absolute;
    top: 45%;
    width: 30%;
  }

  .e-caret {
    transform: rotate(0deg);
    transition: transform 200ms ease-in-out;
  }

  .e-caret-up .e-caret {
    transform: rotate(180deg);
  }

  .my-masonry-grid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 16px;
    width: 100%;
  }
  .my-masonry-grid_column {
    display: contents;
  }

  /* Style your items */
  .my-masonry-grid_column > div {
    width: 140px;
    margin: 0 auto;
    border-radius: 12px;
    margin-bottom: 30px;
  }

  .my-masonry-grid_2 {
    display: block;
    width: 100%;
  }
  .my-masonry-grid_2_column {
    width: 100%;
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_2_column > div {
    /* change div to reference your elements you put in <Masonry> */
    /*background: grey;*/
    margin-bottom: 30px;
  }

  .my-masonry-grid_2_column .holder__content ul.list {
    display: flex;
    flex-diretion: row;
    gap: 16px;
    width: calc(100% - 56px);
  }

  .my-masonry-grid_2_column .holder__content ul.list li {
    flex: 0 0 33.33%;
    margin: 0 !important;
  }

  .my-masonry-grid_2_column .holder__content ul.list li.list__item {
    display: flex;
    align-items: center;
  }

  // hazır kamyonetler ek düzeltme

  div.ready-trucks .holder__content ul.list {
    display: block !important;
  }

  // sipariş kartları

  .holder__content ul.list {
    display: grid !important;
    gap: 16px !important;
    grid-template-columns: auto auto auto auto;
    width: 100%;
    z-index: 1;
  }

  .holder__content ul.list li.list__item {
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    width: 120px;
  }

  .border2 {
    border: 1px solid burlywood;
  }

  .border1 {
    border: 1px solid firebrick;
  }

  .borderfalse {
    border: 1px solid cornflowerblue;
  }

  .bordertrue {
    border: 1px solid cadetblue;
  }

  // sipariş no - başla
  .holder__content ul.list li.list__item div.order-no {
    display: block !important;
    margin: 0 !important;
    width: 100%;
    font-size: 12px;
  }

  .holder__content ul.list li.list__item div.order-no > div {
    background-color: white;
    color: black;
    max-width: unset !important;
    padding: 8px !important;
    width: 100%;
  }
  // sipariş no - bitir

  // öğeler - başla
  .holder__content ul.list li.list__item ul.order-details {
    list-style: none;
    width: 100%;
  }

  .holder__content ul.list li.list__item ul.order-details li {
    // background-color: red;
    border-bottom: 1px solid white;
    color: white;
    width: 100%;
    font-size: 12px;
  }

  .holder__content ul.list li.list__item ul.order-details li:last-of-type {
    border-bottom: none;
  }
  // öğeler - bitir

  // sipariş öğesi footer - başla
  .holder__content ul.list li.list__item div.holderDateItem {
    background-color: white;
    color: black;
    padding: 8px !important;
    width: 100%;
    font-size: 12px;
  }

  // sipariş öğesi footer - bitir

  //content block-top dizayn

  .content-block-top-dizayn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /// bilgilendirme buton textler-başla

  .buttonstext {
    background-color: inherit;
    padding: 3px 13px;
    font-size: 9px;
    display: inline-block;
    border: 2px solid;
    border-radius: 5px;
    margin-right: 10px;
  }

  .normalbutton {
    color: burlywood;
  }

  .normalbutton:hover {
    color: white;
    background: burlywood;
  }

  .ozmalbutton {
    color: cornflowerblue;
  }

  .ozmalbutton:hover {
    color: white;
    background: cornflowerblue;
  }

  .yabancibutton {
    color: cadetblue;
  }

  .yabancibutton:hover {
    color: white;
    background: cadetblue;
  }

  .onaydabutton {
    color: #f0ab3c;
  }

  .onaydabutton:hover {
    color: white;
    background: #f0ab3c;
  }

  .yuklubutton {
    color: #8b658b;
  }

  .yuklubutton:hover {
    color: white;
    background: #8b658b;
  }

  .parsiyelbutton {
    color: firebrick;
  }

  .parsiyelbutton:hover {
    color: white;
    background: firebrick;
  }

  .pastbutton {
    color: #f90808;
  }

  .pastbutton:hover {
    color: white;
    background: #f90808;
  }

  .button1-3 {
    color: #14ff00;
    width: max-content;
  }

  .button1-3:hover {
    color: white;
    background: #14ff00;
    width: max-content;
  }

  .button1-7 {
    color: #ffa000;
    width: max-content;
  }

  .button1-7:hover {
    color: white;
    background: #ffa000;
    width: max-content;
  }

  .css-i4bv87-MuiSvgIcon-root {
    position: relative;
    top: 3px;
    width: 41px;
    height: 22px;
  }

  // search ınput

  .css-12gxfsc-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 10px;
    flex-direction: row-reverse;
  }
  .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    margin: 25px 0px 25px 0px;
  }

  // atanmış sipariş css başla

  // .holder--tint-1 .holder__content ul.list li.list__item div.order-no {
  //   display: none !important;
  // }
  // .holder--tint-1 ul.order-details li:not(:first-child) {
  //   display: none;
  // }

  // .holder--tint-1 div.holderDateItem {
  //   display: none;
  // }

  .holder--tint-1 .holder__content ul.list li.list__item {
    margin: auto !important;
  }

  .arrow-order-detail {
    width: 4em;
    top: -7px;
  }

  .holder--tint-2 .arrow-order-detail {
    display: none;
  }
  .holder--tint-1 ul.order-details li:first-child {
    height: 45px;
  }

  //fab button css başla

  .fab-button {
    height: 15px;
    min-height: 15px;
    margin-right: 5px;
    font-weight: 300;
  }

  // tablo sekmelerinin css başla

  .css-ls60h3-MuiButtonBase-root-MuiTab-root.Mui-selected {
    background-color: #00ab55;
    color: white;
  }

  // dorse ve şoför ekranı css başla

  .holder__content ul.list.vehicle-connect1 {
    grid-template-columns: auto !important;
    display: inline-block !important;
  }

  .holder__content ul.list.vehicle-notConnect {
    grid-template-columns: auto auto !important;
  }

  .holder.holder--tint-2.tint-trailer-driver .holder__content.notConnect {
    padding: 15px 10%;
  }

  .holder.holder--tint-3.tint-trailer-driver .holder__content.notConnect {
    padding: 15px 10%;
  }

  .holder__content ul.list.vehicle-notConnect li {
    width: 140px;
  }

  .holder__content ul.list.vehicle-connect1 li.list__item {
    margin-bottom: 5px !important;
    height: 44px;
  }

  .holder__content ul.list.vehicle-notConnect div.card {
    padding: 10px;
  }

  .p-text {
    font-size: 12px;
  }
  .drivername {
    overflow: unset !important;
    white-space: normal !important;
  }

  .holder__content.connect1 .card {
    padding: 0px 10px;
  }

  .vehicle-places {
    padding: 8px;
    color: white;
  }

  .inline-data {
    padding: 8px;
    color: white;
  }

  .holder.holder--tint-1.card.tint-vehicles svg {
    float: right;
    display: none;
    background: cornflowerblue;
    width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  .holder.holder--tint-1.card.tint-vehicles .holder__title {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  .holder.holder--tint-1.card .list {
    margin: -15px 7px 9px 0px;
  }

  .list.vehicle-connect1 .holder__title {
    background: cornflowerblue;
  }

  .holder.holder--tint-2.tint-trailer-driver li.list__item {
    background-color: cadetblue;
  }

  .holder.holder--tint-3.tint-trailer-driver li.list__item {
    background-color: burlywood;
  }

  .holder__content ul.list.vehicle-connect1 li.list__item.trailer {
    background-color: cadetblue;
  }

  .holder__content ul.list.vehicle-connect1 li.list__item.driver {
    background-color: burlywood;
  }

  .chef-screen-refresh {
    display: flex;
    list-style: none;
    width: 100%;
    padding: 0;
  }

  .content-block-top-dizayn2 {
    display: flex;
    align-items: baseline;
  }

  .holder.holder--tint-2.tint-trailer-driver li.list__item.true {
    background-color: #ff5858;
  }

  .holder__content ul.list.vehicle-connect1 li.list__item.trailer.true {
    background-color: #ff5858;
  }

  //layout tasarımı

  .account-bar {
    display: flex;
    margin-top: 50px;
    position: relative;
    left: 30px;
  }

  .account-bar > div {
    align-items: center;
  }

  .account-links > div {
    padding: 0;
  }

  .account-links button {
    padding: 2px;
  }
  .account-links a {
    padding: 2px;
  }
  #account-profile {
    font-size: 13px;
  }

  #account-log-out {
    font-size: 13px;
  }

  #account-log-out svg {
    position: relative;
    top: 0px;
    width: 26px;
    height: 19px;
  }

  #account-profile svg {
    position: relative;
    top: 0px;
    width: 26px;
    height: 19px;
  }

  .navbar-menu-left ul button {
    width: 100%;
    background: transparent;
    border: none;
  }

  .open_navbar > .navbar-menu-left > ul > button > a:nth-child(1) {
    flex-direction: row-reverse;
  }

  .circular-progress {
    margin-left: 50%;
  }

  .open_navbar
    .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-hidden.css-bz4dnt-MuiCollapse-root {
    height: auto;
    visibility: visible;
  }

  .MuiTableCell-root {
    padding: 4px 16px !important;
    text-align: start !important;
  }
  td button.MuiIconButton-root {
    padding: 0 !important;
  }

  td button .MuiButton-root {
    padding: 0 !important;
  }

  td.MuiTableCell-root {
    text-align: left !important;
  }
  .MuiTableCell-head svg {
    display: none !important;
  }
`;

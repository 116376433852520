import { ReactNode } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PATH_AUTH, PATH_DASHBOARD } from '../routes/paths';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

type AuthProtectProps = {
  children: ReactNode;
};

export default function AuthProtect({ children }: AuthProtectProps) {
  const { isLoading, isAuthenticated, user } = useAuth();
  const history = useHistory();

  // yasaklılar yaz
  const insideGuard = {
    //  rol url erişim
    Customer: [
      '/dashboard/Staff/Customers',
      '/GetVehicleListWithCity',
      '/waitingOKShipmentList',
      '/Staff/selfOwnedVehicleDriverList',
      '/Staff/EscalationScreen',
      '/Staff/SelfOwnedCompanies',
      '/Staff/foreignTransitCompany',
      '/Staff/AddDriver',
      '/Staff/TrailerList',
      '/Staff/StaffList',
      '/Staff/AddStaff',
      '/Staff/AddCustomerInfo',
      '/Staff/OrderRequestFull',
      '/Staff/EditContract',
      '/Staff/ContractList',
      '/Staff/DebitMaterial',
      '/Staff/Customers',
      '/Staff/PriceDefinationScreen',
      '/Staff/DivideLoad/:orderRequestId',
      '/Staff/ShipmentOrganization',
      '/Staff/SelfOwnedVehicleDetail',
      '/SelfOwnedVehicleConnection',
      '/OrderrequestOrganization',
      '/Staff/CustomerInformation/:customerId',
      '/Staff/Customers',
      '/Staff/order',
      '/Staff/Vehicles',
      '/ForeignVehicleRegistration',
      '/Staff/ShipmentFinalConfirmation',
      '/ManagementPanel/staffs',
      '/ManagementPanel/vehicles',
      '/MagementPanel/Addforeignvehicle',
      '/ManagementPanel/AddTrailer',
      '/ManagementPanel/ForeignVehicles',
      '/ManagementPanel/SelfOwnedVehicle',
      '/ManagementPanel/VehicleTypes',
      '/ManagementPanel/AddSelfOwnedVehicle',
      '/ManagementPanel/Customers',
      '/ManagementPanel/Cardocumentvehicles',
      '/ManagementPanel/WorkedTransitCompanies',
      '/ManagementPanel/StaffsContractFinished',
      '/Staff/SelfOwnedVehicle',
      '/Staff/ForeignVehicle',
      '/ManagementPanel/CustomerContractFinish',
      '/ManagementPanel/CustomerEscalationCondinition',
      '/Staff/VehicleDamageRecords'
    ] as string[],
    Admin: [] as string[],
    Staff: [
      'dashboard/Customer/CreateOrderRequest',
      'Customer/CreateOrderRequest',
      '/dashboard/customer/Information',
      '/dashboard//Customer/OrderRequest',
      '/dashboard//Customer/CreateOrderEdit',
      '/dashboard//Customer/Prices'
    ] as string[]
  } as any;
  // insideGuard

  // eger yasakli ise dasboard root'a yonlendircek
  // let userRole = 'customer';
  // if (user.userRole !== undefined) {
  //   userRole = user.userRole;
  // }
  // user role sayfa yenilendiğinde boş geldiği için hata alıyorum.

  if (user.role === 'Staff') {
    if (insideGuard.Staff.includes(history.location.pathname)) {
      return <Redirect to={PATH_DASHBOARD.root} />;
    }
  }
  if (user.role === 'Customer') {
    if (insideGuard.Customer.includes(history.location.pathname)) {
      return <Redirect to={PATH_DASHBOARD.root} />;
    }
  }
  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Redirect to={PATH_AUTH.login} />;
  }

  return <>{children}</>;
}

export const CONSTS = {
  // Account -------------------------------------------------------------------------

  Login: '/login',

  // Location ------------------------------------------------------------------------
  GetCustomerLocations: '/Location/GetLocations',
  LocationAddLocationCustomerId: '/Location/AddLocation/1',
  GetLocationById: '/Location/GetLocation',
  AddCustomerLocation: '/Location/AddNewLocation',
  CustomerResetPasswordChange: '/Account/PasswordChange',
  CustomerDetailInformation: '/Account/CustomerInformation',
  EditCustomerLocation: '/Location/EditLocation',
  GetAllGetAllCustomerList: '/Customer/GetCustomerAllList',
  DeleteCustomerLocation: '/Location/DeleteLocation',
  DeleteCustomerLocationList: '/Location/DeleteLocationList',
  AddLocation: '/Location/AddNewLocation',
  getLocationByLocationID: '/Location/GetLocation',
  getOrderRequestById: '/OrderRequest/GetOrderRequestDetail',
  getOrderRequestSplit: '/OrderRequest/LoadSplit',
  getOrderRequestSplit2: '/OrderRequest/orderRequestSplit',
  GetProcessOrderRequestKanban: '/OrderRequest/GetProcesseOrderKanban',
  searchAddressApiRequest: '/Customer/SearchAddress',
  // OrderRequest ---------------------------------------------------------------------
  GetAllOrderRequests: '/OrderRequest/GetOrderRequests',
  GetCreateOrderRequest: '/OrderRequest/GetCreateOrderRequest',
  getTCMB: '/OrderRequest/TCMBDataResult',
  DeleteCustomerOrderRequest: '/OrderRequest/DeleteOrderRequests',
  GroupOrderRequestProcessing: '/OrderRequest/ProcessOrderRequestAsGroup',
  OrderRequestProcessing: '/OrderRequest/ProcessMultipleOrderRequests',
  AddCustomerOrderRequest: '/OrderRequest/AddOrderRequest',
  UpdateCustomerOrderRequest: '/OrderRequest/CustomerEditOrderRequest',
  GetCustomerOrderRequests: '/OrderRequest/GetCustomerOrderRequests',
  stationDeleteById: '/OrderRequest/DeleteStationsById',
  stationAddFunction: '/OrderRequest/AddStations',
  orderRaportFunction: '/OrderRequest/ExportToExcel',
  stationGetFunction: '/OrderRequest/getStationById',
  stationEditFunction: '/OrderRequest/UpdateStationById',
  stationSelectList: '/OrderRequest/GetStationSelectList',
  GetCustomerAll: '/Customer/GetCustomerAllList',
  getCustomerNotContractList: '/Customer/GetCustomerNotContractList',
  getCustomerInContractList: '/Customer/GetCustomerContractList',
  getCustomerBasicInfo: '/Customer/GetCustomerBasicInfo',
  GetStaffAll: '/api/Staff/getStaffListt',
  addStaff: '/api/Staff/StaffAdd',
  GetForeignTransitCompany:
    '/api/ForeignTransitCompany/GetForeignTransitCompanyList',
  completeOrder: '/OrderRequest/GetCompletedOrder',
  HandleDeliveryDateRange: '/OrderRequest/ListCompletedDateRangeDeliveryDate',
  proccesedList: '/OrderRequest/GetProcessedList',
  ShippedShipmentList: '/OrderRequest/GetWaitingShipmentList2',
  getWayOrderRequest: '/OrderRequest/GetWayOrderRequestList',
  AllDocumentForCompletedOrder:
    '/ProcessedOrder/OrderRequestWaybillOrDeliveryDocumentList',
  CancelOrderRequestList: '/OrderRequest/GetCancelOrderRequestList',
  getLoadingTypeSelectList: '/api/SystemSettings/GetLoadingTypeSelectList',
  // VehicleTypes ---------------------------------------------------------------------
  GetVehicleTypes: '/SelfOwnedVehicle/GetVehicleTypeList',
  AddSelfOwnedVehicle: '/SelfOwnedVehicle/AddSelfOwnedVehicle',
  // PriceDefination -------------------------------------------------------------------
  GetCurrentPriceDefinitionList:
    '/PriceDefinition/GetCurrentPriceDefinitionList',
  GetCurrentPriceDefinitionListNew:
    '/PriceDefinition/GetCurrentPriceDefinitionListNew',
  PriceDEfinitaionExampleExcel: '/PriceDefinition/GetPriceDefinitionExcel',
  GetCustomerPriceFixedComponent:
    '/PriceDefinition/GetCustomerPriceFormulaFixedComponent',
  PostUpdatePriceFormulaFixedComponent:
    '/PriceDefinition/AddPriceFormulFixedComponent',
  RemovePriceDefinition: '/PriceDefinition/RemovePriceDefinition',
  SelfOwnedVehicleList: '/SelfOwnedVehicle/GetSelfOwnedVehicles',
  seltownedcompaniesvehicles:
    '/api/SelfOwnedCompanies/GetSelfOwnedVehiclesBySelfOwnedCompanies',
  CitySelectList: '/api/City/GetSelectList',
  DistrictForCitySelectList: '/api/District/GetDistrict',
  allDistrict: '/api/District/GetDistrictSelectList',
  AddPriceFFormulFixed: '/PriceDefinition/AddPriceFormulFixedComponent',
  // CustomerPriceDefinition: '/PriceDefinition/DefineNewPriceDefinition',
  CustomerPriceDefinition:
    '/PriceDefinition/DefineNewPriceDefinitionAddExcelFile',
  RemoveDefinedPriceNew: '/PriceDefinition/RemovePriceDefinitionNew',
  DistrictSelectList: '/api/District/GetDistrictSelectList',
  deleteproccessorder: '/ProcessedOrder/DeleteProccessOrder',

  AddExcelFile: '/api/Vehicle/AddDataByExcel',
  UETDSNotification: '/OrderRequest/UETDSNotificationReadyOrders',
  AccountingTransferReady:
    '/api/AccountingTransfer/ISReadyAccountingTransferOrderSelect',

  UETDSNotificationUpdate: '/OrderRequest/UETDSNotificationReadyOrdersUpdate',
  getCompletedOrderDetail: '/OrderRequest/GetCompletedOrderDetail',
  CompletedOrderDetailUpdate: '/OrderRequest/CompletedOrderDetailUpdate',

  // Staff ------------------------------------------------------------------------------
  StaffGetStaffList: '/api/Staff/GetStaffList',
  // customerContact ------------------------------------------------------------------------------------
  addCustomerContract: '/api/CustomerContract/AddCustomerContract',

  // ---material---------------------------------------------------------------------------------
  getMaterial: '/api/Material/GetMaterialSelectList',
  // ------------------------------------------------------------------------------------
  SelfOwnedVehicleState: '/SelfOwnedVehicle/GetVehicleState',
  ChangeSelfOwnedVehicleState:
    '/SelfOwnedVehicle/SelfOwnedVehicleChangeVehicleState',
  GetVehicleStateSelectLİst: '/SelfOwnedVehicle/GetVehicleStateList',
  GetForeignVehicleState: '/ForeignVehicle/GetVehicleState',
  ChangeForeignVehicleState: '/ForeignVehicle/ForeignVehicleChangeVehicleState',
  getForeignVehicleCompanyInformation:
    '/ForeignVehicle/ForeignVehicleInformation',
  updateForeignVehicleInformation:
    '/ForeignVehicle/UpdateForeignVehicleInformation',
  GetForeignVehicleDriverInformation:
    '/ForeignVehicle/foreignVehicleCarmenInformation',
  getGetselfOwnedVehicleInformation:
    '/SelfOwnedVehicle/selfOwnedVehicleInformation',
  getGetselfOwnedVehicleDokuments: '/api/Vehicle/VehicleDocumentList',

  getForeignVehicleDokuments: '/api/Vehicle/ForeignVehicleDocumentList',
  deleteSelfOwnedVehicle: '/SelfOwnedVehicle/DeleteSelfOwnedVehicle',
  deleteSelfOwnedVehicleDocument: '/SelfOwnedVehicle/DeleteDocumentList',
  updateSelfOwnedVehicleInformation:
    '/SelfOwnedVehicle/UpdateSelfOwnedVehicleInformation',
  SelfOwneddocumentTypeSelectList:
    '/SelfOwnedVehicle/getSelfOwnedDocumentTypeList',
  addSelfOwnedVehicleDocument: '/SelfOwnedVehicle/SelfOwnedVehicleAddDocument',
  addstaffDocument: '/api/Staff/AddVehicleDriverDocument',
  getSelfOwnedDocumentByDocumentId: '/SelfOwnedVehicle/getVehicleDocument',
  updateSelfOwnedVehicleDocument:
    '/SelfOwnedVehicle/SelfOwnedVehicleDocumentUpdate',
  addForeignVehicleDocument: '/ForeignVehicle/ForeignVehicleAddDocument',
  deleteForeignVehicle: '/ForeignVehicle/DeleteForeignVehicle',
  // ----debit--------------------------------------------------------------------------------
  AddSelfOwnedVehicleDebit: '/SelfOwnedVehicle/AddSelfOwnedVehicleDebitEntry',
  GetSelfOwnedVehicleDebitList:
    '/SelfOwnedVehicle/GetSelfOwnedVehicleDebitEntries',
  TrailerListUseKanban: '/SelfOwnedVehicle/TrailerList',
  SelfOwnedDriverUseKanban: '/api/Staff/GetSelfOwnedDriver',
  TowTruckVehicleListUseKanban: '/SelfOwnedVehicle/GetKanbanVehicleList',
  TowTruckVehicleReadyTable: '/SelfOwnedVehicle/GetTowTruckVehicleReadyTable',
  TowTruckVehicleReadyTableSearch:
    '/SelfOwnedVehicle/SearchTowTruckTrailerVehicle',
  OrderKanbanReadyTableSearch: '/OrderRequest/SearchOrderKanban',
  searchSelfOwnedKanban:
    '/SelfOwnedVehicle/SearchTowTruckTrailerSelfOwnedVehicle',
  searchForeignKanban: '/SelfOwnedVehicle/SearchTowTruckTrailerForeignVehicle',
  searchOnWayKanban: '/SelfOwnedVehicle/SearchTowTruckTrailerONWay',
  searchPendingApprovalKanban:
    '/SelfOwnedVehicle/SearchTowTruckTrailerPendingApproval',
  searchNormalOrder: '/OrderRequest/SearchNormalOrderKanban',
  searchParsiyelOrder: '/OrderRequest/SearchParsiyelOrderKanban',

  searchDriverChefScreen: '/SelfOwnedVehicle/SearchChefDriver',
  searchTrailerChefScreen: '/SelfOwnedVehicle/SearchChefTrailer',
  searchVehicleChefScreen: '/SelfOwnedVehicle/SearchChefVehicle',

  searchGecmisOrder: '/OrderRequest/SearchGecmisOrderKanban',
  search1To3DayOrder: '/OrderRequest/Search1Day3DayOrderKanban',
  search1To7DayOrder: '/OrderRequest/Search1Day7DayOrderKanban',
  // ------------------------------------------------------------------------------------
  getCarBrandSelectList: '/api/CarBrand/GetCarBrandSelectList',
  getCarModelSelectList: '/api/CarModel/GetCarModel',
  DangerousMaterialsTransportTypeSelectList:
    '/api/Vehicle/GetDangerousMaterialsTransportTypeSelectList',
  Unnumber: '/api/Vehicle/GetUnNumberSelectList',
  selfOwnedDriverList: '/api/Staff/SelfOwnedDriverList',
  staffStatusPassive: '/api/Staff/PassiveStaffStatusListFunc',
  TowTruckVehicleProcessOrderChange: '/Shipment/ShipmentOrderChange',
  TowTruckVehicleAddProcessOrderRequest: '/Shipment/AddShipments',
  GetFilterReadyTowTruckVehicle: '/Shipment/GetProcessOrderNearTowTruck',
  ApprovedProcessOrder: '/api/Vehicle/ApprovedProcessOrder',
  TowTruckVehicleDeleteProcessOrder: '/Shipment/DeleteShipments',
  foreignDriverList: '/api/Staff/ForeignDriverList',
  TowTruckVehicleAddStaff: '/api/Staff/AddTowTruckStaff',
  TowTruckVehicleAddTrailer: '/api/Staff/AddTowTruckTrailer',
  TowTruckVehicleDeleteStaff: '/api/Staff/DeleteTowTruckStaff',
  TowTruckVehicleChangeStatüs: '/api/Vehicle/ChangeVehicleStatüs',
  TowTruckVehicleDeleteTrailer: '/api/Staff/DeleteTowTruckTrailer',
  getVehicleDriverDocumentList: '/api/Staff/GetDriverDocumentList',
  getEditVehicleDriverDocument: '/api/Staff/getStaffDocument',
  updateVehicleDriverDocument: '/api/Staff/UpdateDriverDocument',
  DriverDocumentTypesSelectLİst:
    '/api/Staff/getSelfOwnedDriverDocumentTypeSelectList',
  AddVehicleDriverDocument: '',
  deleteVehicleDriverDocument: '',
  vehicleDriverInfo: '/api/Staff/getStaffInformation',
  updateVehicleDriverInfo: '/api/Staff/UpdateStaff',
  editprofilestaff: '/Account/UpdateStaff',
  getStaffTitle: '/api/Staff/GetTitleSelectList',
  contractList: '/api/CustomerContract/GetCustomerContractDateInformationList',
  getCustomerContractByCustomerId: '/Customer/GetByCustomerContract',
  updateCustomerContract: '/Customer/UpdateCustomerContract',
  deleteCustomerContract: '/api/CustomerContract/DeleteCustomerContract',
  AddCustomer: '/Customer/AddCustomer',
  AddCustomerLogin: '/Register',
  deleteCustomer: '/Customer/DeleteCustomer',
  AddCreateStaff: '/RegisterDriver',
  getEditCustomerInfo: '/Customer/GetCustomer',
  updateEditCustomerInfo: '/Customer/UpdateCustomer',
  // updateEditCustomerInfo: '/Customer/UpdateCustomerAllInformation',

  updateSelfOwnedVehicleChiferDriver: '',
  ChiferDriverSelectList: '/api/Staff/ChiferDriverSelectList',
  SelfOwnedCompaniesSelectList:
    '/api/SelfOwnedCompanies/GetSelfOwnedCompaniesList',
  getForeignTransitCompanySelectList:
    '/api/ForeignTransitCompany/GetForeignTransitCompanySelectList',
  trailerList: '/SelfOwnedVehicle/GetTrailerList2',
  selfOwndeCompaniesTrailer:
    '/api/SelfOwnedCompanies/GetSelfOwnedTrailerListBySelfOwnedCompaniesId',

  getTrailerTypeSelectList: '/api/Vehicle/GetTrailerTypeSelectList',
  trailerDocumentType:
    '/SelfOwnedVehicle/GetSelfOwnedTrailerDocumnetTypeSelectList',
  getTrailerDetail: '/SelfOwnedVehicle/GetSelOwnedTrailerInformation',
  updateTrailerDetail: '/SelfOwnedVehicle/UpdateSelfOwnedTrailerInformation',
  getEscalationList: '/Customer/GetCustomerEscalation',
  createSelfOwnedVehicle: '/SelfOwnedVehicle/AddSelfOwnedVehicle',

  addTrailer: '/SelfOwnedVehicle/AddSelfOwnedTrailer',
  addForeignVehicle: '/ForeignVehicle/AddForeignVehicle',
  getForeignVehicleDocumentTypesSelectList:
    '/ForeignVehicle/GetForeignVehicleDocumentList',
  trailerDocumentList: '/api/Vehicle/TrailerDocumentList',
  deleteStaff: '/api/Staff/DeleteStaff',
  transitCompanyList: '/api/ForeignTransitCompany/GetForeignTransitCompanyList',
  addTransitCompanyInformation: '/api/TransitCompany/AddTransitCompany',
  getTransitCompanyByTransitCompanyId:
    '/api/TransitCompany/GetByTransitCompanyInformation',
  updateTransitCompanyInfo:
    '/api/TransitCompany/UpdateTransitCompanyInformation',
  deleteTransitCompany: '/api/TransitCompany/DeleteTransitCompanies',
  getVehiclesForTransitCompanyList:
    '/ForeignVehicle/GetForeignVehiclesByTransitCompanyIdModel',
  waitingShipmentList: '/OrderRequest/GetWaitingShipment',
  GetVehicleListWithCity: '/api/City/GetVehicleListWithCity',
  ExportCity: '/api/City',
  DocumentIdByBase64: '/api/Vehicle/GetDocumentByIdBase64',
  orderDocumentIdByBase64: '/ProcessedOrder/GetOrderDocumentByIdBase64',
  cancelShipment: '/Shipment/CancelShipment',
  paymentMethotSelectList: '/api/SystemSettings/GetPaymentSelectList',
  cashierSelectList: '/api/SystemSettings/GetCashierSelectList',
  updatePriceDefination: '/PriceDefinition/UpdatePriceDefinitions',
  finalApproval: '/Shipment/GetFinalApproval',
  updatePriceShipment: '/OrderRequest/UpdateOrderRequestJoinRate',
  UpdateCargoAmountInCompleteList:
    '/OrderRequest/UpdateOrderRequestCargoAmount',
  UpdateCustomerInCompleteList:
    '/OrderRequest/ProcessOrderRequestUpdateCustomer',
  waybillanddeliverydocumentaddandCompleteOrder:
    '/ProcessedOrder/AddOrderRequestWaybillOrDeliveryDocument',
  finalConfirmOrder: '/OrderRequest/FinalConfirmOrderRequest',
  addWaybillNoOrDeliveryDocumentNo:
    '/ProcessedOrder/AddOrderRequestWaybillNoOrDeliveryDocumentNo',
  getWaybillNoOrDeliveryDocumentNo:
    '/ProcessedOrder/GetOrderRequestWaybillNoOrDeliveryDocumentNo',
  deleteOrderdocument: '/ProcessedOrder/DeleteOrderDocument',
  divideListOrder: '/ProcessedOrder/AddSplitProcessOrder',

  addCarBrand: '/api/Vehicle/AddCarBrand',
  addCarModel: '/api/Vehicle/AddCarModel',
  addCashier: '/api/Vehicle/AddCashier',
  cashierDeleteById: '/api/Vehicle/DeleteCashierById',
  cashierGetFunction: '/api/Vehicle/getCashierById',
  cashierEditFunction: '/api/Vehicle/UpdateCashierById',

  getVehicleTypeList: '/api/VehicleType/GetVehicleTypeList',
  // -----------------,-------------------------------------------------------------------
  // dashboard //
  TotalORderRequest: '/api/DashBoard/TotalOrderCount',
  totalShipment: '/api/DashBoard/TotalShipmentCount',
  totalCargo: '/api/DashBoard/TotalCargoAmountCount',
  totalkm: '/api/DashBoard/TotalKM',

  // ********* ***
  AccountInformation: '/Account/AccountInformation',
  StaffAccountInformation: '/Account/StaffInformation',
  Register: '/Register',
  CreateRole: '/CreateRole',
  AccountLogout: '/api/Account/Logout',
  AccountSendMail: '/api/Account/SendMail',
  AccountResetPassword: '/api/Account/ResetPassword',

  // Foreign Vehicle ---------------------------------------------------------------------------------------
  AnnouncementAddAnnouncement: '/api/Announcement/AddAnnouncement',
  GeForeignVehicle: '/ForeignVehicle/GetForeignVehicleList',
  AnnouncementDeleteAnnouncement:
    '/api/Announcement/DeleteAnnouncement/{announcementId}',
  AnnouncementGetAnnouncementList: '/api/Announcement/GetAnnouncementsList',
  AnnouncementEditAnnounement: '/api/Announcement/EditAnnounement',
  PriceDefinitionGetPriceDefinitions: '/api/Announcement/EditAnnounement',
  // PriceDefinitionGetPriceDefinitions:
  //   'PriceDefinition/GetPriceDefinitionList/1',
  AnnouncementGetPresidentAnnouncementList:
    '/api/Announcement/GetPresidentAnnouncementList',
  AnnouncementGetAnnouncementById: '/api/Announcement/GetAnnouncementById/',
  // Appointment ---------------------------------------------------------------------------------------

  AppointmentCancelAppointment:
    '/api/Appointment/CancelAppointment/{appointmentId}/{cancellationReason}',

  AppointmentGetAppointmentInformation:
    '/api/Appointment/GetAppointmentInformation/{appointmentId}',

  AppointmentGetTableAppointmentList:
    '/api/Appointment/GetTableAppointmentList/{tableId}',

  AppointmentGetActiveAppointments: '/api/Appointment/GetActiveAppointments',

  AppointmentGetBarcodeNumberActiveAppointment:
    '/api/Appointment/GetBarcodeNumberActiveAppointment/{barcodeNumber}',

  AppointmentGetViolatedAppointment: '/api/Appointment/GetViolatedAppointment',

  AppointmentGetVisiorAppoinment:
    '/api/Appointment/GetVisiorAppointment/{identificationNumber}',
  AppointmentGetAppointmentByStartDateEndDate:
    '/api/Appointment/GetAppointmentByStartDateEndDate/{startDate}/{endDate}',
  AppointmentAddAppointment: '/api/Appointment/AddAppointment',
  AppointmentGetPastAppointments: '/api/Appointment/GetPastAppointments',
  AppointmentGetAppointmentRestrictionDateList:
    '/api/Appointment/GetAppointmentRestrictionDateList',
  AppointmentAddAppointmentRestrictionDate:
    '/api/Appointment/AddAppointmentRestrictionDate',
  AppointmentDeleteAppointmentRestrictionDate:
    '/api/Appointment/DeleteAppointmentRestrictionDate/{appointmentRestrictionDateId}',
  // RfidCard ---------------------------------------------------------------------------------------

  RfidCardActiveRfidCardList: '/api/RfidCard/ActiveRfidCardList',
  RfidCardQuestAndVisitorList: '/api/RfidCard/QuestAndVisitorList',
  RfidCardIdentificationNumberVisitor:
    '/api/RfidCard/IdentificationNumberVisitor/{identificationNumber}',
  RfidCardAddRfidCardUser: '/api/RfidCard/AddRfidCardUser/{cardNumber}',
  RfidCardDeleteRfidCard: '/api/RfidCard/DeleteRfidCard',
  // Statistics ---------------------------------------------------------------------------------------

  StatisticsGetTotalNumberOfAppointments:
    '/api/Statistics/GetTotalNumberOfAppointments',
  StatisticsGetTotalRfidQrCodeStatistics:
    '/api/Statistics/GetTotalRfidQrCodeStatistics',
  StatisticsGetTotalVisitorStatistics:
    '/api/Statistics/GetTotalVisitorStatistics',
  StatisticsGetDayNumberOfAppointments:
    '/api/Statistics/GetDayNumberOfAppointments',
  StatisticsGetDayVisitorStatistics: '/api/Statistics/GetDayVisitorStatistics',
  StatisticsGetMonthTotalNumberOfAppointments:
    '/api/Statistics/GetMonthTotalNumberOfAppointments/{month}',
  StatisticsGetMonthVisitorStatistics:
    '/api/Statistics/GetMonthVisitorStatistics/{month}',

  // SystemSettings --------------------------------------------------------------------------------------

  SystemSettingsLatestDateUpdate: '/api/SystemSettings/LatestDateUpdate/{day}',
  SystemSettingsGetLatestDate: '/api/SystemSettings/GetLatestDate',

  // Table ---------------------------------------------------------------------------------------------------

  TableGetTable: '/api/Table/GetTable/{barcodeNumber}',
  ChangeTableWorkingArea:
    '/api/Table/ChangeTableWorkingArea/{tableId}/{isInWarehouse}/{newWorkingAreaId}',
  TableGetAllTables: '/api/Table/GetAllTables',
  IsThereAppointmentTable: '/api/Table/IsThereAppointmentTable/{tableId}',
  TableDeleteIsInWarehouse: '/api/Table/DeleteIsInWarehouse/{tableId}',

  // Turnstile -----------------------------------------------------------------------------------------

  TurnstileTurnstileRegisters11: '/api/Turnstile/GetTurnstileRegisters/1/1',
  TurnstileTurnstileRegisters12: '/api/Turnstile/GetTurnstileRegisters/1/2',

  TurnstileTurnstileRegisters21: '/api/Turnstile/GetTurnstileRegisters/2/1',

  TurnstileTurnstileRegisters22: '/api/Turnstile/GetTurnstileRegisters/2/2',

  // Util ----------------------------------------------------------------------------------------------

  UtilMisafirQRCode: '/api/Util/MisafirQRCode',

  // VacationDay ------------------------------------------------------------------------------------------

  VacationDayGetVacationDayList: '/api/VacationDay/GetVacationDayList',
  VacationDayAddVacationDay: '/api/VacationDay/AddVacationDay',

  // Visitor ------------------------------------------------------------------------------------------------

  VisitorVisitorTurnstileMovement:
    '/api/Visitor/VisitorTurnstileMovement/{identificationNumber}',

  // WorkingArea ------------------------------------------------------------------------------------------------

  WorkingAreaAddClassroom: '/api/WorkingArea/AddClassroom',
  WorkingAreaWorkingAreaStatus:
    '/api/WorkingArea/WorkingAreaStatus/{workingAreaId}/{isOpenMassAppointmentId}',
  WorkingAreaGetQuitLibrary: '/api/WorkingArea/GetQuitLibrary',
  WorkingAreaGetStudiesRoom: '/api/WorkingArea/GetStudiesRoom',
  WorkingAreaDeleteWorkingArea:
    '/api/WorkingArea/DeleteWorkingArea/{workingAreaId}',
  WorkingAreaWorkingAreaList: '/api/WorkingArea/WorkingAreaList',
  WorkingAreaWorkingAreaTables: '/api/WorkingArea/WorkingAreaTables',
  WorkingAreaAddWorkingAreaTable: '/api/WorkingArea/AddWorkingAreaTable',
  WorkingAreaDeleteTableFromWorkingArea:
    '/api/WorkingArea/DeleteTableFromWorkingArea/{workingAreaId}/{tableId}',
  WorkingAreaIsThereAppointmentWorkingArea:
    '/api/WorkingArea/IsThereAppointmentWorkingArea/{workingAreaId}',
  WorkingAreaGetWorkingAreaList: '/api/WorkingArea/GetWorkingAreaList'
};

import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit'; // redux bu
import { dispatch } from '../../store';
// utils
import axios from '../../../utils/axios';
import {
  GetPriceDefinitionModel,
  GetPriceFormulaFixedComponentModel,
  getNewPriceDefinationModel
} from '../../../@types/GetPriceDefinition';
import { CONSTS } from '../const';
import { MapDeckglOverlay } from '../../../components/map';
// ----------------------------------------------------------------------

type PriceDefinitionState = {
  isLoading: boolean;
  error: boolean;
  detailModalIsOpen: boolean;
  PriceDefinitionList: GetPriceDefinitionModel[]; // farklı olan diğerleri fix
  priceDefinition: GetPriceDefinitionModel;
  GetPriceFormulaFixedComponentList: GetPriceFormulaFixedComponentModel[];
  GetPriceFormulaFixedComponent: GetPriceFormulaFixedComponentModel;
  getNewPriceDefinationList: getNewPriceDefinationModel[];
};
const priceDefinitionInit = {
  // gelen modelin propertylerin tipleri
  sourceLocationCityId: 0,
  sourceLocationCityName: '',
  priceformulafixedcomponentId: 0,
  sourceLocationDistrictId: 0,
  sourceLocationDistrictName: '',
  destinationLocationCityName: '',
  destinationLocationCityId: 0,
  destinationLocationDistrictName: '',
  destinationLocationDistrictId: 0,
  tonnage: 0,
  id: 0,
  oldPrice: 0,
  distance: 0,
  jointRate: 0,
  isCurrentPrice: false,
  appliedDate: new Date()
};
const priceFormulaFixedInit = {
  createDate: new Date(),
  ısDeleted: false,
  customerId: 0,
  id: 0,
  dieselOilOldPrice: 0,
  dieselOilNewPrice: 0,
  fuelToBeApplied: 0,
  dieselOilIncreaseRate: 0
};

const initialState: PriceDefinitionState = {
  isLoading: false,
  error: false,
  detailModalIsOpen: false,
  PriceDefinitionList: [],
  priceDefinition: priceDefinitionInit,
  GetPriceFormulaFixedComponentList: [],
  GetPriceFormulaFixedComponent: priceFormulaFixedInit,
  getNewPriceDefinationList: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getAllPriceDefinationSuccess(state, action) {
      state.isLoading = false;
      state.PriceDefinitionList = action.payload;
    },
    getNewPriceDefinationListSuccess(state, action) {
      state.isLoading = false;
      state.getNewPriceDefinationList = action.payload;
    },
    filterPriceDefinationSuccess(state, action) {
      state.isLoading = false;
      state.PriceDefinitionList = action.payload;
    },
    savePriceDefinationSuccess(state, action) {
      state.isLoading = false;
      state.PriceDefinitionList = action.payload;
    },
    getPriceFormulaFixedComponent(state, action) {
      state.isLoading = false;
      state.GetPriceFormulaFixedComponent = action.payload;
    },
    getPriceDefinationByIdSuccess(state, action) {
      state.isLoading = false;
      state.priceDefinition = action.payload;
    },

    // GET PROFILE
    getPriceDefnationByCustomerIdForStaffSuccess(state, action) {
      state.isLoading = false;
      state.PriceDefinitionList = action.payload;
    },

    onToggleDetailModal(state, action) {
      state.detailModalIsOpen = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleDetailModal } = slice.actions;

// ----------------------------------------------------------------------

// export function addPriceDefination(model: any) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
// const response = await axios.post(
//   `${CONSTS.AnnouncementAddAnnouncement}`,
//   {
//     title: model.title,
//     description: model.description,
//     publicationDate: model.publicationDate,
//     announcementTypeId: Number(model.announcementTypeId),
//     takedownDate: model.takedownDate
//   }
// );
//       localStorage.setItem('id', response.data.data.id);

//       dispatch(getAllPriceDefination());
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function getAllPriceDefination(CustomerId: string | null) {
  // const customerId = localStorage.getItem('Customer');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.GetCurrentPriceDefinitionList}?customerId=${CustomerId}`,
        {}
      );
      if (response.data.data !== null) {
        dispatch(
          slice.actions.getAllPriceDefinationSuccess(response.data.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllPriceDefinationNew(CustomerId: string | null) {
  // const customerId = localStorage.getItem('Customer');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.GetCurrentPriceDefinitionListNew}?customerId=${CustomerId}`,
        {}
      );
      if (response.data.data !== null) {
        await dispatch(
          slice.actions.getNewPriceDefinationListSuccess(response.data.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function RemoveDefinedPriceNewFunc(id: any) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${CONSTS.RemoveDefinedPriceNew}`, id);
    if (response.data.success) {
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}

export function removePriceDefinition(
  priceDefinitionList: any,
  customerId: any
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `${CONSTS.RemovePriceDefinition}`,
        priceDefinitionList
      );
      dispatch(getAllPriceDefination(customerId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export async function getPriceDefnationByCustomerIdForStaff(
  CustomerId: string
) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(
      `${CONSTS.GetCurrentPriceDefinitionList}?customerId=${CustomerId}`
    );
    if (response.data.success) {
      dispatch(
        slice.actions.getPriceDefnationByCustomerIdForStaffSuccess(
          response.data.data
        )
      );
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}
export function customerPriceFormulaComponent(customerId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${CONSTS.GetCustomerPriceFixedComponent}?customerId=${Number(
          customerId
        )}`
      );
      if (response.data.data !== null) {
        dispatch(
          await slice.actions.getPriceFormulaFixedComponent(response.data.data)
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateCustomerPriceFormulaComponent(
  updatePriceFormulaComponent: any
) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${CONSTS.PostUpdatePriceFormulaFixedComponent}`,
      {
        createDate: new Date(updatePriceFormulaComponent.createDate),
        ısDeleted: false,
        customerId: Number(updatePriceFormulaComponent.customerId),
        id: Number(updatePriceFormulaComponent.id),
        dieselOilOldPrice: Number(
          updatePriceFormulaComponent.dieselOilOldPrice
        ),
        dieselOilNewPrice: Number(
          updatePriceFormulaComponent.dieselOilCustomerNewPrice
        ),
        fuelToBeApplied: Number(updatePriceFormulaComponent.fuelToBeApplied),
        dieselOilIncreaseRate: Number(
          updatePriceFormulaComponent.dieselOilIncreaseRate
        )
      }
    );
    if (response.data.success) {
      dispatch(getAllPriceDefination(updatePriceFormulaComponent.customerId));
      return true;
    }
    return false;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return false;
  }
}
